import { keyframes } from 'styled-components';

export const fadeIn = keyframes`
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
`;

export const dot = keyframes`
  0% { 
    opacity: 0;
  }
  50% { 
    opacity: 1;
  }
  50.01% { 
    opacity: 1;
  }
  100% { 
    opacity: 0;
  }
`;

export const slideFromLeft = keyframes`
  from {
    margin-left: -30%;
    opacity: 0;
  }
  to {
    margin-left: 0;
    opacity: 1;
  }
`;

export const slideFromRight = keyframes`
  from {
    margin-left: 30%;
    opacity: 0;
  }
  to {
    margin-left: 0;
    opacity: 1;
  }
`;

export const cardSlider = keyframes`
  from {
    opacity: 0;
    visibility: hidden;
    transform: translateY(5%);
  }
  to {
    opacity: 1;
    visibility: visible;
    transform: none;
  }
`;