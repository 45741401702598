import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import ComponentWrapper from '../layout/ComponentWrapper';
import AnchorLink from './AnchorLink';
import { Text } from '../../util/typography';
import { blueberry, blueberryDark, ink, sky, skyLight } from '../../util/colors';

const AnchorLinksWrapper = styled.div`
    --titleColor: ${props => (props.theme === 'light' ? 'white' : ink )};
    --linkColor: ${props => (props.theme === 'light' ? sky : blueberry )};
    --linkColorHover: ${props => (props.theme === 'light' ? skyLight : blueberryDark )};
    --iconSize: 10px;
`;

const AnchorLinksTitle = styled(Text.Body)`
    color: var(--titleColor);
    display: block;
    margin-bottom: 16px;
`;

const AnchorLinksGrid = styled.div`
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(198px, 1fr));
    column-gap: 10px;
    row-gap: 16px;
`;

const AnchorLinks = ({ title, items, children, theme }) => {
    return (
        <ComponentWrapper>
            <AnchorLinksWrapper theme={theme}>
                <AnchorLinksTitle as='H3'>{title}</AnchorLinksTitle>
                <AnchorLinksGrid>
                    {items.map((link, i) => (
                        <AnchorLink key={i} {...link}/>
                    ))}
                </AnchorLinksGrid>
            </AnchorLinksWrapper>
        </ComponentWrapper>
    );
};

AnchorLinks.propTypes = {
    title: PropTypes.string,
    items: PropTypes.arrayOf(PropTypes.object)
};

AnchorLinks.defaultProps = {
    title: 'On this page',
    items: []
};

export default AnchorLinks;
export { AnchorLink };
