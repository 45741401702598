import styled from 'styled-components';
import { Heading } from '../../util/typography';
import * as Colors from '../../util/colors';
import { createLocalScaleFunction } from '../../util/functions';

export const InformationListContainer = styled.section`
    flex: 0 auto;
    margin-right: -17px; // offset for scrollbars
    padding-right: 17px;
    overflow: auto;
    padding-bottom: ${createLocalScaleFunction(40, 320, 60, 952)};
`;

export const InformationItemLabel = styled(Heading.H7)`
    display: block;
    margin-bottom: 10px;

    em {
        font-style: normal;
        text-decoration: underline;
        font-weight: 400;
    }
`;

export const InformationItem = styled.article`
    display: flex;
    flex-wrap: wrap;
    padding-bottom: ${createLocalScaleFunction(30, 280, 40, 824)};

    > div {
        color: ${Colors.slate};
    }

    &:last-child {
        padding-bottom: 0;
    }

    ${({ columns }) =>
        columns === 2 &&
        `
        header {
            flex: 1 1 280px;
            padding: 0 20px 0 0;
            margin-bottom: 10px;
        }

        > div {
            flex: 23 1 280px;
            min-width: 280px;
            padding: 0;
        }
    `}
`;
