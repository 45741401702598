import React, { useContext } from 'react';
import styled from 'styled-components';
import FilterCard from './FilterCard';
import { Text } from '../../util/typography';
import { between } from 'polished';
import { FilterContext } from '../FilterProvider';

const StyledFilterCardContainer = styled.div`
    display: flex;
    flex-flow: row wrap;
    margin: 0 -10px;
`;

const FilterCardColumn = styled.div`
    flex: 1 1 calc(50% - 20px);
    min-width: 280px;
    margin: 0 10px;
`;

const SubHeading = styled(Text.Eyebrow)`
    text-transform: uppercase;
    margin-bottom: 10px;
    display: block;
`;

const TypesListContainer = styled.div`
    margin-bottom: 20px;

    @media (min-width: 321px) and (max-width: 1032px) {
        margin-bottom: ${between('20px', '30px', '320px', '1032px')};
    }

    @media (min-width: 1033px) {
        margin-bottom: 30px;
    }
`;

const FilterCardContainer = ({list, onSelectCard}) => { 
    const { filteredCancerTypes } = useContext(FilterContext);

    //split lists up into 2 columns
    let columns = [],
    columnLength = Math.ceil(Object.values(list).length / 2);
    columns[0] = Object.fromEntries(Object.entries(list).splice(0,columnLength));
    columns[1] = Object.fromEntries(Object.entries(list).splice(columnLength));

    return (
        <StyledFilterCardContainer>
            {columns.map((columnList, i) => (
                <FilterCardColumn key={i}>
                    {Object.values(columnList).map((item, index) => {
                        
                        return (<FilterCard
                            key={index}
                            data={item}
                            onChecked={onSelectCard}
                            checkedList={filteredCancerTypes}
                        />)
                        }
                    )}
                </FilterCardColumn>
            ))}
        </StyledFilterCardContainer>
    )
}

const TypesList = ({ groupName, list, onSelectCard }) => {
    return (
        <TypesListContainer>
            {groupName && <SubHeading>{groupName}</SubHeading>}

            <FilterCardContainer list={list} onSelectCard={onSelectCard} />
        </TypesListContainer>
    );
};

export default TypesList;
