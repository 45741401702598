export const q3wInstruction = `<li>Administer <b>200 mg every 3 weeks (Q3W)</b>. Administer after dilution as an intravenous infusion over 30 minutes; or</li>`,
q3wInstructionNoOr = q3wInstruction.replace('; or', ''),
q6wInstruction = `<li>Administer <b>400 mg every 6 weeks (Q6W)</b>. Administer after dilution as an intravenous infusion over 30 minutes</li>`;

export const indicationDosingContent = {
    // Primary Advanced or Recurrent Endometrial Carcinoma
    1: {
        adult: {
            headingAddon: `<sup>a</sup>`,
            dosing: `
                ${q3wInstruction}
                ${q6wInstruction}
                <li>Administer KEYTRUDA prior to carboplatin and paclitaxel when given on the same day</li>
            `,
            duration: `<p>Until disease progression, unacceptable toxicity, or up to 24 months</p>`,
        },
    },
    // Advanced Melanoma
    2: {
        adult: {
            dosing: `
                ${q3wInstruction}
                ${q6wInstruction}
            `,
            duration: `<p>Until disease progression or unacceptable toxicity</p>`,
        },
    },
    // Adjuvant Therapy for Stage IIb, Stage IIC, or Stage III Melanoma
    3: {
        adult: {
            dosing: `
                ${q3wInstruction}
                ${q6wInstruction}
            `,
            duration: `<p>Until disease recurrence, unacceptable toxicity, or up to 12 months</p>`,
        },
        pediatric: {
            dosing: `<ul><li>12 years and older: Administer <b>2 mg/kg every 3 weeks (Q3W) (up to a maximum of 200 mg)</b>. Administer after dilution as an intravenous infusion over 30 minutes</li></ul>`,
            duration: `<p>12 years and older: Until disease recurrence, unacceptable toxicity, or up to 12 months</p>`,
        },
    },
    // First-line Combination Therapy in Nonsquamous mNSCLC
    4: {
        adult: {
            headingAddon: `<sup>a</sup>`,
            dosing: `
                ${q3wInstruction}
                ${q6wInstruction}
                <li>Administer KEYTRUDA prior to chemotherapy when given on the same day</li>
            `,
            duration: `<p>Until disease progression, unacceptable toxicity, or up to 24 months</p>`,
        },
    },
    // First-line Combination Therapy in Squamous mNSCLC
    5: {
        adult: {
            headingAddon: `<sup>a</sup>`,
            dosing: `
                ${q3wInstruction}
                ${q6wInstruction}
                <li>Administer KEYTRUDA prior to chemotherapy when given on the same day</li>
            `,
            duration: `<p>Until disease progression, unacceptable toxicity, or up to 24 months</p>`,
        },
    },
    // First-line Monotherapy for Nonsquamous and Squamous Advanced NSCLC
    6: {
        adult: {
            dosing: `
                ${q3wInstruction}
                ${q6wInstruction}
            `,
            duration: `<p>Until disease progression, unacceptable toxicity, or up to 24 months</p>`,
        },
        patientSelection: [
            {
                description: 'Select patients for treatment with KEYTRUDA as a single agent based on the presence of positive PD&#8288;-&#8288;L1 expression in stage III NSCLC who are not candidates for surgical resection or definitive chemoradiation, or in metastatic NSCLC.',
            },
        ],
    },
    // Second-line or Greater Monotherapy for Nonsquamous and Squamous mNSCLC
    7: {
        adult: {
            dosing: `
                ${q3wInstruction}
                ${q6wInstruction}
            `,
            duration: `<p>Until disease progression, unacceptable toxicity, or up to 24 months</p>`,
        },
        patientSelection: [
            {
                description: 'Select patients for treatment with KEYTRUDA as a single agent based on the presence of positive PD&#8288;-&#8288;L1 expression in metastatic NSCLC.',
            },
        ],
    },
    // Advanced Renal Cell Carcinoma
    8: {
        adult: {
            headingAddon: `<sup>a</sup>`,
            dosing: `
                ${q3wInstruction}
                ${q6wInstruction}
                <li>Administer KEYTRUDA in combination with axitinib 5 mg orally twice daily<sup>b</sup></li>
            `,
            duration: `<p>Until disease progression, unacceptable toxicity, or for KEYTRUDA, up to 24 months</p>`,
        },
    },
    // First-line Combination Therapy in Metastatic or Unresectable, Recurrent HNSCC
    9: {
        adult: {
            headingAddon: `<sup>a</sup>`,
            dosing: `
                ${q3wInstruction}
                ${q6wInstruction}
                <li>Administer KEYTRUDA prior to chemotherapy when given on the same day</li>
            `,
            duration: `<p>Until disease progression, unacceptable toxicity, or up to 24 months</p>`,
        },
    },
    // First-line Monotherapy in Metastatic or Unresectable, Recurrent HNSCC
    10: {
        adult: {
            dosing: `
                ${q3wInstruction}
                ${q6wInstruction}
            `,
            duration: `<p>Until disease progression, unacceptable toxicity, or up to 24 months</p>`,
        },
        patientSelection: [
            {
                description: 'Select patients for treatment with KEYTRUDA as a single agent based on the presence of positive PD&#8288;-&#8288;L1 expression in first-line treatment of metastatic or unresectable, recurrent HNSCC.',
            },
        ],
    },
    // Subsequent-line Monotherapy in Recurrent or Metastatic HNSCC
    11: {
        adult: {
            dosing: `
                ${q3wInstruction}
                ${q6wInstruction}
            `,
            duration: `<p>Until disease progression, unacceptable toxicity, or up to 24 months</p>`,
        },
    },
    // 12: ''
    // Advanced cSCC
    13: {
        adult: {
            dosing: `
                ${q3wInstruction}
                ${q6wInstruction}
            `,
            duration: `<p>Until disease progression, unacceptable toxicity, or up to 24 months</p>`,
        },
    },
    // Advanced Merkel Cell Carcinoma
    14: {
        adult: {
            dosing: `
                ${q3wInstruction}
                ${q6wInstruction}
            `,
            duration: `<p>Until disease progression, unacceptable toxicity, or up to 24 months</p>`,
        },
        pediatric: {
            dosing: `<ul><li>Administer <b>2&nbsp;mg/kg every 3 weeks (Q3W) (up to a maximum of 200&nbsp;mg)</b>. Administer after dilution as an intravenous infusion over 30 minutes</li></ul>`,
            duration: `<p>Until disease progression, unacceptable toxicity, or up to 24 months</p>`,
        }
    },
    // First-line Monotherapy for Advanced Urothelial Carcinoma
    15: {
        adult: {
            dosing: `
                ${q3wInstruction}
                ${q6wInstruction}
            `,
            duration: `<p>Until disease progression, unacceptable toxicity, or up to 24 months</p>`,
        },
    },
    // Second-line Monotherapy—Post–Platinum Failure
    16: {
        adult: {
            dosing: `
                ${q3wInstruction}
                ${q6wInstruction}
            `,
            duration: `<p>Until disease progression, unacceptable toxicity, or up to 24 months</p>`,
        },
    },
    // High-Risk Non-muscle Invasive Bladder Cancer
    17: {
        adult: {
            dosing: `
                ${q3wInstruction}
                ${q6wInstruction}
            `,
            duration: `<p>Until persistent or recurrent high-risk NMIBC, disease progression, unacceptable toxicity, or up to 24 months</p>`,
        },
    },
    // Advanced Gastric or GEJ Cancer
    18: {
        adult: {
            headingAddon: `<sup>a</sup>`,
            dosing: `
                ${q3wInstruction}
                ${q6wInstruction}
                <li>Administer KEYTRUDA prior to chemotherapy when given on the same day</li>
            `,
            duration: `<p>Until disease progression, unacceptable toxicity, or up to 24 months</p>`,
        },
    },
    // Advanced Esophageal or GEJ Carcinoma
    19: {
        adult: {
            headingAddon: `<sup>a</sup>`,
            dosing: `
                ${q3wInstruction}
                ${q6wInstruction}
                <li>For combination therapy, administer KEYTRUDA prior to chemotherapy when given on the same day</li>
            `,
            duration: `<p>Until disease progression, unacceptable toxicity, or up to 24 months</p>`,
        },
        patientSelection: [
            {
                description: 'Select patients for treatment with KEYTRUDA as a single agent based on the presence of positive PD&#8288;-&#8288;L1 expression in previously treated recurrent locally advanced or metastatic esophageal cancer.',
            },
        ],
    },
    // Relapsed or Refractory cHL
    20: {
        adult: {
            dosing: `
                ${q3wInstructionNoOr}
                <li><a href='/interactivepi/' target='_blank'>View Prescribing Information for an additional dosing option</a></li>
            `,
            duration: `<p>Until disease progression, unacceptable toxicity, or up to 24 months</p>`,
        },
        pediatric: {
            dosing: `<ul><li>Administer <b>2 mg/kg every 3 weeks (Q3W) (up to a maximum of 200 mg)</b>. Administer after dilution as an intravenous infusion over 30 minutes</li></ul>`,
            duration: `<p>Until disease progression, unacceptable toxicity, or up to 24 months</p>`,
        },
    },
    // Refractory or Relapsed PMBCL
    21: {
        adult: {
            dosing: `
                ${q3wInstructionNoOr}
                <li><a href='/interactivepi/' target='_blank'>View Prescribing Information for an additional dosing option</a></li>
            `,
            duration: `<p>Until disease progression, unacceptable toxicity, or up to 24 months</p>`,
        },
        pediatric: {
            dosing: `<ul><li>Administer <b>2 mg/kg every 3 weeks (Q3W) (up to a maximum of 200 mg)</b>. Administer after dilution as an intravenous infusion over 30 minutes</li></ul>`,
            duration: `<p>Until disease progression, unacceptable toxicity, or up to 24 months</p>`,
        },
    },
    // Advanced MSI-H/dMMR Cancers
    22: {
        adult: {
            dosing: `
                ${q3wInstruction}
                ${q6wInstruction}
            `,
            duration: `<p>Until disease progression, unacceptable toxicity, or up to 24 months</p>`,
        },
        pediatric: {
            dosing: `<ul><li>Administer <b>2 mg/kg every 3 weeks (Q3W) (up to a maximum of 200 mg)</b>. Administer after dilution as an intravenous infusion over 30 minutes</li></ul>`,
            duration: `<p>Until disease progression, unacceptable toxicity, or up to 24 months</p>`,
        },
        patientSelection: [
            {
                description: `For the MSI&#8288;-&#8288;H/dMMR indications, select patients for treatment with KEYTRUDA as a single agent based on MSI&#8288;-&#8288;H/dMMR status in tumor specimens as determined by an FDA approved test.`
            },
            {
                description: `Because subclonal dMMR mutations and microsatellite instability may arise in high-grade gliomas during temozolomide therapy, it is recommended to test for MSI&#8288;-&#8288;H and dMMR in the primary tumor specimens obtained prior to initiation of temozolomide chemotherapy in patients with high-grade gliomas.`,
            },
            {
                description: `Due to discordance between local tests and FDA-approved tests, confirmation of MSI&#8288;-&#8288;H or dMMR status is recommended by an FDA-approved test in patients with MSI&#8288;-&#8288;H or dMMR solid tumors, if feasible.`,
            },
        ],
    },
    // Advanced MSI-H/dMMR CRC
    23: {
        adult: {
            dosing: `
                ${q3wInstruction}
                ${q6wInstruction}
            `,
            duration: `<p>Until disease progression, unacceptable toxicity, or up to 24 months</p>`,
        },
        patientSelection: [
            {
                description: `For the MSI&#8288;-&#8288;H/dMMR indications, select patients for treatment with KEYTRUDA as a single agent based on MSI&#8288;-&#8288;H/dMMR status in tumor specimens as determined by an FDA approved test.`
            },
            {
                description: `Because subclonal dMMR mutations and microsatellite instability may arise in high-grade gliomas during temozolomide therapy, it is recommended to test for MSI&#8288;-&#8288;H and dMMR in the primary tumor specimens obtained prior to initiation of temozolomide chemotherapy in patients with high-grade gliomas.`,
            },
        ],
    },
    // Second-line Monotherapy for Advanced Cervical Cancer
    24: {
        adult: {
            dosing: `
                ${q3wInstruction}
                ${q6wInstruction}
            `,
            duration: `<p>Until disease progression, unacceptable toxicity, or up to 24 months</p>`,
        },
        patientSelection: [
            {
                description: 'Select patients for treatment with KEYTRUDA as a single agent based on the presence of positive PD&#8288;-&#8288;L1 expression in recurrent or metastatic cervical cancer with disease progression on or after chemotherapy.',
            },
        ],
    },
    // Advanced TMB-H Cancers
    // 25: {
    //     adult: {
    //         dosing: `
    //             ${q3wInstruction}
    //             ${q6wInstruction}
    //         `,
    //         duration: `<p>Until disease progression, unacceptable toxicity, or up to 24 months</p>`,
    //     },
    //     pediatric: {
    //         dosing: `<ul><li>Administer <b>2 mg/kg every 3 weeks (Q3W) (up to a maximum of 200 mg)</b>. Administer after dilution as an intravenous infusion over 30 minutes</li></ul>`,
    //         duration: `<p>Until disease progression, unacceptable toxicity, or up to 24 months</p>`,
    //     },
    //     patientSelection: [
    //         {
    //             description: `Select patients for treatment with KEYTRUDA as a single agent based on TMB&#8288;-&#8288;H status in tumor specimens as determined by an FDA approved test.`
    //         },
    //         {
    //             description: `Because subclonal dMMR mutations and microsatellite instability may arise in high-grade gliomas during temozolomide therapy, it is recommended to test for TMB&#8288;-&#8288;H in the primary tumor specimens obtained prior to initiation of temozolomide chemotherapy in patients with high-grade gliomas.`
    //         },
    //     ],
    // },
    // Advanced Triple-Negative Breast Cancer
    26: {
        adult: {
            headingAddon: `<sup>a</sup>`,
            dosing: `
                ${q3wInstruction}
                ${q6wInstruction}
                <li>Administer KEYTRUDA prior to chemotherapy when given on the same day</li>
            `,
            duration: `<p>Until disease progression, unacceptable toxicity, or up to 24 months</p>`,
        },
        patientSelection: [
            {
                description: 'For use of KEYTRUDA in combination with chemotherapy, select patients based on the presence of positive PD&#8288;-&#8288;L1 expression in locally recurrent unresectable or metastatic TNBC.',
            },
        ],
    },
    // High-Risk Early-Stage Triple-Negative Breast Cancer
    27: {
        adult: {
            headingAddon: `<sup>a</sup>`,
            dosing: `
                ${q3wInstruction}
                ${q6wInstruction}
                <li>Administer KEYTRUDA prior to chemotherapy when given on the same day</li>
            `,
            duration: `<p>Neoadjuvant treatment in combination with chemotherapy for 24 weeks (8 doses of 200 mg every 3 weeks or 4 doses of 400 mg every 6 weeks) or until disease progression or unacceptable toxicity, followed by adjuvant treatment with KEYTRUDA as a single agent for up to 27 weeks (9 doses of 200 mg every 3 weeks or 5 doses of 400 mg every 6 weeks) or until disease recurrence or unacceptable toxicity.<sup>b</sup></p>`,
        },
    },
    // First-line Combination Therapy for Advanced Cervical Cancer
    28: {
        adult: {
            headingAddon: `<sup>a</sup>`,
            dosing: `
                ${q3wInstruction}
                ${q6wInstruction}
                <li>Administer KEYTRUDA prior to chemotherapy with or without bevacizumab when given on the same day </li>
            `,
            duration: `<p>Until disease progression, unacceptable toxicity, or for KEYTRUDA, up to 24 months</p>`,
        },
        patientSelection: [
            {
                description: 'For use of KEYTRUDA in combination with chemotherapy, with or without bevacizumab, select patients based on the presence of positive PD&#8288;-&#8288;L1 expression in persistent, recurrent, or metastatic cervical cancer.',
            },
        ],
    },
    // Adjuvant Treatment for Renal Cell Carcinoma
    29: {
        adult: {
            dosing: `
                ${q3wInstruction}
                ${q6wInstruction}
            `,
            duration: `<p>Until disease recurrence, unacceptable toxicity, or up to 12 months</p>`,
        },
    },
    // Advanced MSI⁠-⁠H/dMMR Endometrial Carcinoma
    30: {
        adult: {
            dosing: `
                ${q3wInstruction}
                ${q6wInstruction}
            `,
            duration: `<p>Until disease progression, unacceptable toxicity, or up to 24 months</p>`,
        },
        patientSelection: [
            {
                description: `Select patients for treatment with KEYTRUDA as a single agent based on MSI&#8288;-&#8288;H/dMMR status in tumor specimens as determined by an FDA approved test.`
            },
            {
                description: `Because subclonal dMMR mutations and microsatellite instability may arise in high-grade gliomas during temozolomide therapy, it is recommended to test for MSI&#8288;-&#8288;H and dMMR in the primary tumor specimens obtained prior to initiation of temozolomide chemotherapy in patients with high-grade gliomas.`,
            },
            {
                description: `Due to discordance between local tests and FDA-approved tests, confirmation of MSI&#8288;-&#8288;H or dMMR status is recommended by an FDA-approved test in patients with MSI&#8288;-&#8288;H or dMMR solid tumors, if feasible.`,
            },
        ],
    },
    // Another Indication for a Combination Treatment (RCC)
    31: {
        callout: {
            url: 'https://www.keytrudalenvimahcp.com',
            text: 'View Additional Information'
        },
    },
    // Another Indication for a Combination Treatment (Endo)
    32: {
        callout: {
            url: 'https://www.keytrudalenvimahcp.com/endometrial-carcinoma/',
            text: 'View Additional Information'
        },
    },
    // Adjuvant Therapy for Stage IB (T2a ≥4 cm), Stage II, or Stage IIIA NSCLC
    33: {
        adult: {
            dosing: `
                ${q3wInstruction}
                ${q6wInstruction}
            `,
            duration: `<p>Until disease recurrence, unacceptable toxicity, or up to 12 months</p>`,
        },
    },
    // Neoadjuvant Combination Therapy for Stage II, Stage IIIA, or Stage IIIB NSCLC and Continued as Adjuvant Monotherapy
    34: {
        adult: {
            dosing: `
                ${q3wInstruction}
                ${q6wInstruction}
                <li>Administer KEYTRUDA prior to chemotherapy when given on the same day</li>
            `,
            duration: `<p>Neoadjuvant treatment in combination with chemotherapy for 12 weeks or until disease progression that precludes definitive surgery or unacceptable toxicity, followed by adjuvant treatment with KEYTRUDA as a single agent after surgery for 39 weeks or until disease recurrence or unacceptable toxicity</p>`,
        },
    },
    //Biliary
    35: {
        adult: {
            headingAddon: `<sup>a</sup>`,
            dosing: `
                ${q3wInstruction}
                ${q6wInstruction}
                <li>Administer KEYTRUDA prior to chemotherapy when given on the same day</li>
            `,
            duration: `<p>Until disease progression, unacceptable toxicity, or up to 24 months</p>`,
        }
    },
    // aUC
    36: {
        adult: {
            headingAddon: `<sup>a</sup>`,
            dosing: `
                ${q3wInstruction}
                ${q6wInstruction}
                <li>Administer KEYTRUDA after enfortumab vedotin when given on the same day.</li>
            `,
            duration: `<p>Until disease progression, unacceptable toxicity, or up to 24 months</p>`,
        },
    },
    // FIGO 2014 Stage III-IVA Cervical Cancer
    37: {
        adult: {
            headingAddon: `<sup>a</sup>`,
            dosing: `
                ${q3wInstruction}
                ${q6wInstruction}
                <li>Administer KEYTRUDA prior to chemoradiotherapy when given on the same day</li>
            `,
            duration: `<p>Until disease progression, unacceptable toxicity, or for KEYTRUDA, up to 24 months</p>`,
        }
    }
   
};

export const cancerTypeFootnotes = {
    2: {},
    3: {
        footnotes: [
            {
                label: `a.`,
                text: `For combination therapy, refer to the Prescribing Information for the agents administered in combination with KEYTRUDA for recommended dosing information, as appropriate.`,
            },
        ],
        definitions: `NSCLC = non–small cell lung cancer; PD⁠-⁠L1 = programmed death ligand 1.`,
    },
    5: {
        footnotes: [
            {
                label: `a.`,
                text: `For combination therapy, refer to the Prescribing Information for the agents administered in combination with KEYTRUDA for recommended dosing information, as appropriate.`,
            },
        ],
        definitions: `HNSCC = head and neck squamous cell carcinoma; PD⁠-⁠L1 = programmed death ligand 1. `,
    },
    13: {
        definitions: `cHL = classical Hodgkin lymphoma. `,
    },
    14: {
        definitions: `PMBCL = primary mediastinal large B-cell lymphoma. `,
    },
    9: {
        footnotes: [
            {
                label: `a.`,
                text: `For combination therapy, refer to the Prescribing Information for the agents administered in combination with KEYTRUDA for recommended dosing information, as appropriate.`,
            },
        ],
    },
    10: {
        definitions: `NMIBC = non-muscle invasive bladder cancer. `,
    },
    15: {
        definitions: `MSI⁠-⁠H = microsatellite instability-high; dMMR = mismatch repair deficient.`,
    },
    16: {
        definitions: `MSI⁠-⁠H = microsatellite instability-high; dMMR = mismatch repair deficient; CRC = colorectal cancer.`,
    },
    11: {
        footnotes: [
            {
                label: `a.`,
                text: `For combination therapy, refer to the Prescribing Information for the agents administered in combination with KEYTRUDA for recommended dosing information, as appropriate.`,
            },
        ],
        definitions: `GEJ = gastroesophageal junction.`,
    },
    12: {
        footnotes: [
            {
                label: `a.`,
                text: `For combination therapy, refer to the Prescribing Information for the agents administered in combination with KEYTRUDA for recommended dosing information, as appropriate.`,
            },
        ],
        definitions: `GEJ = gastroesophageal junction; PD⁠-⁠L1 = programmed death ligand 1.`,
    },
    17: {
        footnotes: [
            {
                label: `a.`,
                text: `For combination therapy, refer to the Prescribing Information for the agents administered in combination with KEYTRUDA for recommended dosing information, as appropriate.`,
            },
        ],
        definitions: `PD⁠-⁠L1 = programmed death ligand 1; FIGO = International Federation of Gynecology and Obstetrics.`,
    },
    1: {},
    8: {
        // definitions: `MCC = merkel cell carcinoma.`,
    },
    4: {
        footnotes: [
            {
                label: `a.`,
                text: `For combination therapy, refer to the Prescribing Information for the agents administered in combination with KEYTRUDA for recommended dosing information, as appropriate.`,
            },
            {
                label: `b.`,
                text: `When axitinib is used in combination with KEYTRUDA, dose escalation of axitinib above the initial 5-mg dose may be considered at intervals of 6 weeks or longer.`,
            },
        ],
        definitions: `RCC = renal cell carcinoma.`,
    },
    // Endo
    21: {
        footnotes: [
            {
                label: `a.`,
                text: `For combination therapy, refer to the Prescribing Information for the agents administered in combination with KEYTRUDA for recommended dosing information, as appropriate.`,
            },
        ],
        definitions: `MSI⁠-⁠H = microsatellite instability-high; dMMR = mismatch repair deficient.`,
    },
    // 18: {
    //     definitions: `TMB-H = tumor mutational burden-high.`,
    // },
    7: {
        definitions: `cSCC = cutaneous squamous cell carcinoma.`,
    },
    19: {
        footnotes: [
            {
                label: `a.`,
                text: `For combination therapy, refer to the Prescribing Information for the agents administered in combination with KEYTRUDA for recommended dosing information, as appropriate.`,
            },
            {
                label: `b.`,
                text: `Patients who experience disease progression or unacceptable toxicity related to KEYTRUDA with neoadjuvant treatment in combination with 	chemotherapy should not receive adjuvant single agent KEYTRUDA.`,
            },
        ],
        definitions: `PD⁠-⁠L1 = programmed death ligand 1; TNBC = triple-negative breast cancer.`,
    },
    22: {
        footnotes: [
            {
                label: `a.`,
                text: `For combination therapy, refer to the Prescribing Information for the agents administered in combination with KEYTRUDA for recommended dosing information, as appropriate.`,
            },
        ],
    },
}