import React from 'react';
import styled from 'styled-components';
import * as colors from '../../util/colors';
import { Heading, Text, NotationBlock } from '../../util/typography';
import ComponentWrapper from '../layout/ComponentWrapper';
import { createMarkup } from '../../util/functions';

const ProfileHeading = styled.div`
    display: flex;
    flex-wrap: wrap;
    padding: 20px 0 10px;
    color: white;
    border-radius: 8px;
    --multiplier: calc(460px - 100%);
    background-image: radial-gradient(circle at 23% 0, #006ead, #36864a 87%);
`;

const AvatarContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    flex: 2 1 230px;
    min-width: 230px;
    flex-basis: calc(var(--multiplier) * 999);
    margin-bottom: 10px;
`;
const Avatar = styled.div`
    width: 100px;
    height: 100px;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    border-radius: 50%;
`;

const ProfileName = styled(Text.Eyebrow)`
    display: block;
    background-color: #326373;
    padding: 2px 10px;
    border-radius: 2px;
    margin-top: 10px;
    max-width: 93%;
    text-align: center;
    overflow-wrap: break-word;
    color: ${colors.mist};
`;

const HeadTextContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    flex: 2 1 280px;
    padding: 10px 20px;
    color: ${colors.white};

    span, h4 {
        color: ${colors.white};
    }
`;

const GridContainer = styled.div`
    display: flex;
    flex-wrap: wrap;
    --margin: 10px;
    --modifier: calc(40rem - 100%);
    margin: 15px calc(var(--margin) * -1);
`;

const GridWrapper = styled.div`
    overflow: hidden;
`;

// const GridRow = styled.div`
//     display: flex;
//     flex-wrap: wrap;
//     --multiplier: calc(768px - 100%);
//     margin: 0 -10px;
// `;

const GridItem = styled.div`
    flex-grow: 1;
    flex-basis: calc(var(--modifier) * 999);
    margin: var(--margin);
    min-width: calc(33% - 20px);
    max-width: 100%;
    display: flex;
`;
const CardWrapper = styled.div`
    position: relative;
    z-index: 5;
    ${({ shadow }) =>
        shadow &&
        `
        padding-right: 6px;
        height: calc(100% - 6px);
        &::after {
            content: "";
            position: absolute;
            bottom: -6px;
            right: 0;
            height: 100%;
            width: calc(100% - 8px);
            border-radius: 8px;
            background-image: radial-gradient(circle at 23% 0, #006ead, #36864a 87%);
            z-index: -1;
        }
    `}

    flex: 1 1 auto;
    display: flex;
`;
const Card = styled.div`
    background: white;
    padding: 16px 10px;
    border: 1px solid ${colors.silver};
    border-radius: 8px;
    text-align: center;
    flex: 1 1 auto;

    & span {
        display: block;
    }
`;

const CardText = styled(Heading.H5)`
    margin-top: 4px;
`;

const ListContainer = styled.div`
    color: ${colors.slate};
`;
const ListItem = styled.div`
    display: flex;
    justify-content: space-between;
    padding: 10px 0;
    border-bottom: 1px solid ${colors.silver};
    border-width: ${({ border }) => (border ? 1 : 0)};

    & span:first-child {
        white-space: nowrap;
        margin-right: 30px;
    }

    & span:last-child {
        text-align: right;
    }
`;

const ListValue = styled(Text.Body)`
    color: ${colors.ink};
`;

/*
const Data = {
    name: 'Dana',
    diagnosis: 'Nonsquamous Advanced Non-Samll Cell Lung Cancer',
    primaryCharacteristics: [
        {
            key: 'PD&#8288;-&#8288;L1 Expression',
            value: '>1%',
            emphasize: true,
        },
        {
            key: 'Biomarker Status',
            value: 'Negative for EGFR and ALK',
            emphasize: false,
        },
        {
            key: 'Treatment History',
            value: 'No prior history of therapy',
            emphasize: false,
        },
        {
            key: 'ECOG* Status',
            value: '1',
            emphasize: false,
        },
        {
            key: 'Histology',
            value: 'Clear cell',
            emphasize: false,
        },
    ],
    secondaryCharacteristics: [
        {
            key: 'Age',
            value: '72',
        },
        {
            key: 'Gender',
            value: 'Female',
        },
        {
            key: 'Smoking history',
            value: '30-pack-year history, quit 2 years ago',
        },
    ],
    footnotes: 'footnotes',
};
*/

const PatientProfile = props => {

    return (
        <ComponentWrapper>
            <ProfileHeading>
                <AvatarContainer>
                    <Avatar>
                        {typeof props.image === 'function' && props.image()}
                    </Avatar>
                    <ProfileName>{props.name}</ProfileName>
                </AvatarContainer>
                <HeadTextContainer>
                    <Text.Body>Diagnosis</Text.Body>
                    <Heading.H4 dangerouslySetInnerHTML={createMarkup(props.diagnosis)} />
                </HeadTextContainer>
            </ProfileHeading>
            <GridWrapper>
                <GridContainer>
                    {props.primaryCharacteristics.map((item, index) => (
                        <GridItem key={`cart-${index}`}>
                            <CardWrapper shadow={item.emphasize}>
                                <Card>
                                    <Text.Body dangerouslySetInnerHTML={createMarkup(item.key)} />
                                    <CardText dangerouslySetInnerHTML={createMarkup(item.value)} />
                                </Card>
                            </CardWrapper>
                        </GridItem>
                    ))}
                </GridContainer>
            </GridWrapper>

            <ListContainer>
                {props.secondaryCharacteristics.map((li, index) => (
                    <ListItem
                        key={`list-${index}`}
                        border={props.secondaryCharacteristics.length > index + 1}
                    >
                        <Text.Body dangerouslySetInnerHTML={createMarkup(li.key)} />
                        <ListValue dangerouslySetInnerHTML={createMarkup(li.value)} />
                    </ListItem>
                ))}
            </ListContainer>
            {(props.footnotes || props.definitions) && <NotationBlock footnotes={props.footnotes} definitions={props.definitions} />}
        </ComponentWrapper>
    );
};

export default PatientProfile;
