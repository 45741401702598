import React from 'react';
import styled from 'styled-components';
import { Heading, WYSIWYG } from '../../util/typography';
import PropTypes from 'prop-types';
import { createMarkup } from '../../util/functions';
import * as colors from '../../util/colors';

export const StyledDefinitionListItem = styled.div`
    padding: clamp(20px, 4%, 30px) 0;
    border-top: 1px solid limegreen;
    border-top: 1px solid var(--borderColor);
`;

const ItemRow = styled.div`
    display: flex;
    flex-wrap: wrap;
    --multiplier: calc(768px - 100%);
    margin: 0 -15px;
`;

const ItemTitleColumn = styled.div`
    min-width: calc(30% - 30px);
    flex-grow: 1;
    flex-basis: calc(var(--multiplier) * 999);
    padding: 0 15px;
    margin-bottom: 10px;
`;

const ItemContentColumn = styled.div`
    width: calc(70% - 30px);
    flex-grow: 2;
    padding: 0 15px;

    &:first-child {
        padding-top: 0;
        margin-top: 0;
    }

    &:last-child {
        padding-bottom: 0;
        margin-bottom: 0;
    }
`;

const ItemTitle = styled(Heading.H7)`

`;

// these may belong in the root wysiwyg
const ItemContentWysiwyg = styled(WYSIWYG)`
    .h6,
    h6 {
        margin-bottom: 16px;
    }

    p,
    .body {
        display: block;
        margin-bottom: 10px;
    }

    > *:first-child, ul > *:first-child {
        margin-top: 0;
    }
    > *:last-child, ul > *:last-child{
        margin-bottom: 0;
    }
    button.begin-chat {
        appearance: none;
        -webkit-appearance: none;
        border: 0;
        background: transparent;
        border-radius: 0;
        margin: 0;
        padding: 0;
        color: inherit;
        font: inherit;
        cursor: pointer;
        color: ${colors.blueberry};

        &:hover {
            color: ${colors.blueberryDark};
        }
    }
`;

const DefinitionListItem = props => {
    const { title, content } = props;
    return (
        <StyledDefinitionListItem>
            <ItemRow>
                <ItemTitleColumn>
                {title && <ItemTitle as='H4' theme={props.theme} dangerouslySetInnerHTML={createMarkup(title)} /> }
                </ItemTitleColumn>
                <ItemContentColumn>
                    <ItemContentWysiwyg theme={props.theme} dangerouslySetInnerHTML={{ __html: content }} />
                </ItemContentColumn>
            </ItemRow>
        </StyledDefinitionListItem>
    );
};

DefinitionListItem.propTypes = {
    title: PropTypes.string.isRequired,
    content: PropTypes.any,
};

export default DefinitionListItem;
