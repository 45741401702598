import React from 'react';
import styled from 'styled-components';
import * as colors from '../../../util/colors';
import { Text, Heading } from '../../../util/typography';
import ComponentWrapper from '../../layout/ComponentWrapper';
import { minusBlueberry, plusBlueberry } from '../../../util/icons';
import { createLocalScaleFunction, createMarkup } from '../../../util/functions';
import AnimateHeight from 'react-animate-height';
import TextBlock from '../../TextBlock';

const DrawerWrapper = styled.div`
    border: 1px solid ${colors.pebble};
    border-radius: 8px;
    margin-bottom: 10px;
    transition: 0.3s ease all;
    background-color: ${colors.white};

    &:last-child {
        margin-bottom: 0;
    }

    &:hover {
        box-shadow: 0 0 23px 0 rgba(0, 0, 0, 0.1);
        border: 1px solid ${colors.white};
    }
`;
const DrawerHeader = styled.button`
    cursor: pointer;
    padding: ${createLocalScaleFunction(10, 280, 16, 992)}
        ${createLocalScaleFunction(10, 280, 20, 992)};
    display: flex;
    max-width: 100%;
    user-select: none;
    align-items: center;
    transition: 0.3s ease all;
    border-bottom: ${({ isOpen }) =>
        isOpen ? `1px solid ${colors.pebble}` : `1px solid transparent`};
    transition: border-color 0.3s ease-in-out;
    width: 100%;
    appearance: none;
    -webkit-appearance: none;
    border-top: 0;
    border-left: 0;
    border-right: 0;
    background: transparent;
    text-align: left;

    &:hover {
        &::before {
            background-color: ${colors.mist};
            height: 34px;
        }
    }

    &::before {
        display: block;
        content: '';
        background-image: url(${({ isOpen }) => (isOpen ? minusBlueberry : plusBlueberry)});
        background-repeat: no-repeat;
        background-position: center;
        margin-right: 20px;
        width: 34px;
        height: 34px;
        border-radius: 50%;
        transition: 0.3s ease background-color;
        flex: 0 0 34px;
    }
`;
const DrawerContent = styled.div`
    overflow: hidden;
    padding: 0 20px;
    transition: all 0.3s;
    border-top: 0px solid transparent;

    &.active {
        padding: ${createLocalScaleFunction(30, 280, 40, 992)} 20px;
        border-top: 1px solid ${colors.silver};
    }

    > *:first-child {
        padding-top: 0;
        margin-top: 0;
    }

    > *:last-child {
        padding-bottom: 0;
        margin-bottom: 0;
    }
`;

const DrawerSubtitle = styled(Text.Body)`
    color: ${colors.slate};
    display: block;
`;

const DrawerHeaderInner = styled.div``;

const AccordionDrawer = props => {
    return (
        <DrawerWrapper>
            <DrawerHeader isOpen={props.isOpen} onClick={props.clickHandler} data-design-category="accordion_vd" aria-expanded={props.isOpen}>
                <DrawerHeaderInner>
                    <Heading.H6 as='H3' dangerouslySetInnerHTML={createMarkup(props.title)} />
                    {props.subtitle && <DrawerSubtitle dangerouslySetInnerHTML={createMarkup(props.subtitle)} />}
                </DrawerHeaderInner>
            </DrawerHeader>
            <AnimateHeight
                id={`accordion-drawer-${props.drawerId}`}
                duration={300}
                height={props.isOpen ? 'auto' : 0} // see props documentation below
            >
                <ComponentWrapper>
                        { props.transcript ? 
                            <DrawerContent><TextBlock>{props.children}</TextBlock></DrawerContent> 
                            : 
                            <DrawerContent>{props.children}</DrawerContent>
                        }
                </ComponentWrapper>
            </AnimateHeight>
        </DrawerWrapper>
    );
};

export default AccordionDrawer;
