import React from 'react';
import styled from 'styled-components';
import { Text } from '../../util/typography';
import * as colors from '../../util/colors';
import {
    chatDot1Blueberry,
    chatDot2Blueberry,
    chatDot3Blueberry,
    chatBubbleWhite,
    chatDot1White,
    chatDot2White,
    chatDot3White,
    chatBubblePebble,
} from '../../util/icons';
import { useSelector } from 'react-redux';
import { launchChatbotWindow } from './ChatbotLauncher';

const StyledChatbotLaunchButton = styled.button.attrs(props => ({
    'data-design-category': props.dataDesignCategory,
}))`
    cursor: pointer;
    border: none;
    background-color: transparent;
    
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0;
    margin: 0;

    > span {
        padding: 0.9em 0 0.8em;
        font-size: 12px;
        color: ${colors.white};
    }

    &::after {
        display: inline-block;
        content: '';
        background-image: url(${chatDot1Blueberry}), url(${chatDot2Blueberry}),
            url(${chatDot3Blueberry}), url(${chatBubbleWhite});
        background-repeat: no-repeat;
        background-position: center, left;
        background-size: 20px 20px;
        width: 20px;
        height: 20px;
        margin-left: 6px;
    }

    &:hover,
    &:active,
    &:focus {
        &::after {
            animation-duration: 0.8s;
            animation-name: chat-animation;
            animation-fill-mode: both;
            animation-iteration-count: infinite;
        }
    }

    @keyframes chat-animation {
        0%,
        90%,
        100% {
            background-position: center, center, center, center;
        }

        10% {
            background-position: center calc(50% - 1px), center, center, center;
        }

        20% {
            background-position: center calc(50% - 2px), center calc(50% - 1px), center, center;
        }

        30% {
            background-position: center calc(50% - 3px), center calc(50% - 2px),
                center calc(50% - 1px), center;
        }

        40% {
            background-position: center calc(50% - 3px), center calc(50% - 3px),
                center calc(50% - 2px), center;
        }

        50% {
            background-position: center calc(50% - 2px), center calc(50% - 3px),
                center calc(50% - 3px), center;
        }

        60% {
            background-position: center calc(50% - 1px), center calc(50% - 2px),
                center calc(50% - 3px), center;
        }

        70% {
            background-position: center calc(50% - 0px), center calc(50% - 1px),
                center calc(50% - 2px), center;
        }

        80% {
            background-position: center calc(50% - 0px), center calc(50% - 0px),
                center calc(50% - 1px), center;
        }
    }

    &[disabled] {
        pointer-events: none;
        
        > span {
            color: ${colors.pebble};
        }

        &::after {
            background-image: url(${chatDot1White}), url(${chatDot2White}), url(${chatDot3White}),
                url(${chatBubblePebble});
        }
    }
`;

const ChatbotLaunchButton = (props) => {
    const scrolledPastInPageSSI = useSelector(state => state.scrolledPastInPageSSI);
    const chatbotEnabled = useSelector(state => state.chatbotEnabled);

    return (
        <StyledChatbotLaunchButton dataDesignCategory="top_nav_vd" onClick={chatbotEnabled ? () => launchChatbotWindow({ scrolledPastInPageSSI: scrolledPastInPageSSI }) : undefined} {...props} disabled={!chatbotEnabled}>
            <Text.Button>Chat</Text.Button>
        </StyledChatbotLaunchButton>
    )
};

export default ChatbotLaunchButton;

