import React from 'react';
import styled from 'styled-components';
import { Heading, Text, WYSIWYG } from '../../../util/typography';
import * as colors from '../../../util/colors';
import ComponentWrapper from '../../layout/ComponentWrapper';
import { createMarkup } from '../../../util/functions';
import icon from '../../../assets/icon-zoom.svg';
import { NotationBlock } from '../../../util/typography';
import { createLocalScaleFunction } from '../../../util/functions';

const TABLE_BREAKPOINT = 780

const Table = styled.table`
  width: 100%;
  table-layout:fixed;

  border-collapse: collapse;
  border: none;
  border-bottom: 1px solid ${colors.keytrudaGreen};

  th {
    font-weight: 400; // 500 in Figma. this matches better visually
    padding: 10px 5px;
    max-width: 174px;
    width: ${props => props.widthIs}%;
    
    @media (min-width: ${TABLE_BREAKPOINT}px) {
      padding: 20px;
    } 
  }
`

const HeadingCell = styled.th`
  width: ${props => props.widthIs}%;
`

const Head = styled.thead`
  th:nth-child(1) { border-top-left-radius: 8px; }
  th:last-child { border-top-right-radius: 8px; }
  th:not(:last-child) { border-right: solid 1px ${colors.pebble}; }
  background-color: ${colors.charcoal};

  color: ${colors.white};
  font-size: 10px;
  line-height: 14px;

  @media (min-width: ${TABLE_BREAKPOINT}px) {
    font-size: 18px;
    line-height: 24px
  } 
`

const Body = styled.tbody`
  tr.even{ background-color: ${colors.cloud}; }
  tr.odd{ background-color: ${colors.mist}; }

  tr.green {
    background-color: ${colors.keytrudaGreen};

    // green cell font settings
    font-weight: 400; // 500 in Figma
    color: ${colors.ink};
    font-size: 10px;
    line-height: 14px;

    @media (min-width: ${TABLE_BREAKPOINT}px) {
      font-size: 16px;
      line-height: 24px;
    } 

    th { border-right: solid 1px ${colors.keytrudaGreenBackground}; }
    td:not(:last-child) { 
      border-right: solid 1px ${colors.keytrudaGreenBackground}; 
      padding: 10px 2.5px;

      @media (min-width: ${TABLE_BREAKPOINT}px) {
        padding: 20px;
      } 
    }
  }

  /* subheads */
  tr.subhead td {
    border-top: solid 1px ${colors.keytrudaGreen};
    border-bottom: solid 1px ${colors.keytrudaGreen};

    padding: 10px;
    text-align: left;
    background-color: ${colors.silver};

    font-size: 9px;
    font-weight: 400;
    line-height: 12px;
    color: ${colors.keytrudaGreenWeb};
    text-transform: uppercase;
    letter-spacing: -0.045px; // TODO: to keep or not to keep?

    @media (min-width: ${TABLE_BREAKPOINT}px) {
      padding: 10px 20px;

      font-size: 12px;
      line-height: 16px;
    } 
  }

  td:not(:last-child), th {
    border-right: solid 1px ${colors.pebble};
  }

  tr {
    text-align: left;
    &:not(:last-child){ border-bottom: solid 1px ${colors.pebble}; }
  }
  
  font-size: 9px;
  font-weight: 300; //400 figma
  line-height: 12px;
  color: ${colors.charcoal};

  @media (min-width: ${TABLE_BREAKPOINT}px) {
    font-size: 16px;
    line-height: 24px;
  } 

  th {
    padding-left: 10px;

    // left column specific
    @media (min-width: ${TABLE_BREAKPOINT}px) {
      padding-left: 20px;
      min-width: 190px;

      font-weight: 400; // 500 figma
      color: ${colors.ink};
    } 

    &.indented {
      padding-left: 16px;

      @media (min-width: ${TABLE_BREAKPOINT}px) {
        padding-left: 34px;
      } 
    }
  }

  td { text-align: center; }
`

// prop styles
const HeaderContainer = styled.div`
    margin-bottom: ${createLocalScaleFunction(16, 320, 20, 992)};
`;

const TableTitle = styled(Heading.H4)``;

const Limitation = styled(Text.Body)`
    margin-top: 10px;
`;

const PinchContainer = styled.div`
    display: flex;
    align-items: center;
    margin-top: 16px;

    @media (hover:hover) {
        display: none;
    }
`;

const PinchIcon = styled.span`
    height: 40px;
    width: 40px;
    display: block;
    background-size: 35px 35px;
    background-repeat: no-repeat;
    background-image: url(${icon});
    margin-right: 6px;
`;

const PinchLabel = styled(Text.Eyebrow)`
    text-transform: uppercase;
    color: ${colors.slate};
`;

const AdditionalInformationTitle = styled(Heading.H5)`
    margin-top: 30px;
`;

const AdditionalInformationDescription = styled(WYSIWYG)`
    display: inline-block;
    margin-top: 10px;
`;

const HtmlTable = props => {
  const cellLength = props.rows[props.rows.length-1].length // measuring the count of last row of cells as the first row cells are highly likely to have colspan > 1

  const indexSubheadRows = (rows) => {
    let subheadIndex = -1;
    for(var i = 0; i < rows.length; i++){
      if(rows[i] && rows[i][0].subhead){
        subheadIndex = 0;
      } else if(subheadIndex >= 0){
        rows[i][0].subheadIndex = subheadIndex++;
      }
    }
    return rows;
  };

  const indexedRows = indexSubheadRows(props.rows);

    return (
        <ComponentWrapper>
           <HeaderContainer>
                {props.title && <TableTitle as='H3' theme={props.theme} dangerouslySetInnerHTML={createMarkup(props.title)}/>}
                {props.limitation !== '' && (
                    <Limitation forwardedAs="div" theme={props.theme}>
                        {props.limitation}
                    </Limitation>
                )}

                <PinchContainer>
                    <PinchIcon />
                    <PinchLabel>Pinch to zoom & enlarge</PinchLabel>
                </PinchContainer>
            </HeaderContainer>
            <Table>
                <Head>
                    <tr>
                      {props.headings.map((heading, i) => (
                          heading.colspan ?
                          <th key={i} colspan={heading.colspan} widthIs={i === 0 ? props.width : ''} dangerouslySetInnerHTML={createMarkup(heading.title)}/>
                          :
                          <HeadingCell key={i} widthIs={i === 0 ? props.width : ''} dangerouslySetInnerHTML={createMarkup(heading.title)}/>
                      ))}
                    </tr>
                </Head>
                <Body>
                    { props.greenHeading &&
                      <tr className='green'>
                        {props.greenHeading.map((gheading, g) => (
                          g === 0 ? <th key={g} colspan={gheading.colspan} dangerouslySetInnerHTML={createMarkup(gheading)}/> : <td key={g} colspan={gheading.colspan} dangerouslySetInnerHTML={createMarkup(gheading)}/>
                        ))}
                      </tr>
                    }
                    {indexedRows.map((row, r) => (
                          row[0].subhead ?
                            <tr className='subhead'><td colspan={cellLength} dangerouslySetInnerHTML={createMarkup(row[0].subhead)}/></tr>
                          :
                          (<tr key={r} className={('subheadIndex' in row[0] &&  row[0].subheadIndex % 2 === 0) || (!row[0].subheadIndex && r % 2 === 0) ? 'even' : 'odd'}>
                            {row.map((cell, c) => (
                              c === 0 ? 
                                <th key={c} colspan={cell.colspan} className={cell.indent ? 'indented' : ''} dangerouslySetInnerHTML={createMarkup(cell.content)}/> 
                                : 
                                <td key={c} colspan={cell.colspan} dangerouslySetInnerHTML={createMarkup(cell.content)}/>
                            ))}
                          </tr>)
                        
                    ))}
                </Body>
            </Table>
            {props.additionalInformation && 
                <>
                    {props.additionalInformation.map((item, index) => 
                        <div key={index}>
                            {item.title && <AdditionalInformationTitle as='H4' dangerouslySetInnerHTML={{__html: item.title}}></AdditionalInformationTitle> }
                            {item.text && <AdditionalInformationDescription dangerouslySetInnerHTML={{__html: item.text}}></AdditionalInformationDescription> }
                        </div>
                    )}
                </>
            }

            {(props.footnotes || props.definitions) && <NotationBlock footnotes={props.footnotes} definitions={props.definitions} />}
        </ComponentWrapper>
    );
};

export default HtmlTable;
