import React, { useState, useEffect, useContext, useRef } from 'react';
import styled from 'styled-components';
import * as colors from '../../util/colors';
import { Heading, Text } from '../../util/typography';
import { NAVIGATION_BREAKPOINT, NAVIGATION_BREAKPOINT_UNITLESS } from '../../constants/layout';
import { useSelector, useDispatch } from 'react-redux';
import {
    openFilter,
    closeFilter,
    openOverlay,
    closeOverlay,
    preventScrolling,
    allowScrolling,
} from '../../store/actions';
// import { createOverlay, DEFAULT_OVERLAY_KEY } from '../Overlay';
import useOnClickOutside from 'use-onclickoutside';
import useKeydownHandler from '../../util/accessibility/useKeydownHandler';

import { checkAndTriggerCancerTypeChange } from '../../util/functions';
import useTrapFocus from '../../util/accessibility/useTrapFocus';
import { cancerTypes } from '../../util/data/cancerTypes';
import { SortMethods, sortByDefault, sortByAZ, sortBySearch } from './SortMethods';
import { closeBlueberry, closeBlueberryDark } from '../../util/icons';
import FilterNav from './FilterNav';
import FilterHeader from './FilterHeader';
import TypesView from './TypesView';
import * as FilterButtons from './FilterButtons';
import { FilterContext } from '../FilterProvider';

const bp = NAVIGATION_BREAKPOINT_UNITLESS;

const Wrapper = styled.div`
    width: 100%;
    flex: 0 auto;
    overflow: hidden;
    display: flex;
    flex-flow: column nowrap;
    // padding: 20px;
`;

const DrawerContainer = styled.div`
    --UtilityNavHeight: 42px;
    --StickyISI: 130px;
    --filterBanner: 40px;
    --visualPadding: 10px;
    background-color: ${colors.cloud};
    box-sizing: border-box;
    visibility: ${({ open }) => open ? 'visibile' : 'hidden'};
    max-height: ${({ open }) =>
        open
            ? 'calc(100vh - var(--UtilityNavHeight) - var(--StickyISI) - var(--visualPadding))'
            : '0vh'};
    display: flex;
    flex-flow: column nowrap;
    overflow: hidden;
    transition: max-height 0.3s ease-in-out;
    max-width: 100%;

    @media (min-width: ${NAVIGATION_BREAKPOINT}) {
        max-height: ${({ open }) =>
            open
                ? 'calc(100vh - var(--UtilityNavHeight) - var(--StickyISI) - var(--visualPadding))'
                : '0vh'};
    }
`;

const HeaderContainer = styled.div`
    display: flex;
    align-items: center;
    position: relative;
    background-color: ${colors.white};
    padding: 6px 0;
    margin: 0 auto;
    width: 100%;
    
    > div {
        max-width: 1032px;
        padding: 0 20px;
        margin: 0 auto;
        width: 100%;
        box-sizing: border-box;
    }

    @media (max-width: ${bp - 1}px) {
        display: none;
    }
`;

const OpenStateContainer = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
`;

const CloseButton = styled.button`
    flex: 0 auto;
    display: inline-flex;
    justify-content: center;
    white-space: nowrap;
    background: none;
    border: none;
    border-radius: 4px;
    color: ${colors.blueberry};
    padding: 4px 16px 4px 16px;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;

    &:hover {
        color: ${colors.blueberryDark};

        &::after {
            background-color: ${colors.mist};
            background-image: url(${closeBlueberryDark});
        }
    }

    & > span {
        display: inline-block;
        color: currentColor;
        line-height: 1;
        padding: 0.2em 0;
    }

    &::after {
        content: '';
        display: inline-block;
        width: 32px;
        height: 32px;
        background-color: transparent;
        border-radius: 50%;
        background-image: url(${closeBlueberry});
        background-size: 13.5px 13.5px;
        background-repeat: no-repeat;
        background-position: center center;
        border: none;
        color: transparent;
        padding: 0;
        margin: -15px -26px -16px 6px;
        transition: 0.3s background-color ease-in-out;
    }
`;

const DrawerContent = styled.div`
    --topPadding: clamp(20px, 3vw, 40px);
    max-width: 1032px;
    width: 100%;
    padding: var(--topPadding) 20px 0;
    margin: 0 auto;
    box-sizing: border-box;
    flex: 1 1 auto;
    overflow: auto;
`;

const FooterContainer = styled.div`
    display: flex;
    flex-wrap: wrap;
    margin-top: 35px;
    justify-content: space-between;
    padding-bottom: 40px;
`;

const Message = styled(Heading.H5)`
    margin-bottom: 20px;

    @media (min-width: ${NAVIGATION_BREAKPOINT}) {
        display: none;
    }
`;

const CustomizeFilter = ({ filter_wrapper_ref }) => {
    /**
     * CustomizeFilter is opened with the TriageList's FilterTrigger button. The menu allows the user to filter TriageList by one
     * or more selected cancerTypes. CustomizeFilter is conditionally rendered only if a page has been wrapped with a FilterProvider.
     */
    const clearButtonRef = useRef(null);
    const closeButtonRef = useRef(null);

    const filterOpen = useSelector(state => state.filterOpen);
    const { filteredCancerTypes, setFilteredCancerTypes } = useContext(FilterContext);
    const dispatch = useDispatch();

    const [sortedList, setSortedList] = useState({});
    const [sortMethod, setSortMethod] = useState(SortMethods.DEFAULT);
    const [search, setSearch] = useState('');

    useEffect(() => {
        // this is a dependency of this hook, but it seems like we may only want to run this onece
        // we may need to add the function INSIDE the effect
        setSortedList(sortByDefault(cancerTypes));
    }, []);
    
    // Set focus in first focusable element of CustomizeFilter
    useEffect(() => {
        const getFirstFocusableElement = () => {
            if ( clearButtonRef.current && !clearButtonRef.current.disabled ) return clearButtonRef.current;
            if ( closeButtonRef.current ) return closeButtonRef.current;        
            return;
        }

        if (filterOpen) {
            const button = getFirstFocusableElement();
            
            if (button) {
                button.focus()
            }
        }
    }, [filterOpen]);

    // Trap focus inside the CustomizeFilter
    useTrapFocus(filter_wrapper_ref, filterOpen);

    // Close when user uses 'Escape'
    useKeydownHandler(() => {
        if (filterOpen) {
            dispatch(closeFilter());
            dispatch(closeOverlay());
            dispatch(allowScrolling());
            checkAndTriggerCancerTypeChange();
        }
    }, 'Escape');


    // Close everything when we click outside the main nav
    // This hook seems to be choking on the callback ref.
    // Added an aditional div to attach a ref using the useRef hook in
    // place of the callbackRef
    useOnClickOutside(filter_wrapper_ref, e => {
        /**
         * If we click on the Sticky ISI launch button, bail early.
         */
        // if (launch_button_container_ref?.current?.contains(e.target)) {
        //     return;
        // }

        /**
         * Only close everything if something is open.
         * Only works for desktop. To get this working on Mobile, we'd need 2 `useOnClickOutside` calls.
         * Aka, don't fire `closeAllHandler` _every_ time we click outside.
         */
        if (filterOpen != null && filterOpen) {
            handleToggleClick();
        }
    });

    const handleToggleClick = () => {
        if (!filterOpen) {
            dispatch(openFilter());
            dispatch(openOverlay());
            dispatch(preventScrolling());
        } else {
            dispatch(closeFilter());
            dispatch(closeOverlay());
            dispatch(allowScrolling());
            checkAndTriggerCancerTypeChange();
        }
    };

    // User Selections
    const userSelecting = e => {
        const { checked, value } = e.target;
        let _id = parseInt(value);
        let _filteredCancerTypes = [...filteredCancerTypes];

        if (checked) {
            _filteredCancerTypes.push(_id);
        } else {
            _filteredCancerTypes.splice(_filteredCancerTypes.indexOf(_id), 1);
        }

        setFilteredCancerTypes(_filteredCancerTypes);
    };

    const resetUserSelectionHandler = () => {
        setFilteredCancerTypes([]);
    };

    // change sort
    const changeSortHandler = (sortMethod, value) => {
        setSortMethod(sortMethod);
        let _sortedList = null;

        switch (sortMethod) {
            case SortMethods.DEFAULT:
                setSearch('');
                _sortedList = sortByDefault(cancerTypes);
                break;
            // case SortMethods.DATE:
            //     setSearch('');
            //     _sortedList = sortByDate(cancerTypes);
            //     break;
            case SortMethods.A_Z:
                setSearch('');
                _sortedList = sortByAZ(cancerTypes);
                break;
            case SortMethods.SEARCH:
                setSearch(value);
                _sortedList = sortBySearch(cancerTypes, value);
                break;
            default:
                break;
        }

        setSortedList(_sortedList);
    };

    return (
        <>
            <Wrapper>
                <div ref={filter_wrapper_ref}>
                    <DrawerContainer open={filterOpen} aria-hidden={!filterOpen} role="region">
                        {/* On builds, the Media element renders on mobile and DrawerContent does not. */}
                        {filterOpen &&
                            <>
                                <HeaderContainer>
                                    <div>
                                        <OpenStateContainer>
                                            <FilterButtons.ResetFilterPrompt onClick={resetUserSelectionHandler} forwardedRef={clearButtonRef} />

                                            <CloseButton aria-label="Close" onClick={handleToggleClick} ref={closeButtonRef}>
                                                <Text.Button>Close </Text.Button>
                                            </CloseButton>
                                        </OpenStateContainer>
                                    </div>
                                </HeaderContainer>
                                <FilterHeader
                                    resetUserSelection={resetUserSelectionHandler}
                                    setShow={handleToggleClick}
                                />
                            </>
                        }
                        <DrawerContent>
                            {/* Message only displays on small screens */}
                            <Message>
                                Select 1 or more. Filters only apply to website pages with content
                                about specific tumors.
                            </Message>
                            <FilterNav
                                sortMethod={sortMethod}
                                search={search}
                                onChangeFilter={changeSortHandler}
                            />

                            <TypesView
                                data={sortedList}
                                onSelectCard={userSelecting}
                                sortMethod={sortMethod}
                            />

                            <FooterContainer>
                                <FilterButtons.ResetFilterPrompt
                                    onClick={() => resetUserSelectionHandler()}
                                />
                                <FilterButtons.ApplyButton
                                    filteredCancerTypes={filteredCancerTypes}
                                />
                            </FooterContainer>
                        </DrawerContent>
                    </DrawerContainer>
                </div>
            </Wrapper>
        </>
    );
};

export default CustomizeFilter;
