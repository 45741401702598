import React from 'react';
import styled from 'styled-components';
import MenuLink from './MenuLinkSmall';
import { Heading } from '../../../util/typography';
import * as colors from '../../../util/colors';

const SubMenuList = styled.ul`
    list-style: none;
    padding: 0;
`;

const SubMenuHeader = styled.header`
    margin: 20px 20px 0 20px;
    padding-bottom: 20px;
    border-width: 0 0 2px 0;
    border-style: solid;
    border-image-slice: 1;
    border-image-source: linear-gradient(
        to right,
        ${colors.keytrudaGreen} 0,
        ${colors.keytrudaGreen} 30px,
        transparent 31px,
        transparent 100%
    );
`;

const SubMenuItem = styled.li`
    text-align: left;
    list-style: none;
`;

const SubMenuLink = styled(MenuLink)`
    color: ${colors.blueberry};
`;

const SubMenu = ({ item , closeAllHandler }) => {
    //filter out the line breaks needed for the large screen display
    let label = item.label.replace(/<[^>]*>?/gm, '');
    return (
        <>
            <SubMenuHeader>
                <Heading.H4>{label}</Heading.H4>
            </SubMenuHeader>
            <SubMenuList>
                {item.children.map((link, i) => (
                    <SubMenuItem key={i} onClick={closeAllHandler}>
                        <SubMenuLink label={link.label} url={link.url} ></SubMenuLink>
                    </SubMenuItem>
                ))}
            </SubMenuList>
        </>
    );
};

export default SubMenu;
