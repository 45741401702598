import React from 'react';
import styled from 'styled-components';
import * as colors from '../../util/colors';
import { WYSIWYG, Heading } from '../../util/typography';
import * as List from '../../util/typography/List';
import { componentTheme, createMarkup } from '../../util/functions';
import { between } from 'polished';
import warningImage from '../../assets/psaWarnRed.svg';


const Container = styled.div`
    padding: 0;

    > div {
        margin: 0 auto;
        display: flex;
        flex-flow: row wrap;
    }

    hr {
        border: 0;
        height: 1px;
        background-color: ${colors.keytrudaGreen};
        margin-top: 10px;
        margin-bottom: 30px;

        @media (min-width: 321px) and (max-width: 1032px) {
            margin-top: ${between('10px', '20px', '320px', '1032px')};
            margin-bottom: ${between('30px', '40px', '320px', '1032px')};
        }

        @media (min-width: 1033px) {
            margin-top: 20px;
            margin-bottom: 40px;
        }
    }
`;

const ImageWrapper = styled.div`
    flex: 0 1 316px;
    margin: 0 20px 20px 0;

    @media(max-width: 420px) {
        margin-right: 0;
    }

    img {
        width: 100%;
    }
`;

const ContentContainer = styled.div`
    flex: 1 1 340px;
`;

const StepNumContainer = styled.div`
    width: 74px;
    height: 74px;
    margin-bottom: 20px;
    padding: 13px 15px 14px 14px;
    border: solid 1px #bebebe;
    background-color: #fff;
    border-radius: 50%;
`;

const PSAInstruction = styled(Heading.H4)`
    max-width: 571px;
    margin: 0 0 15px 0;
`;

const PSADetails = styled(WYSIWYG)`
    margin: 15px 0 20px;
    max-width: 571px;
`;

const WarningNumContainer = styled(StepNumContainer)`
    margin: 20px 0;
`;

const WarningText = styled(Heading.H4)`
    max-width: 571px;
    margin: 20px 0;
`;

const Warning = props => {
    return (
        <>
            <WarningNumContainer>
                <img src={warningImage} alt='' width='45' height='47' />
            </WarningNumContainer>
            <WarningText>{props.text}</WarningText>
        </>
    )
};

const PSAImageStepsList = ({ data, theme }) => {
    return (
        <>
            {data.map((listItemData, i) => (
                <PSAImageSteps {...listItemData} key={`psaImage-${i}`} step={i + 1} theme={theme} end={listItemData.end} bullets={listItemData.bullets}/>
            ))}
        </>
    );
};

// @todo implement theme
const PSAImageSteps = props => {
    let theme = componentTheme(true, props.sectionTheme, props.theme);
    return (
        <Container>
            <div>
                <ImageWrapper>
                    <img src={props.image} alt={props.alt} />
                </ImageWrapper>
                <ContentContainer>
                    {props.count && <StepNumContainer>
                        {props.count && <img src={props.count} width='45' height='47' alt={props.stepNumAlt} />}
                    </StepNumContainer> }
                    <PSAInstruction>
                        {props.instruction}
                    </PSAInstruction>
                    {props.details && 
                        <PSADetails dangerouslySetInnerHTML={createMarkup(props.details)} />
                    } 
                    {props.warning && <Warning text={props.warning} />}
                </ContentContainer>
            </div>
            {!props.end && <hr theme="dark" /> } 
        </Container>
    );
};

export default PSAImageStepsList;
