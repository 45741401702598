import React, { useState } from 'react';
import styled from 'styled-components';
import * as CTA from '../../util/CTA';
import * as colors from '../../util/colors';
import { between } from 'polished';
import { Heading, Text } from '../../util/typography';
import { createMarkup } from '../../util/functions';

const classNamePrefix = 'tiered-cta';

// The dollar sign prevents cardprefix from being added to Container as an HTML attribute
const Container = styled.a.attrs(props => ({
    className: `${props.$cardPrefix}-card-container`
}))`
    text-decoration: none;
    cursor: pointer;
    display: flex;
    flex-wrap: wrap;
    padding: 30px clamp(20px, ${between('20px', '30px', '620px', '1032px')}, 30px) 18px;
    border-radius: 8px;
    border: 1px solid ${colors.pebble};
    background: white;
    transition: 0.3s ease all;
    flex-grow: 1;
    &:hover {
        border-color: ${colors.white};
        box-shadow: 0 4px 20px 0 rgba(0, 0, 0, 0.16);
    }
`;

const ImageWrapper = styled.div.attrs(props => ({
    className: `${props.cardPrefix}-image-wrapper`
}))`
    padding: 0 0 20px;
    pointer-events: none; //resolves issue where hovering over image 'stops' onMouseOver for the Card

    & img {
        width: 60px;
        height: 60px;
    }
`;

const ContentWrapper = styled.div.attrs(props => ({
    className: `${props.cardPrefix}-content-wrapper`
}))`
    display: flex;
    flex-direction: column;
    ${props => props.verticalCenter && `justify-content: center;`}
    align-items: ${props => props.rowTier === '3' ? 'flex-start' : 'center'};
    flex-grow: 1;
    flex-shrink: 1;
    flex-basis: calc(100% - 100px);
    text-align: ${props => props.rowTier === '3' ? 'left' : 'center'};
`;

const Eyebrow = styled(Text.Eyebrow)`
    color: ${colors.slate};
    margin-bottom: ${between('6px', '10px', '320px', '1032px')};

    @media (max-width: 320px) {
        margin-bottom: 6px;
    }

    @media (min-width: 1032px) {
        margin-bottom: 10px;
    }
`;

const Title = styled(Heading.H4).attrs(props => ({
    className: `${props.cardPrefix}-title-text`
}))`
    ${props => props.rowTier !== '3' && `
        max-width: max(60%, 22ch);
    `}
`;

const Body = styled(Text.Body).attrs(props => ({
    className: `${props.cardPrefix}-body-text`
}))`
    margin-top: 10px;
`

const LinkContainer = styled.div`
    ${props => !props.verticalCenter && `margin-top: auto;`}

    @media (max-width: 768px) {
        width: 100%;
        align-items: stretch;
    }
`;

const PrimaryButton = styled(CTA.PrimaryButton).attrs(props => ({
    className: `${props.cardPrefix}-button`
}))`
    margin-top: ${between('20px', '30px', '320px', '1032px')};
    margin-bottom: ${between('12px', '22px', '320px', '1032px')};

    @media (max-width: 320px) {
        margin-top: 20px;
        margin-bottom: 12px;
    }

    @media (min-width: 1032px) {
        margin-top: 30px;
        margin-bottom: 22px;
    }
`;

const TertiaryButton = styled(CTA.TertiaryButton).attrs(props => ({
    className: `${props.cardPrefix}-button`
}))`
    margin-top: 3px;
    margin-bottom: ${between('0px', '3.5px', '320px', '1032px')};

    @media (max-width: 320px) {
        margin-bottom: 0px;
    }

    @media (min-width: 1032px) {
        margin-bottom: 3.5px;
    }

    ${props => props.rowTier === '2' && `
        margin-top: ${between('13px', '3px', '320px', '1032px')};

        @media (max-width: 320px) {
            margin-top: 13px;
        }

        @media (min-width: 1032px) {
            margin-top: 3px;
        }
    `}
`;

const BadgeWrapper = styled.div`
    margin: 0 0 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
`;

const Badge = styled(Text.Eyebrow).attrs(props => ({
    className: `${props.cardPrefix}-badge`
}))`
    font-size: 13px;
    color: white;
    background:
        radial-gradient(circle at 16% 5%,
            ${colors.blueberry} 0%,
            ${colors.keytrudaGreenWebForBanner} 70%,
            ${colors.keytrudaGreenWebForBanner} 85%);
    padding: 8px 16px 7px;
    border-radius: 4px;

    ${props => props.verticalCenter
        ? 'margin-top: 15px;'
        : `margin-top: clamp(0px, ${between('0px', '22px', '420px', '580px')}, 22px);`
    }

    margin-bottom: clamp(0px, ${between('0px', '7px', '420px', '580px')}, 7px);
`;

//@TODO need to resolve alt tag for image

const Card = props => {
    const { rowKey, colKey } = props;
    const cardPrefix = `${classNamePrefix}-r${rowKey + 1}-c${colKey + 1}`;
    const [isCardHovered, setIsCardHovered] = useState(false);
    const onCardMouseEnter = () => {
        setIsCardHovered(true);
    };

    const onCardMouseLeave = () => {
        setIsCardHovered(false);
    };

    const Button = props.rowTier === '1' ? PrimaryButton : TertiaryButton;
    // Use the CTA's href and target props if we need to open an internal link in a new tab (/interactivepi/)
    const conditionalCTAProps = props.href 
        ? { href: props.href, target: props.target }
        : {};

    return (
        <Container {...CTA.setPropsFromURL(props.url)} {...conditionalCTAProps} $cardPrefix={cardPrefix} onMouseEnter={onCardMouseEnter} onMouseLeave={onCardMouseLeave} data-design-category="button_vd">
            <ContentWrapper cardPrefix={cardPrefix} rowTier={props.rowTier} span="col-primary" verticalCenter={props.verticalCenter}>
                {props.image && 
                    <ImageWrapper cardPrefix={cardPrefix}>
                        {typeof props.image === 'function' && props.image()}
                    </ImageWrapper>
                }
                {props.badge &&
                    <BadgeWrapper>
                        <Badge verticalCenter={props.verticalCenter} cardPrefix={cardPrefix} dangerouslySetInnerHTML={createMarkup(props.badge)} />
                    </BadgeWrapper>
                }
                {props.category && <Eyebrow>{props.category}</Eyebrow>}
                <Title cardPrefix={cardPrefix} rowTier={props.rowTier}>{props.title}</Title>
                {props.body && <Body cardPrefix={cardPrefix}>{props.body}</Body>}
                <LinkContainer verticalCenter={props.verticalCenter}>
                    <Button
                        cardPrefix={cardPrefix}
                        rowTier={props.rowTier}
                        forwardedAs="span"
                        label={props.buttonLabel}
                        url={props.url}
                        parentHovered={isCardHovered}
                        {...props.buttonProps}
                    />
                </LinkContainer>
            </ContentWrapper>
        </Container>
    );
};

export default Card;
