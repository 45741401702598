import React from 'react';
import styled from 'styled-components';
import { Text } from '../../util/typography';
import { createMarkup, stringToSlug } from '../../util/functions';
import { arrowRightBlueberry } from '../../util/icons';

const StyledAnchorLink = styled.a.attrs(props => ({
    'data-design-category': props.dataDesignCategory || props['data-design-category'] || 'content_link_vd'
}))`
    display: flex;
    align-items: flex-start;
    color: var(--linkColor);
    text-decoration: none;

    &:hover div::before{
        animation-duration: .3s;
        animation-name: slide-down;
        animation-fill-mode: both;
    }

    @keyframes slide-down {
        0% {
            background-position: center 50%, left;
        }

        50% {
            background-position: calc(100% + 20px) center, center;
        }

        50.001% {
            background-position: calc(0% - 20px) center, center;
        }

        100% {
            background-position: center 50%, right;
        }
    }
`;

const AnchorArrowContainer = styled.div`
    height: 40px;
    min-width: 20px;
    position: relative;
    overflow: hidden;

    ::before {
        display: inline-block;
        content: '';
        background-image: url(${arrowRightBlueberry});
        background-repeat: no-repeat;
        background-position: center, center, left;
        background-size: 10px 10px, 10px 10px, 200% 100%;
        width: 20px;
        height: 20px;
        transform: rotate(90deg);
        margin: 2px 4px 0 -2px;
        position: absolute;
    }
`

const AnchorText = styled(Text.Body)`
    color: inherit;
`;

// TEMPORARY HACK FOR LAUNCH - several submissions were ATD'd with mismatched anchor link text/target section titles. This labelTextOverride is not intended to be used, and should be removed ASAP.
const AnchorLink = ({ label, labelTextOverride }) => {
    let text = labelTextOverride || label;
    return (
        <StyledAnchorLink href={`#${stringToSlug(label)}`}>
            <AnchorArrowContainer />
            <AnchorText dangerouslySetInnerHTML={createMarkup(text)} />
        </StyledAnchorLink>
)};

AnchorLink.defaultProps = {
    href: '#',
    label: 'Anchor Link',
};

export default AnchorLink;
