import React from 'react';
import styled from 'styled-components';
import { rgba } from 'polished';
import * as Colors from '../../util/colors';
import { useSelector } from 'react-redux';
import { STYLED_OVERLAY } from '../../constants/z-index-map';
import { isValidElementType } from 'react-is';

const StyledOverlay = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    height: 100vh;
    z-index: ${STYLED_OVERLAY};
    background-color: ${rgba(Colors.ink, 0.8)};
    display: ${({ isActive }) => (isActive ? 'block' : 'none')};
    //not clear if this is having an effect ...
    opacity: ${({ isActive }) => (isActive ? 1 : 0)};
    transition: opacity 1s ease-in-out 0.1s;
    overscroll-behavior: contain;
`;

/**
 * We have a single place of global state for overlays, (currently) stored in `overlayActive`.
 * This means we only have a _single_ overlay, a sibling within our main element. This is problematic
 * when we want the overlay to sit _in between_ components, e.g. the mobile open menu.
 *
 * To solve for this, we create _different_ overlays that are triggered with some
 * unique string key. So, dispatching that key opens _that specific overlay._
 *
 * We also can create an overlay that allows for `overlayActive === true`, that is,
 * a default overlay that doesn't necessarily need the unique key.
 *
 * @param {String} unique_key
 * @param {Object} options
 * @param {Boolean} [options.is_default] If true, allows for boolean state values to open the overlay
 * @param {Function} [options.extendsOverlay] Allows us to change the underlying styled-component the overlay will use. Called with `StyledOverlay`, this should return a new React element (e.g. '(StyledOverlay) => styled(StyledOverlay)`color: red`').
 * @example
 *     createOverlay('BLUE_OVERLAY', {
 *         extendsOverlay: StyledOverlay =>
 *             styled(StyledOverlay)`
 *                 background: blue;
 *             `,
 *     });
 *
 * @returns {Function} Returns a new React component.
 */
export function createOverlay(unique_key, { is_default, extendsOverlay } = {}) {
    // Create a new StyledOverlay if we passed in a `extendsOverlay` function
    let StyledOverlayComponent = StyledOverlay;
    if (typeof extendsOverlay === 'function') {
        let NewStyledOverlay = extendsOverlay(StyledOverlay);
        if (isValidElementType(NewStyledOverlay)) {
            // Use newly created React Element
            StyledOverlayComponent = NewStyledOverlay;
        } else {
            if (process.env.NODE_ENV === 'development') {
                console.warn(
                    'Invalid React element returned from `extendsOverlay` function. Using default `StyledOverlay` for Overlay with key: ' +
                        unique_key
                );
            }
        }
    }

    const NewOverlay = props => {
        const overlayActive = useSelector(state => state.overlayActive);
        const is_active =
            overlayActive &&
            (overlayActive === unique_key || (overlayActive === true && is_default));

        return <StyledOverlayComponent isActive={is_active} {...props} />;
    };
    NewOverlay.displayName = `Overlay_${unique_key}`;

    return NewOverlay;
}
export const DEFAULT_OVERLAY_KEY = 'DEFAULT_OVERLAY';
const DefaultOverlay = createOverlay(DEFAULT_OVERLAY_KEY, { is_default: true });

export default DefaultOverlay;
