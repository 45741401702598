import React from 'react';
import ComponentWrapper from '../layout/ComponentWrapper';
import styled from 'styled-components';
import { createMarkup, componentTheme, createLocalScaleFunction } from '../../util/functions';
import { Text, NotationBlock } from '../../util/typography';
import InfoIcon from '../../assets/icon-info.svg';
import * as colors from '../../util/colors';
import Nub from '../../assets/nub.svg'

const Wrapper = styled.div`
    display: flex;
    align-items: center;
`;

const InfoIconContainer = styled.div`
    margin-right: 10px;
`

const TextBoxContainer = styled.div`
    border: 1px solid ${colors.pebble};
    border-radius: 8px;
    background-color: ${colors.white};
    padding: 10px 14px;
    position: relative;
    padding: ${createLocalScaleFunction(10, 219, 15, 931)}
            ${createLocalScaleFunction(14, 219, 20, 931)};

    &::before {
        content: "";
        background-image: url(${Nub});
        position: absolute;
        height: 28px;
        width:28px;  
        left: -14px;  
        top: 50%;  
        transform: translateY(-50%);
    }
`

const InlineImage = styled.div`
    margin-right: 20px;
    box-shadow: 0 0 0 0 rgba(108, 192, 74, 1);
	height: 20px;
	width: 20px;
	transform: scale(1);
	animation: pulse 2s infinite;
    border-radius: 50%;

    @keyframes pulse {
        0% {
            transform: scale(0.95);
            box-shadow: 0 0 0 0 rgba(108, 192, 74, 0.7);
        }
        
        70% {
            transform: scale(1);
            box-shadow: 0 0 0 10px rgba(108, 192, 74, 0);
        }
        
        100% {
            transform: scale(0.95);
            box-shadow: 0 0 0 0 rgba(108, 192, 74, 0);
        }
    }
`;

const StaticTooltip = props => {
    // we only use theme for Notation Block because the inner part of component doesn't depend on Page Section theme
    const theme = componentTheme(true, props.sectionTheme, props.theme);
    return (
        <ComponentWrapper>
            <Wrapper>
                <InfoIconContainer>
                    <InlineImage>
                        <img alt={props.alt} src={InfoIcon} />
                    </InlineImage>
                </InfoIconContainer>

                <TextBoxContainer>
                    <Text.Body dangerouslySetInnerHTML={createMarkup(props.text)}/>
                </TextBoxContainer>
            </Wrapper>
            {(props.footnotes || props.definitions) && <NotationBlock footnotes={props.footnotes} definitions={props.definitions} theme={theme} />}
        </ComponentWrapper>
    );
};

export default StaticTooltip;
