import React from 'react';
import { Heading } from '../../util/typography';
import PropTypes from 'prop-types';
import ComponentWrapper from '../layout/ComponentWrapper';
import styled from 'styled-components';
import { createMarkup } from '../../util/functions';


const PrimaryClaimTitle = styled(Heading.H2)`
    text-align: center;
`;


const PrimaryClaim = ({ theme, children }) => {
    return (
        <ComponentWrapper>
            <PrimaryClaimTitle theme={theme} dangerouslySetInnerHTML={createMarkup(children)} />
        </ComponentWrapper>
    );
};

PrimaryClaim.propTypes = {
    /**
     * This is the primary indication; Currently a string, but probably needs a shape matches the more complex use case. Needs to support at least superscript notations.
     */
    children: PropTypes.string,
};

export default PrimaryClaim;
