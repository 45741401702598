import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { caratDownBlueberry } from '../../../util/icons';
import { Text } from '../../../util/typography';
import * as colors from '../../../util/colors';
import Link from 'gatsby-link';

const MenuLabel = styled(Text.Button)`
    line-height: 1.13;
    flex: 0 auto;
    color: inherit;
`;

const StyledMenuLink = styled(Link).withConfig({
    shouldForwardProp: prop => !['hasChildren', 'isOpen', 'inActivePath', 'activePath'].includes(prop),
})`
    padding: 1em 1em 0;
    display: flex;
    flex-flow: column nowrap;
    flex: 1 1 100%;
    justify-content: center;
    text-decoration: none;
    color: ${({ level }) => (level === '1' ? colors.charcoal : colors.blueberry)};
    align-items: center;
    background-color: ${colors.white};
    transition: background-color 0.3s ease-in-out;
    box-sizing: border-box;
    height: 100%;
    z-index: 1;
    border: none;

    &:hover {
        background-color: ${colors.mist};
    }

    ${({ level, hasChildren, isOpen }) =>
        level === '1' &&
        `
        background-color: ${isOpen ? colors.mist : 'none'};
        position: relative;
        
        &::after {
            display: block;
            content: "";
            margin-top: 6px;
            width: 10px;
            height: 20px;
            ${hasChildren ? `background-image: url(${caratDownBlueberry});` : ''}
            background-size: contain;
            background-repeat: no-repeat;
            background-position-y: ${isOpen ? 'calc(100% + 10px)' : '0'};
            background-position-x: center;
            transition: background-position-y .3s ease-in-out;
        }

        &:before {
            content: "";
            position: absolute;
            width: 100%;
            height: 2px;
            background-color: transparent;
            bottom: -2px;
            transition: background-color .3s ease-in-out;
        }
        
        &.active:before {
            background-color: ${colors.keytrudaGreen};
        }
    `}
    ${({ inActivePath }) =>
        inActivePath && `
        &:before {
            background-color: ${colors.keytrudaGreen};
        }
    `}

    ${({ level }) =>
        level === '2' &&
        `
        &.active {
            // background: #000;
        }`}
`;

const MenuLink = props => {
    //workaround for the fact that Gatsby Link doesn't seem to allow you to pass it's active class to a non-link (like our spans)
    let [inActivePath, setInActivePath] = useState(false);

    useEffect(() => {
        // set 'active' indicator
        if (typeof window !== 'undefined' && window.origin !== 'null') { //check to avoid errors in Gatsby build and in DSM's sandboxed iframe
            setInActivePath(window.location.pathname.startsWith(props.activePath));
        }
    },[props.activePath])


    const linkProps = {
        as: props.hasChildren ? 'button' : undefined,
        to: props.url,
        inActivePath: inActivePath,
        'data-design-category': 'top_nav_vd',
        ...props
    };
    
    //avoid errors for adding Link props to non-links (eg the parent spans)
    if (!props.hasChildren) {
        linkProps.activeClassName = 'active';
    }

    return (
        <StyledMenuLink {...linkProps}>
            <MenuLabel dangerouslySetInnerHTML={{ __html: props.label }} />
        </StyledMenuLink>
    );
};
export default MenuLink;
