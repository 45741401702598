import React, { useRef, useLayoutEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setUsableSpace } from '../../store/actions';

function generateUsableSpaceDebugStyles(usableSpaceObject) {
    const { top, left, right, bottom } = usableSpaceObject;

    const debugStyles = `
        body { color: purple; }

        body::after {
            content: '';
            position: fixed;
            z-index: 9999999;
            background: rgba(255,0,0,0.3);
            top: ${top}px;
            left: ${left}px;
            bottom: ${bottom}px;
            right: ${right}px;
            pointer-events: none;
        }
    `;

    return debugStyles;
}

const UsableSpace = ({
    utility_nav_ref,
    main_nav_ref,
    launch_button_container_ref,
    showUsableSpace = false,
}) => {
    const scrolledPast = useSelector(state => state.scrolledPastInPageSSI);
    const reduxUsableSpace = useSelector(state => state.usableSpace);
    const dispatch = useDispatch();

    // Log renders
    const renderCount = useRef(0);
    // console.log(
    //     '👋 USABLE SPACE REPORTING! ================================ ',
    //     renderCount.current
    // );
    renderCount.current++;

    const utility_nav = utility_nav_ref?.current;
    const main_nav = main_nav_ref?.current;
    const launch_button_container = launch_button_container_ref?.current;

    useLayoutEffect(() => {

        function computeUsableSpace() {
            if (!utility_nav || !main_nav || !launch_button_container) {
                if (process.env.NODE_ENV === 'development' && renderCount.current > 1) {
                    // All refs are null on the first render, so only log if renderCount > 1
                    console.group('Chatbot UsableSpace');
                    console.log('Not enough node refs to calculate usable space:');
                    console.log({ utility_nav: utility_nav, main_nav: main_nav, launch_button_container: launch_button_container });
                    console.groupEnd();
                }

                // Short circuit, but return a no-op since `useLayoutEffect` requires it
                return function () {};
            }

            if (typeof window === 'undefined') {
                // Short circuit, but return a no-op since `useLayoutEffect` requires it
                return function () {};
            }

            // Get window vals
            // @link https://stackoverflow.com/a/8876069
            const window_height = Math.max(
                document.documentElement.clientHeight,
                window.innerHeight || 0
            );

            const window_width = Math.min(
                document.documentElement.clientWidth,
                window.innerWidth || 0
            );

            // Chatbot and other constants
            // const botWidth = 400;
            // const botHeight = 510;
            const scrollBar = 15;
            const offsetFromBottom = 0;
            const launchButtonContainerRectPadX = 20; // make this dynamic

            // Measure the rects
            let utility_nav_rect = utility_nav.getBoundingClientRect();
            let main_nav_rect = main_nav.getBoundingClientRect();
            let launch_button_container_rect = launch_button_container.getBoundingClientRect();

            // Unusables
            const unusableTop = Math.max(
                utility_nav_rect.bottom,
                main_nav_rect.bottom
            );

            let unusableBottom = scrolledPast ? 0 : launch_button_container_rect.height;

            // Compute
            const computedHeight = window_height - unusableTop - unusableBottom - offsetFromBottom;
            const computedWidth =
                window_width > 768
                    ? launch_button_container_rect.width - 2 * launchButtonContainerRectPadX
                    : window_width;
            const computedTop = unusableTop;
            const computedRight =
                window_width > 768
                    ? window_width -
                      launch_button_container_rect.right +
                      launchButtonContainerRectPadX +
                      launchButtonContainerRectPadX
                    : 0;
            const computedBottom = unusableBottom + offsetFromBottom;
            const computedLeft =
                window_width > 768
                    ? launch_button_container_rect.left + launchButtonContainerRectPadX
                    : 0;

            // Logs
            // console.group('🧮 usable space calcs');

            // console.log('======  Window ====== ');
            // console.log('window_height', window_height);
            // console.log('window_width', window_width);

            // console.log('======  Rectangles ====== ');
            // console.log('utility_nav_rect', utility_nav_rect);
            // console.log('main_nav_rect', main_nav_rect);
            // console.log('launch_button_container_rect', launch_button_container_rect);

            // console.log('======  Unusables ====== ');
            // console.log('unusableTop', unusableTop);
            // console.log('unusableBottom', unusableBottom);

            // console.log('======  Computed ====== ');
            // console.log('computedHeight', computedHeight);
            // console.log('computedTop', computedTop);
            // console.log('computedRight', computedRight);
            // console.log('computedBottom', computedBottom);
            // console.log('computedLeft', computedLeft);

            // console.groupEnd();

            const computedUsableSpace = {
                // Set corners
                top: computedTop,
                right: computedRight,
                bottom: computedBottom,
                left: computedLeft,
                // Set size
                height: computedHeight,
                width: computedWidth,
            };

            // Set in redux
            // console.log('🤖 Space Set in Redux');
            dispatch(setUsableSpace(computedUsableSpace));
        }

        computeUsableSpace();
        window.addEventListener('resize', computeUsableSpace);
        // window.addEventListener('scroll', computeUsableSpace);

        return () => window.removeEventListener('resize', computeUsableSpace);
    }, [
        utility_nav_ref,
        main_nav_ref,
        launch_button_container_ref,
        utility_nav,
        main_nav,
        launch_button_container,
        scrolledPast,
        dispatch,
    ]);

    const debugStyles = generateUsableSpaceDebugStyles(reduxUsableSpace);

    return showUsableSpace ? <style>{debugStyles}</style> : null;
};

export default UsableSpace;
