import React, {useState, useEffect, useRef} from 'react';
import styled from 'styled-components';
import * as CTA from '../../util/CTA';
import * as colors from '../../util/colors';
import { Heading } from '../../util/typography';
import { createLocalScaleFunction } from '../../util/functions';
import Card from './Card';
import ComponentWrapper from '../layout/ComponentWrapper';
import { NAVIGATION_BREAKPOINT } from '../../constants/layout';

const Title = styled(Heading.H3)`
  text-align: center;
  font-weight: 300;
  margin-bottom: 30px;
`

const CardsAndShadow = styled.div`
  position: relative;
`

const CardsWrapper = styled.div`
    display: flex;
    flex-direction: row;
    gap: 20px;
    overflow: hidden;
    padding: 10px;
    scroll-behavior: smooth;
    -ms-overflow-style: none;  /* Internet Explorer 10+ */
    scrollbar-width: none;  /* Firefox */
`

const CardWrapper = styled.div`
    display: flex;
    width: 240px;
    @media (min-width: ${NAVIGATION_BREAKPOINT}) {
      width: 280px;
  }
`

// might want to have 1 source for this 🤷🏻‍♀️
const Dot = styled.div`
    height: ${({dist}) => dist < 3 ? '6px' : dist == 3 ? '4px' : dist >= 4  ? '2px' : '6px'};
    width: ${({dist}) => dist < 3 ? '6px' : dist == 3 ? '4px' : dist >= 4  ? '2px' : '6px'};
    background-color: ${colors.pebble};
    border-radius: 50%;

    &.active {
      height: 8px;
      width: 8px;
      background-color:  ${colors.blueberry};
      box-shadow: 0px 0px 9px 1px ${colors.blueberry};
    }
`

const TempButtonContainerD = styled.div`
    display: block;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 56px;
    margin-top: ${createLocalScaleFunction(20, 320, 30, 992)};
`

const LeftButton = styled(CTA.SliderButton)`
    transform: rotate(180deg);
    &::after {
      margin-left: 2px; //visually centers left-carret (per design direction)
    }
`

const PaginationWrapper = styled.div`
    display: grid;
    gap: 8px;
    grid-auto-flow: column;
    margin: 0 20px;
    place-items: center;
    @media (min-width: ${NAVIGATION_BREAKPOINT}) {
      gap: 16px;
    }
`

const RightShadow = styled.div`
    position: absolute;
    right: 0;
    width: ${createLocalScaleFunction(50, 320, 112, 992)};
    top: 10px;
    height: calc(100% - 20px);
    background: ${({shadowColor}) => (shadowColor === 'cloud') ? `linear-gradient(to left, #f3f3f3 4.32%, rgba(255, 255, 255, 0.00) 96.04%)` : `linear-gradient(to left, #FFF 4.32%, rgba(255, 255, 255, 0.00) 96.04%)`};
`;

const LeftShadow = styled(RightShadow)`
  left: 0;
  background: ${({shadowColor}) => (shadowColor === 'cloud') ? `linear-gradient(to left, #f3f3f3 4.32%, rgba(255, 255, 255, 0.00) 96.04%)` : `linear-gradient(to left, #FFF 4.32%, rgba(255, 255, 255, 0.00) 96.04%)`};
`;

const ResourceSlider = ({title, cards, shadowColor}) => {

  const [currentIndex, setCurrentIndex] = useState(0);
  const [activeId, setActiveId] = useState({ activeSlide: 0 })
  const [prevDisabled, setPrevDisabled] = useState(true)
  const [nextDisabled, setnextDisabled] = useState(false)
  const { activeSlide } = activeId
  const cardWrapperRef = useRef(null);
  const cardRef = useRef(null);
  const [cardsInView, setCardsInView] = useState(0);
  const [dots, setDots] = useState(null);
  const [whiteShadow, setWhiteShadow] = useState(false);

  
  const calcCardsInView = () => {
    setCardsInView(Math.floor(cardWrapperRef.current.clientWidth / cardRef.current.clientWidth));
  }

  useEffect(() => {
    calcCardsInView();
  }, [cardWrapperRef]);

  useEffect(() => {
    const handleResize = () => {
      calcCardsInView();
    };
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(() => {
    if(cardsInView > 0){
    setWhiteShadow(cards.length  > cardsInView);
    setDots([...Array(Math.ceil(cards.length / cardsInView)).keys()]);
    }
  }, [cardsInView]);

  useEffect(() => {
    if(dots !== null){
       // check if current index is 0 then reset to proper arrow states
      if (currentIndex == 0) {
        setPrevDisabled(true)
        setnextDisabled(false)
      } else {
        setPrevDisabled(false)
      }

      // if currentIndex is the last slide, disable next arrow
      if (currentIndex / cardsInView == (dots.length - 1)) {
        setnextDisabled(true)
      }
    }
  }, [dots, currentIndex, cardsInView]);

  const goToPrevious = () => {
    const isFirstSlide = currentIndex === 0;
    const newIndex = isFirstSlide ? cards.length - 1 : currentIndex - cardsInView;
    setCurrentIndex(newIndex);

    setActiveId({
      activeSlide: activeSlide === 0 ? cards.length - 1 : activeSlide - cardsInView
    })

    if(!isFirstSlide) {
      cardWrapperRef.current.scrollLeft -= ( (cardRef.current.clientWidth + 20) * cardsInView); //20 is the gap of flexbox
    }
  };

  const goToNext = () => {
    const isLastSlide = currentIndex === cards.length - 1;
    const newIndex = isLastSlide ? 0 : currentIndex + cardsInView;
    setCurrentIndex(newIndex);

    setActiveId({
      activeSlide: activeSlide === cards.length - 1 ? 0 : activeSlide + cardsInView
    })

    if(!isLastSlide) {
      cardWrapperRef.current.scrollLeft += ( (cardRef.current.clientWidth + 20) * cardsInView); //20 is the gap of flexbox
    }
    setWhiteShadow(cards.length  > cardsInView);
  };

  return (
    <ComponentWrapper>
      <Title>{title}</Title>
      <CardsAndShadow>
        <CardsWrapper ref={cardWrapperRef}>
          {cards.map((card, i) => {
            return (
              <CardWrapper ref={cardRef}>
                <Card {...card} id={i} />
              </CardWrapper>
            );
          })}
        </CardsWrapper>
        {whiteShadow && !prevDisabled && <LeftShadow shadowColor={shadowColor}/>}
        {whiteShadow && !nextDisabled && <RightShadow shadowColor={shadowColor} />}
      </CardsAndShadow>

      {dots && dots.length > 1 && <TempButtonContainerD>
        <LeftButton onClick={goToPrevious} disabled={prevDisabled}/>
        <PaginationWrapper>
          {dots && dots.map((dot, i) => (
            <Dot
              key={i}
              dist = {Math.abs(activeSlide / cardsInView - i)}
              className={activeSlide / cardsInView === dot ? 'active' : ''} 
            />
          ))}
        </PaginationWrapper>

        <CTA.SliderButton onClick={goToNext} disabled={nextDisabled}/>
      </TempButtonContainerD>}

    </ComponentWrapper>
  );
};

export default ResourceSlider;
