import React from 'react';
import styled from 'styled-components';
import Headroom from 'react-headroom';
import { MainNavigation, UtilityNavigation } from '../components';
import { createOverlay } from '../components/Overlay';
import {
    NAV_CONTAINER,
    NAV_CONTAINER_MOBILE_MENU_OPEN,
    DESKTOP_NAV_OVERLAY,
    MOBILE_NAV_OVERLAY,
} from '../constants/z-index-map';
import { useClientRect } from '../util/hooks';
import { useDispatch, useSelector } from 'react-redux';
import { indicateMainNavIsPinned, indicateMainNavIsNotPinned } from '../store/actions';
import { NAVIGATION_BREAKPOINT } from '../constants/layout';

const NavContainerLayout = styled.div`
    position: sticky;
    top: 0;
    left: 0;
    right: 0;
    pointer-events: none;
    z-index: ${({ mobileMenuOpen }) =>
        mobileMenuOpen ? NAV_CONTAINER_MOBILE_MENU_OPEN : NAV_CONTAINER};

    @media (min-width: ${NAVIGATION_BREAKPOINT}) {
        margin-bottom: ${props => props.filter_banner_rect?.height ?? 0}px;
    }
`;

/**
 * Create two overlays with the same key, but only render one at a time due to media queries.
 * We do this so a "single" overlay (keyed by `NAV_OVERLAY_KEY`) can render is multiple
 * spots / stacking contexts. Otherwise we run into DOM structure problems.
 */
export const NAV_OVERLAY_KEY = 'NAV_OVERLAY';
export const DesktopNavOverlay = createOverlay(NAV_OVERLAY_KEY, {
    extendsOverlay: StyledOverlay =>
        styled(StyledOverlay)`
            /**
             * @todo Bad name, but now this applies to both desktop and mobile. Need to clean these up.
             */
            display: none;
            z-index: ${DESKTOP_NAV_OVERLAY};

            /* Never render on Mobile */
            @media (min-width: ${NAVIGATION_BREAKPOINT}) {
                /* @todo Use real values for the media query */
                display: ${({ isActive }) => (isActive ? 'block' : 'none')};
            }
        `,
});
export const DesktopNavInnerFilterOverlay = createOverlay(NAV_OVERLAY_KEY, {
    extendsOverlay: StyledOverlay =>
        styled(StyledOverlay)`
            /* Never render on Mobile */
            display: none;
            z-index: ${DESKTOP_NAV_OVERLAY};

            /* Only cover CustomizeFilterOutOfDocumentFlow */
            position: absolute;

            /* Only (possibly) render on Desktop */
            @media (min-width: ${NAVIGATION_BREAKPOINT}) {
                /* @todo Use real values for the media query */
                display: ${({ isActive }) => (isActive ? 'block' : 'none')};
            }
        `,
});
export const MobileNavOverlay = createOverlay(NAV_OVERLAY_KEY, {
    extendsOverlay: StyledOverlay =>
        styled(StyledOverlay)`
            display: ${({ isActive }) => (isActive ? 'block' : 'none')};
            z-index: ${MOBILE_NAV_OVERLAY};

            /* Never render on Desktop */
            @media (min-width: ${NAVIGATION_BREAKPOINT}) {
                /* @todo Use real values for the media query */
                display: none;
            }
        `,
});

const NavContainer = ({
    launch_button_container_ref,
    main_nav_ref,
    onMainNavRef,
    utility_nav_ref,
    filter_wrapper_ref,
    general_modal_ref,
}) => {
    const [filter_banner_rect, filter_banner_ref] = useClientRect();
    const dispatch = useDispatch();
    const mobileMenuOpen = useSelector(state => state.mainNavigation.mobileMenuOpen);
    const scrollLock = useSelector(state => state.scrollLock);

    return (
        <>
            <NavContainerLayout
                filter_banner_rect={filter_banner_rect}
                mobileMenuOpen={mobileMenuOpen}
            >
                <UtilityNavigation utility_nav_ref={utility_nav_ref} />
                <Headroom
                    disableInlineStyles={true}
                    onPin={() => dispatch(indicateMainNavIsPinned())}
                    onUnpin={() => dispatch(indicateMainNavIsNotPinned())}
                    disable={scrollLock}
                    pinStart={130}
                >
                    <MainNavigation
                        launch_button_container_ref={launch_button_container_ref}
                        main_nav_ref={main_nav_ref}
                        onMainNavRef={onMainNavRef}
                        filter_wrapper_ref={filter_wrapper_ref}
                        general_modal_ref={general_modal_ref}
                    />
                </Headroom>
            </NavContainerLayout>

            {/* {/* <DesktopNavOverlay /> */}
        </>
    );
};

export default NavContainer;
