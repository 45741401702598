import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { WYSIWYG } from '../../util/typography';

const ItemBody = styled.div`
    width: calc(70% - (2 * var(--childPadding)));
    flex-grow: 2;
    padding: 0 var(--childPadding);
`;

// these may belong in the root wysiwyg
const ContentWysiwyg = styled(WYSIWYG)`
    .h4,
    h4 {
        margin-bottom: 24px;
        color: inherit;
    }

    .h6,
    h6 {
        margin-bottom: 16px;
        color: inherit;
    }

    &,
    p,
    .body {
        display: block;
        margin-bottom: 10px;
        color: var(--secondaryText);
    }

    li {
        margin-bottom: 12px;
        color: var(--secondaryText);
    }
`;

// How is the content coming in?
function createMarkup(x) {
    return { __html: x };
}

const IconListItemContentColumn = ({ children }) => {
    return (
        <ItemBody>
            {children && <ContentWysiwyg dangerouslySetInnerHTML={createMarkup(children)} />}
            {/* Alternate
            {children && <WYSIWYG>{children}</WYSIWYG>}
            */}
        </ItemBody>
    );
};

IconListItemContentColumn.prototypes = {
    children: PropTypes.node,
    title: PropTypes.string,
    subTitle: PropTypes.string,
};

export default IconListItemContentColumn;
