import React from 'react';
import styled from 'styled-components';
import ComponentWrapper from '../layout/ComponentWrapper';
import { NotationBlock, Heading, Text } from '../../util/typography';
import { createLocalScaleFunction } from '../../util/functions';
import AccordionGroup from '../layout/AccordionGroup';
import * as colors from '../../util/colors';
import ReactPlayerLoader from '@brightcove/react-player-loader';

const VideoContainer = styled.div`
    display: block;

    .video-js {
        border-radius: 8px;
        min-width: 280px;
        width: auto;
        min-height: 152px;
        height:  auto;

        @media(min-width: 768px) {
            width: 100%;
            height: 540px;
        }
    }

    margin-top: ${createLocalScaleFunction(20, 320, 30, 952)};
    margin-bottom: ${createLocalScaleFunction(20, 320, 30, 952)};
    border-radius: 8px;
    border: ${({ videoUnavailable }) => (videoUnavailable ? '0' : `solid 1px ${colors.pebble}`)};
`;

const VideoUnavailable = styled.div`
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    aspect-ratio: 16 / 9;
    background: #262626;
    color: white;
    border-radius: 8px;
    padding:0 20px;
    h4 {
        font-size: 24px;
        font-style: normal;
        font-weight: 400;
        line-height: 32px; 
        text-align:center;
    }
    @media (max-width:575px){
        h4 {
            font-size: 20px;
            font-style: normal;
            font-weight: 400;
            line-height: 26px;
            text-align:center;
        }
    }
`;

const Transcript = styled.div`
    .underlined {
        text-decoration: underline;
    }
`

const VideoTitle = styled(Heading.H4)`
    margin-top: ${createLocalScaleFunction(20, 320, 30, 952)};
    margin-bottom: 10px;
`

const VideoDescription = styled(Text.Body)`
    margin-top: 10px;
`

const Summary = styled.div``

const SummaryTitle = styled(Heading.H4)`
    margin-bottom: 10px;
`
{/* 
    Props:
      - title
      - description
      - videoId (provided by Brightcove)
      - transcript:
        - title
        - children
      - footnotes
      - definitions
      - summary
*/}

const Video = props => {

    return (
        <ComponentWrapper>
            <VideoTitle theme={props.theme} dangerouslySetInnerHTML={{ __html: props.title }}></VideoTitle>
            <VideoDescription theme={props.theme} dangerouslySetInnerHTML={{ __html: props.description }}></VideoDescription>
                <VideoContainer videoUnavailable={props.videoUnavailable}>
                    {!props.videoUnavailable && (
                        <ReactPlayerLoader 
                                accountId='1905768940001'
                                videoId={props.videoId}
                                playerId='meAxH3mZK'
                        />
                    )}
                    {props.videoUnavailable && (
                        // <picture>
                        //   <source srcset="/static/images/Video-Unavailable-320.svg" media="(max-width: 493px)" />
                        //   <source srcset="/static/images/Video-Unavailable-740.svg" media="(max-width: 783px)" />
                        //   <img src="/static/images/Video-Unavailable.svg" alt="This video is currently unavailable" style={{width:'100%', height:'100%',display:'block'}} />
                        // </picture>
                        <VideoUnavailable>
                                <h4>
                                   This video is currently unavailable 
                                </h4>
                        </VideoUnavailable>
                    )}
                </VideoContainer>
            <Transcript>{(props.transcriptAccordionData) && <AccordionGroup items={props.transcriptAccordionData} transcript />}</Transcript>
            {(props.footnotes || props.definitions) && <NotationBlock footnotes={props.footnotes} definitions={props.definitions} theme={props.theme}/>}
        
            {(props.summary) && 
                <Summary>
                    <SummaryTitle>Summary</SummaryTitle>
                    <Text.Body>{props.summary}</Text.Body>
                </Summary>}

        </ComponentWrapper>
    );
};

export default Video;
