import React from 'react';
import styled from 'styled-components';
import * as colors from '../../util/colors';
import { rgba } from 'polished';
import { createMarkup } from '../../util/functions';
import { checkWhite } from '../../util/icons';
import { Text } from '../../util/typography';

const FilterContent = styled.label`
    display: flex;
    flex-direction: row;
    border: 1px solid ${colors.pebble};
    align-items: center;
    background-color: transparent;
    width: 100%;
    cursor: pointer;
    transition: background-color 0.3s ease-in-out;
    box-sizing: border-box;
    margin: -1px 0 0 ;

    &:hover {
        background-color: ${colors.mist};

        & > div:first-child::after {
            opacity: 1;
        }
    }

    &:focus {
        background-color: red;
    }
`;

const CancerTitle = styled(Text.Button)`
    margin: 0;
    color: ${colors.charcoal};
    font-size: 16px;
    line-height: 1.25;
`;

const CancerMod = styled(Text.Body)`
    margin: 0;
    color: ${colors.charcoal};
    font-weight: 300;
    line-height: 1;
`;

const CheckBox = styled.span``;

const CheckedContainer = styled.div`
    position: relative;

    &::after,
    input ~ ${CheckBox}::before {
        content: '';
        display: block;
        width: 36px;
        height: 36px;
        background-color: ${colors.silver};
        border-radius: 50%;
        position: absolute;
        z-index: 0;
        top: 50%;
        left: 50%;
        transform: translateX(-50%) translateY(-50%);
        opacity: 0;
        transition: opacity 0.3s ease-in-out;
    }

    input {
        position: absolute;
        left: 50%;
        right: 50%;
        width: 1px;
        height: 1px;
        overflow: hidden;
        z-index: 0;
        clip: rect(1px, 1px, 1px, 1px);
    }

    ${CheckBox} span {
        display: block;
        margin: 14px;
        height: 18px;
        width: 18px;
        border: 1px solid ${colors.blueberry};
        background-color: ${colors.white};
        border-radius: 4px;
        z-index: 1;
        position: relative;
    }

    input:checked ~ ${CheckBox} span {
        background-color: ${colors.blueberry};
        background-image: url(${checkWhite});
        background-size: 12px 10px;
        background-position: center;
        background-repeat: no-repeat;
    }

    input ~ ${CheckBox}::before {
        z-index: 0;
    }

    input:focus ~ ${CheckBox} span,
    input:focus-visible ~ ${CheckBox} span {
        outline: 1px solid ${colors.blueberry};
    }

    input:focus ~ ${CheckBox}::before,
    input:focus-visible ~ ${CheckBox}::before {
        opacity: 1;
    }
`;

const FilterContentInner = styled.div`
    margin: 5px 0;
`;

const FilterCard = ({ data, onChecked, checkedList }) => {
    const isChecked = checkedList.includes(data.id);

    return (
        <FilterContent htmlFor={`cancerType-` + data.id}>
            <CheckedContainer>
                <input
                    checked={checkedList.indexOf(data.id) > -1 ? true : false}
                    id={`cancerType-` + data.id}
                    className="chx"
                    type="checkbox"
                    name={data.title}
                    value={data.id}
                    readOnly
                    onClick={() => onChecked({ target: { checked: !isChecked, value: data.id } })}
                    // onChange={onChecked}nn
                />
                <CheckBox><span /></CheckBox>{/* visual 'checkbox' element */}
            </CheckedContainer>
            <FilterContentInner>
                <CancerTitle dangerouslySetInnerHTML={createMarkup(data.name)} />
                <br />
                <CancerMod>{data.modifier}</CancerMod>
            </FilterContentInner>
        </FilterContent>
    );
};

export default FilterCard;
