import React, { useEffect, useState } from 'react';
import { getScript, observeStore } from '../../util/functions';
import axios from 'axios';
import { CHATBOT_GEOLOCATION_CACHE_KEY } from '../../store/storage-keys';
import { useShallowEqualSelector } from '../../util/hooks';
import useInterval from '../../util/use-interval';
import { createSelector } from '@reduxjs/toolkit';
import { useDispatch, useSelector } from 'react-redux';
import { enableChatbot, openChatbot, closeChatbot, preventScrolling, allowScrolling } from '../../store/actions';
import generateChatBotStyles from './generateChatBotStyles';


export const CHATBOT_ENABLED_COUNTRIES_LIST = ['us'];
export const CHATBOT_ENABLED_COUNTRIES_LIST_LOOKUP = CHATBOT_ENABLED_COUNTRIES_LIST.reduce(
    (obj, country) => {
        obj[country] = true;
        return obj;
    },
    {}
);

const NECESSARY_DOM_SELECTOR = '#nit-alme-window-root';
// Check for presence of DOM element every fifth of a second. Stop checking after a minute.
const CHECK_INTERVAL = 200;
const CANCEL_CHECKING_TIMEOUT = 60 * 1000;

const ChatbotObserver = ({ inline_indications_ref }) => {
    const scrolledPastInPageSSI = useSelector(state => state.scrolledPastInPageSSI);

    const [chatbotInDOM, setChatbotInDOM] = useState(false); // flag for whether the Verint code run and inserted the DOM element
    const [searchForChatbotInDOM, setSearchForChatbotInDOM] = useState(true); //flag for whether to keep searching for chatbot DOM element
    const [countryIsValid, setCountryIsValid] = useState(false);
    const [chatWindowStoreObserverCreated, setChatWindowStoreObserverCreated] = useState(false);

    const chatbotEnabled = useSelector(state => state.chatbotEnabled);
    const chatbotOpen = useSelector(state => state.chatbotOpen);

	const usableSpace = useSelector(state => state.usableSpace);
    const safetyDrawerOpen = useSelector(state => state.safetyDrawerOpen);
    const indicationDrawerOpen = useSelector(state => state.indicationDrawerOpen);
    const hcpAcknowledgementOpen = useSelector(state => state.hcpAcknowledgementOpen);
    const { openItem, mobileMenuOpen } = useShallowEqualSelector(state => state.mainNavigation);
    const filterOpen = useSelector(state => state.filterOpen);
    const reduxUsableSpace = useSelector(state => state.usableSpace);
    const dispatch = useDispatch();

    useEffect(() => {
        if (typeof window !== 'undefined' && window.origin !== 'null') {
            //check to avoid errors in Gatsby build and in DSM's sandboxed iframe
            // Checks local storage for Geolocation key
            const cached_geolocation_json_str = window.localStorage.getItem(
                CHATBOT_GEOLOCATION_CACHE_KEY
            );
            if (cached_geolocation_json_str) {
                // `cached_geolocation_json_str` should be JSON / an Array. Try to parse it, but if it fails, use the value directly.
                let cached_geolocation_json = cached_geolocation_json_str;
                try {
                    cached_geolocation_json = JSON.parse(cached_geolocation_json_str);
                } catch (e) {
                    if (
                        process.env.NODE_ENV === 'development' ||
                        !process.env.GATSBY_PRODUCTION_BUILD
                    ) {
                        console.warn(`Unable to parse ${CHATBOT_GEOLOCATION_CACHE_KEY} to JSON.`);
                        console.warn(e);
                    }
                }

                enableChatbotIfInUS(cached_geolocation_json);
            } else {
                // Loads Geolocation API lib
                loadGeolocationApi();
            }
        }

        // Create timer to check for presence of Chatbot Window. Stop checking after a minute
        setTimeout(() => {
            if (!chatbotInDOM) {
                console.log(
                    `Chatbot initializer timed out. Stopping check for "${NECESSARY_DOM_SELECTOR}"`
                );
            }
            // clearInterval(check_interval_id);
            setSearchForChatbotInDOM(false);
        }, CANCEL_CHECKING_TIMEOUT);
    }, [chatbotInDOM]);

    //wait until BOTH the Verint code has loaded AND the Google Maps API has validated the location AND the usable space function has run once
    useEffect(() => {
        if (chatbotInDOM && countryIsValid && usableSpace) {
            dispatch(enableChatbot());
        }
    }, [chatbotInDOM, countryIsValid, usableSpace, dispatch]);

    useEffect(() => {
        if (chatbotEnabled && window.almeWebClient && !chatWindowStoreObserverCreated) {
            createChatWindowObserver();
        }
    }, [chatbotEnabled, chatWindowStoreObserverCreated]);

    useEffect(() => {
        if (
            chatbotOpen &&
            (safetyDrawerOpen || indicationDrawerOpen || openItem || mobileMenuOpen || filterOpen)
        ) {
            minimizeChatbot();
        }
    }, [safetyDrawerOpen, indicationDrawerOpen, chatbotOpen, openItem, mobileMenuOpen, filterOpen]);

    useEffect(() => {
        if (chatbotOpen && scrolledPastInPageSSI) {
            minimizeChatbot();
        }
    }, [scrolledPastInPageSSI, chatbotOpen]);

    useEffect(() => {
        if (window.almeWebClient) {
            window.almeWebClient._store.dispatch({
                type: 'SET_CHAT_WINDOW_HEIGHT',
                height: reduxUsableSpace.height
            });
        }
    }, [reduxUsableSpace])

    /**
     * Since the chatbot loads asynchronously, and since they aren't
     * emitting some kind of custom event, we can only detect that the
     * chatbot is ready to be `enabled()` by querying the DOM over and over.
     *
     * This function will repeatedly do just that
     * If it doesn't find the necessary DOM element after a while, it stops checking.
     *
     * uses https://overreacted.io/making-setinterval-declarative-with-react-hooks/
     */
    useInterval(
        () => {
            let necessary_dom_element = document.querySelector(NECESSARY_DOM_SELECTOR);
            if (necessary_dom_element) {
                // Clear our interval AND cancellation timeout before enabling
                setChatbotInDOM(true);
                setSearchForChatbotInDOM(false);
            }
        },
        !chatbotInDOM && searchForChatbotInDOM ? CHECK_INTERVAL : null
    );

    /**
     * Google Geolocation (maybe?) has a bad polyfill for `Symbol.iterator`,
     * and so a fix is to load that JS file _after_ our `core-js` polyfills
     * have been loaded.
     *
     * Since OneTrust doesn't guarantee execution order of JS files anymore,
     * rather than putting this script tag inline, I need to inject it manually.
     */
    const loadGeolocationApi = () => {
        getScript(
            'https://maps.googleapis.com/maps/api/js?v=3.exp&key=AIzaSyA2GJ4ZU9dkx_tmLmWAwCeRwVjvYQvgWZ0',
            () => {
                // Geolocation library has loaded, now perform the geolocation lookup
                checkLocation();
            }
        );
    };

    /**
     * @param {Array<String>} countries_array - An array of country codes. If one is present in `CHATBOT_ENABLED_COUNTRIES_LIST`, then we enable the chatbot.
     */
    const enableChatbotIfInUS = countries_array => {
        if (!Array.isArray(countries_array)) {
            countries_array = [countries_array];
        }

        const has_valid_country = countries_array.some(
            country => CHATBOT_ENABLED_COUNTRIES_LIST_LOOKUP[country]
        );
        if (has_valid_country) {
            // We can attempt to enable the chatbot!
            setCountryIsValid(true);
        }
    };

    const checkLocation = () => {
        axios
            .post(
                'https://www.googleapis.com/geolocation/v1/geolocate?key=AIzaSyA2GJ4ZU9dkx_tmLmWAwCeRwVjvYQvgWZ0'
            )
            .then(response => {
                return new Promise((resolve, reject) => {
                    /**
                     * The Geolocation API returns longitude and latitude information.
                     * Once we have this, we need to pass to this to a Geocoder, which
                     * gives us a country associated with those coordinates.
                     */
                    const { lat, lng } = response.data.location;

                    const latlng = new window.google.maps.LatLng(lat, lng);
                    const googleGeocoder = new window.google.maps.Geocoder().geocode(
                        { latLng: latlng },
                        (results, status) => {
                            // @see https://developers.google.com/maps/documentation/javascript/geocoding#GeocodingStatusCodes
                            if (status !== 'OK') {
                                return reject(status);
                            }

                            // @see https://developers.google.com/maps/documentation/places/web-service/supported_types
                            const COUNTRY_TYPE = 'country';

                            /**
                             * @see https://developers.google.com/maps/documentation/geocoding/overview#results
                             * `results` is an array of objects, because reverse geocoding can return
                             * several possible exact addresses.
                             *
                             * So, take all our results and compute the unique number of countries that are present.
                             *
                             * I have to imagine that we _could_ also just check a random single result; that is,
                             * if one result is shown to be in the U.S., then they all are, but there is nothing
                             * wrong with mapping all of these and uniq'ing them and storing that result. It is probably
                             * overkill but matches up with what the Geocoder API returns.
                             */
                            const results_to_country_codes = results.map(result => {
                                const country_component = result.address_components.find(
                                    address_component =>
                                        address_component.types.includes(COUNTRY_TYPE)
                                );
                                return (
                                    country_component &&
                                    country_component.short_name &&
                                    country_component.short_name.toString().toLowerCase()
                                );
                            });
                            const unique_country_codes = Object.keys(
                                results_to_country_codes.reduce((obj, country) => {
                                    obj[country] = true;
                                    return obj;
                                }, {})
                            );

                            // Attempt to save our countries array in local storage for use on subsequent pageviews
                            try {
                                const country_json_str = JSON.stringify(unique_country_codes);
                                window.localStorage.setItem(
                                    CHATBOT_GEOLOCATION_CACHE_KEY,
                                    country_json_str
                                );
                            } catch (err) {
                                if (
                                    process.env.NODE_ENV === 'development' ||
                                    !process.env.GATSBY_PRODUCTION_BUILD
                                ) {
                                    console.warn('Error saving country codes to local storage:');
                                    console.warn(err);
                                }
                            }

                            enableChatbotIfInUS(unique_country_codes);
                            resolve(unique_country_codes);
                        }
                    );
                });
            })
            .catch(error => {
                if (
                    process.env.NODE_ENV === 'development' ||
                    !process.env.GATSBY_PRODUCTION_BUILD
                ) {
                    console.warn('Geolocation failed, but our NODE_ENV is "development"');
                    console.warn('So, enabling the chatbot button anyways.');
                    setCountryIsValid(true);
                }
                console.log('Geolocation error:', error);
            });
    };

    /**
     * As we scroll / resize the window, calculate the "usable space"
     * between the header and the sticky ISI. Verint has requested that
     * we send them the _height_ of the usable space (between the header
     * and sticky) as a unitless Number.
     *
     * In this calculation, if a subnav exists, we add that _back_ to our
     * overall height because we want the chatbot to sit on top of the subnav.
     *
     * As an optimization, only calculate this when the chatbot is open.
     
    const checkChatbotSize = () => {
        if (chatbotOpen) {
            // let space = getUsableSpace();
            // if (space && space.height != null) {
            // 	let { height } = space;
            // 	if (space.header_subnav && space.header_subnav.height) {
            // 		height += space.header_subnav.height;
            // 	}
            // 	window.almeWebClient._store.dispatch({
            // 		type: 'SET_CHAT_WINDOW_HEIGHT',
            // 		height: height,
            // 	});
            // }
        }
    };
    */

    const chatbotStyles = generateChatBotStyles(reduxUsableSpace);

    const createChatWindowObserver = () => {
        setChatWindowStoreObserverCreated(true);
        observeStore(
            onChatbotWindowStateChange,
            createSelector(
                state => state.Window.IsMinimized,
                IsMinimized => ({ IsMinimized })
            ),
            window.almeWebClient._store
        );
    };

    const onChatbotWindowStateChange = (state = {}) => {
        if (state.IsMinimized && !hcpAcknowledgementOpen) {
            dispatch(closeChatbot());
            dispatch(allowScrolling());
        } else if (!state.IsMinimized) {
            dispatch(openChatbot());
            if (window.matchMedia('(max-width: 768px)').matches) { //@todo set up a shared breakpoint
                dispatch(preventScrolling());
            } 
        }
    };

    const minimizeChatbot = () => {
        if (window.almeWebClient) {
            window.almeWebClient._store.dispatch({
                type: 'SET_MINIMIZE_WINDOW',
                payload: { value: true, stateLocation: 'IsMinimized' },
            });
            dispatch(closeChatbot());
        }
    };

    return (
        <>
            <style>{chatbotStyles}</style>
        </>
    );
};

export default ChatbotObserver;
