export const indications = {
    1: {
        id: 1,
        modifier: "Primary Advanced or Recurrent",
        name: "Endometrial Carcinoma",
        // shortName: "",
        statement: "<p>KEYTRUDA, in combination with carboplatin and paclitaxel, followed by KEYTRUDA as a single agent, is indicated for the treatment of adult patients with primary advanced or recurrent endometrial carcinoma.</p> "
    },
    2: {
        id: 2,
        modifier: "Advanced",
        name: "Melanoma",
        shortName: "Advanced Melanoma",
        statement: "<p>KEYTRUDA is indicated for the treatment of patients with unresectable or metastatic melanoma.</p>"
    },
    3: {
        id: 3,
        modifier: "Adjuvant Therapy for",
        name: "Stage IIB, Stage IIC, or Stage III Melanoma",
        shortName: "Adjuvant Therapy for Stage IIB, Stage IIC, or Stage III Melanoma",
        statement: "<p>KEYTRUDA is indicated for the adjuvant treatment of adult and pediatric (12 years and older) patients with stage IIB, IIC, or III melanoma following complete resection.</p>"
    },
    4: {
        id: 4,
        name: "First-line Combination Therapy in Nonsquamous mNSCLC",
        dosingName: "First-line Combination Therapy in Nonsquamous Metastatic NSCLC (mNSCLC)",
        shortName: "1L Combo Therapy – Nonsquamous",
        statement: "<p>KEYTRUDA, in combination with pemetrexed and platinum chemotherapy, is indicated for the first-line treatment of patients with metastatic nonsquamous non&#8288;&#8211;&#8288;small cell lung cancer (NSCLC), with no epidermal growth factor receptor (EGFR) or anaplastic lymphoma kinase (ALK) genomic tumor aberrations.</p>"
    },
    5: {
        id: 5,
        name: "First-line Combination Therapy in Squamous mNSCLC",
        shortName: "1L Combo Therapy – Squamous",
        statement: "<p>KEYTRUDA, in combination with carboplatin and either paclitaxel or paclitaxel protein‑bound, is indicated for the first‑line treatment of patients with metastatic squamous non&#8288;&#8211;&#8288;small cell lung cancer (NSCLC).</p>"
    },
    6: {
        id: 6,
        name: "First-line Monotherapy for Nonsquamous and Squamous Advanced NSCLC",
        dosingName: "First-line Monotherapy for Nonsquamous or Squamous Advanced NSCLC",
        shortName: "1L Monotherapy – Nonsquamous & Squamous",
        statement: `<p>KEYTRUDA, as a single agent, is indicated for the first-line treatment of patients with non&#8288;&#8211;&#8288;small cell lung cancer (NSCLC) expressing programmed death ligand 1 (PD&#8288;-&#8288;L1) [tumor proportion score (TPS) ≥1%] as determined by an FDA-approved test, with no epidermal growth factor receptor (EGFR) or anaplastic lymphoma kinase (ALK) genomic tumor aberrations, and is:</p>
                <ul>
                    <li>stage III where patients are not candidates for surgical resection or definitive chemoradiation, or</li> 
                    <li>metastatic.</li>
                </ul>
        `
    },
    7: {
        id: 7,
        name: "Second-line or Greater Monotherapy for Nonsquamous and Squamous mNSCLC",
        dosingName: "Second-line or Greater Monotherapy for Nonsquamous or Squamous mNSCLC",
        shortName: "2L Monotherapy – Nonsquamous & Squamous",
        statement: "<p>KEYTRUDA, as a single agent, is indicated for the treatment of patients with metastatic non&#8288;&#8211;&#8288;small cell lung cancer (NSCLC) whose tumors express programmed death ligand 1 (PD&#8288;-&#8288;L1) [tumor proportion score (TPS) ≥1%] as determined by an FDA-approved test, with disease progression on or after platinum-containing chemotherapy. Patients with epidermal growth factor receptor (EGFR) or anaplastic lymphoma kinase (ALK) genomic tumor aberrations should have disease progression on FDA-approved therapy for these aberrations prior to receiving KEYTRUDA.</p>"
    },
    8: {
        id: 8,
        modifier: "Advanced",
        name: "Renal Cell Carcinoma",
        shortName: "Advanced RCC",
        statement: "<p>KEYTRUDA, in combination with axitinib, is indicated for the first-line treatment of adult patients with advanced renal cell carcinoma (RCC).</p>"
    },
    9: {
        id: 9,

        name: "First-line Combination Therapy in Metastatic or Unresectable, Recurrent HNSCC",
        shortName: "1L Combo Therapy – HNSCC",
        statement: "<p>KEYTRUDA, in combination with platinum and fluorouracil (FU), is indicated for the first-line treatment of patients with metastatic or with unresectable, recurrent head and neck squamous cell carcinoma (HNSCC).</p>"
    },
    10: {
        id: 10,
        name: "First-line Monotherapy in Metastatic or Unresectable, Recurrent HNSCC",
        shortName: "1L Monotherapy – HNSCC",
        statement: "<p>KEYTRUDA, as a single agent, is indicated for the first-line treatment of patients with metastatic or with unresectable, recurrent head and neck squamous cell carcinoma (HNSCC) whose tumors express programmed death ligand 1 (PD&#8288;-&#8288;L1) [combined positive score (CPS) ≥1] as determined by an FDA-approved test.</p>"
    },
    11: {
        id: 11,
        name: "Subsequent-line Monotherapy in Recurrent or Metastatic HNSCC",
        shortName: "2L Monotherapy – HNSCC",
        statement: "<p>KEYTRUDA, as a single agent, is indicated for the treatment of patients with recurrent or metastatic head and neck squamous cell carcinoma (HNSCC) with disease progression on or after platinum-containing chemotherapy.</p>"
    },
    // 12: {
    //     id: 12,
    //     modifier: "Metastatic",
    //     name: "Third-line or Greater Therapy for Metastatic Small Cell Lung Cancer",
    //     shortName: "3L+ Therapy in Metastatic SCLC",
    //     statement: "<p>KEYTRUDA is indicated for the treatment of patients with metastatic small cell lung cancer (SCLC) with disease progression on or after platinum-based chemotherapy and at least 1 other prior line of therapy. This indication is approved under accelerated approval based on tumor response rate and durability of response. Continued approval for this indication may be contingent upon verification and description of clinical benefit in confirmatory trials.</p>"
    // },
    13: {
        id: 13,
        modifier: "Advanced",
        name: "cSCC",
        shortName: "Advanced cSCC",
        statement: `<p>KEYTRUDA is indicated for the treatment of patients with recurrent or metastatic cutaneous squamous cell carcinoma (cSCC) or locally advanced cSCC that is not curable by surgery or radiation.</p>`,
        definitions: "Advanced cSCC = recurrent or metastatic cSCC or locally advanced cSCC not curable by surgery or radiation."
    },
    14: {
        id: 14,
        modifier: "Advanced",
        name: "Merkel Cell Carcinoma",
        shortName: "Merkel Cell Carcinoma",
        statement: "<p>KEYTRUDA is indicated for the treatment of adult and pediatric patients with recurrent locally advanced or metastatic Merkel cell carcinoma (MCC).</p>"
    },
    15: {
        id: 15,
        modifier: "",
        name: "First-line Monotherapy for Advanced Urothelial Carcinoma",
        shortName: "1L Monotherapy – UC",
        statement: "<p>KEYTRUDA, as a single agent, is indicated for the treatment of patients with locally advanced or metastatic urothelial carcinoma who are not eligible for any platinum-containing chemotherapy.</p>"
    },
    16: {
        id: 16,
        modifier: "",
        name: "Second-line Monotherapy for Advanced Urothelial Carcinoma—Post–Platinum Failure",
        shortName: "2L Monotherapy – UC",
        statement: "<p>KEYTRUDA, as a single agent, is indicated for the treatment of patients with locally advanced or metastatic urothelial carcinoma who have disease progression during or following platinum-containing chemotherapy or within 12 months of neoadjuvant or adjuvant treatment with platinum-containing chemotherapy.</p>"
    },
    17: {
        id: 17,
        modifier: "High-Risk",
        name: "Non-muscle Invasive Bladder Cancer",
        shortName: "High-Risk NMIBC",
        statement: "<p>KEYTRUDA, as a single agent, is indicated for the treatment of patients with Bacillus Calmette-Guerin (BCG)-unresponsive, high-risk, non-muscle invasive bladder cancer (NMIBC) with carcinoma in situ (CIS) with or without papillary tumors who are ineligible for or have elected not to undergo cystectomy.</p>"
    },
    18: {
        id: 18,
        modifier: "",
        name: "First-line Combination Therapy for Advanced HER2-Negative Gastric or GEJ Adenocarcinoma",
        shortName: "Advanced Gastric or GEJ Cancer",
        statement: `<p>KEYTRUDA, in combination with fluoropyrimidine- and platinum-containing chemotherapy, is indicated for the first-line treatment of adults with locally advanced unresectable or metastatic HER2-negative gastric or gastroesophageal junction (GEJ) adenocarcinoma.</p>`
    },
    19: {
        id: 19,
        modifier: "Advanced",
        name: "Esophageal or GEJ Carcinoma",
        shortName: "Advanced Esophageal or GEJ Carcinoma",
        statement:
            `<p>KEYTRUDA is indicated for the treatment of patients with locally advanced or metastatic esophageal or gastroesophageal junction (GEJ) (tumors with epicenter 1 to 5 centimeters above the GEJ) carcinoma that is not amenable to surgical resection or definitive chemoradiation either:</p>
            <ul>
                <li>in combination with platinum- and fluoropyrimidine-based chemotherapy, or</li>
                <li>as a single agent after one or more prior lines of systemic therapy for patients with tumors of squamous cell histology that express programmed death ligand 1 (PD&#8288;-&#8288;L1) [combined positive score (CPS) ≥10] as determined by an FDA-approved test.</li>
            </ul>
        `
    },
    20: {
        id: 20,
        modifier: "Relapsed or Refractory",
        name: "cHL",
        shortName: "Relapsed or Refractory Classical Hodgkin Lymphoma",
        statement: "<p>KEYTRUDA is indicated for the treatment of adult patients with relapsed or refractory classical Hodgkin lymphoma (cHL).</p><p>KEYTRUDA is indicated for the treatment of pediatric patients with refractory classical Hodgkin lymphoma (cHL), or cHL that has relapsed after 2 or more lines of therapy.</p>"
    },
    21: {
        id: 21,
        modifier: "Refractory or Relapsed",
        name: "PMBCL",
        shortName: "PMBCL",
        statement: "<p>KEYTRUDA is indicated for the treatment of adult and pediatric patients with refractory primary mediastinal large B-cell lymphoma (PMBCL), or who have relapsed after 2 or more prior lines of therapy. KEYTRUDA is not recommended for treatment of patients with PMBCL who require urgent cytoreductive therapy.</p>"
    },
    22: {
        id: 22,
        modifier: "Advanced",
        name: "MSI&#8288;-&#8288;H/dMMR Cancers",
        shortName: "MSI&#8288;-&#8288;H/dMMR Cancers",
        statement:
        `
        <p>KEYTRUDA is indicated for the treatment of adult and pediatric patients with unresectable or metastatic microsatellite instability-high (MSI&#8288;-&#8288;H) or mismatch repair deficient (dMMR) solid tumors, as determined by an FDA-approved test, that have progressed following prior treatment and who have no satisfactory alternative treatment options.</p>
        `
    },
    23: {
        id: 23,
        modifier: "Advanced",
        name: "MSI&#8288;-&#8288;H/dMMR CRC",
        shortName: "MSI&#8288;-&#8288;H/dMMR CRC",
        statement: "<p>KEYTRUDA is indicated for the treatment of patients with unresectable or metastatic MSI&#8288;-&#8288;H or dMMR colorectal cancer (CRC) as determined by an FDA-approved test.</p> "
    },
    24: {
        id: 24,
        name: "Second-line Monotherapy for Recurrent or Metastatic Cervical Cancer",
        shortName: "Cervical Cancer", //will need to update
        statement: "<p>KEYTRUDA, as a single agent, is indicated for the treatment of patients with recurrent or metastatic cervical cancer with disease progression on or after chemotherapy whose tumors express programmed death ligand 1 (PD&#8288;-&#8288;L1) [combined positive score (CPS) ≥1] as determined by an FDA-approved test.</p>"
    },
    // 25: { // Removing as a part of Accelerated Approval Removal
    //     id: 25,
    //     modifier: "Advanced",
    //     name: "TMB-H Cancers",
    //     shortName: "TMB-H Cancers",
    //     statement: "<p>KEYTRUDA is indicated for the treatment of adult and pediatric patients with unresectable or metastatic tumor mutational burden-high (TMB-H) [≥10 mutations/megabase (mut/Mb)] solid tumors, as determined by an FDA-approved test, that have progressed following prior treatment and who have no satisfactory alternative treatment options. This indication is approved under accelerated approval based on tumor response rate and durability of response. Continued approval for this indication may be contingent upon verification and description of clinical benefit in the confirmatory trials. The safety and effectiveness of KEYTRUDA in pediatric patients with TMB-H central nervous system cancers have not been established.</p>"
    // },
    26: {
        id: 26,
        modifier: "Advanced",
        name: "Triple-Negative Breast Cancer",
        shortName: "Advanced Triple-Negative Breast Cancer",
        statement: "<p>KEYTRUDA, in combination with chemotherapy, is indicated for the treatment of patients with locally recurrent unresectable or metastatic triple-negative breast cancer (TNBC) whose tumors express programmed death ligand 1 (PD&#8288;-&#8288;L1) [combined positive score (CPS) ≥10] as determined by an FDA-approved test.</p>"
    },
    27: {
        id: 27,
        modifier: "High-Risk Early-Stage",
        name: "Triple-Negative Breast Cancer",
        shortName: "High-Risk Early-Stage Triple-Negative Breast Cancer",
        statement: "<p>KEYTRUDA is indicated for the treatment of patients with high-risk early-stage triple-negative breast cancer (TNBC) in combination with chemotherapy as neoadjuvant treatment, and then continued as a single agent as adjuvant treatment after surgery.</p>"
    },
    28: {
        id: 28,
        name: "First-line Combination Therapy for Persistent, Recurrent, or Metastatic Cervical Cancer",
        shortName: "Cervical Cancer",
        statement: "<p>KEYTRUDA, in combination with chemotherapy, with or without bevacizumab, is indicated for the treatment of patients with persistent, recurrent, or metastatic cervical cancer whose tumors express programmed death ligand 1 (PD&#8288;-&#8288;L1) [combined positive score (CPS) ≥1] as determined by an FDA-approved test.</p>"
    },
    29: {
        id: 29,
        modifier: "Adjuvant Treatment for",
        name: "Renal Cell Carcinoma",
        shortName: "Adjuvant Treatment for RCC",
        statement: "<p>KEYTRUDA is indicated for the adjuvant treatment of patients with renal cell carcinoma (RCC) at intermediate-high or high risk of recurrence following nephrectomy, or following nephrectomy and resection of metastatic lesions.</p>"
    },
    30: {
        id: 30,
        modifier: "MSI&#8288;-&#8288;H/dMMR Advanced" ,
        name: "Endometrial Carcinoma",
        shortName: "Advanced MSI&#8288;-&#8288;H/dMMR Endometrial Carcinoma",
        statement: "<p>KEYTRUDA, as a single agent, is indicated for the treatment of adult patients with advanced endometrial carcinoma that is MSI&#8288;-&#8288;H or dMMR, as determined by an FDA-approved test, who have disease progression following prior systemic therapy in any setting and are not candidates for curative surgery or radiation.</p>"
    },
    31: { // Keytruda Lenvima Combo linkout 
        id: 31,
        modifier: "Another Indication for",
        name: " a Combination Treatment",
        shortName: "Another Indication For Advanced RCC",
        statement: "",
        isCombination: true,
    },
    32: { // Keytruda Lenvima Combo linkout
        id: 32,
        modifier: "Another Indication for",
        name: " a Combination Treatment",
        shortName: "Another Indication For Advanced Endometrial Carcinoma",
        statement: "",
        isCombination: true,
    },
    33: {
        id: 33,
        name: "Adjuvant Therapy for Stage IB (T2a&nbsp;≥4&nbsp;cm), Stage II, or Stage IIIA NSCLC",
        shortName: "Adjuvant Therapy for Stage IB (T2a&nbsp;≥4&nbsp;cm), Stage II, or Stage IIIA NSCLC",
        statement: "<p>KEYTRUDA, as a single agent, is indicated for adjuvant treatment following resection and platinum-based chemotherapy for adult patients with stage IB (T2a&nbsp;≥4&nbsp;cm), II, or IIIA non–small cell lung cancer (NSCLC).</p>"
    },
    34: {
        id: 34,
        modifier: "Neoadjuvant Combination Therapy",
        name: "and Then Continued as Adjuvant Monotherapy After Surgery, for Resectable (Tumors ≥4 cm or Node Positive) NSCLC",
        shortname: "Neoadjuvant Combination Therapy for Resectable Stage II, Stage IIIA, or Stage IIIB (T3&#8288;-&#8288;4N2) NSCLC, and Continued as Adjuvant Monotherapy",
        statement: "<p>KEYTRUDA is indicated for the treatment of patients with resectable (tumors ≥4 cm or node positive) non&ndash;small cell lung cancer (NSCLC) in combination with platinum&#8288;-&#8288;containing chemotherapy as neoadjuvant treatment, and then continued as a single agent as adjuvant treatment after surgery.</p>"
    },
    35: {
        id: 35,
        name: "Combination Therapy for Advanced Biliary Tract Cancer",
        shortName: "Biliary shortname",
        statement: "<p>KEYTRUDA, in combination with gemcitabine and cisplatin, is indicated for the treatment of patients with locally advanced unresectable or metastatic biliary tract cancer (BTC).</p>"
    },
    36: {
        id: 36,
        name: "First-line Combination for Advanced Urothelial Cancer",
        shortName: "",
        statement: "<p>KEYTRUDA, in combination with enfortumab vedotin, is indicated for the treatment of adult patients with locally advanced (LA) or metastatic urothelial cancer (mUC).</p>"
    },
    37: {
        id: 37,
        name: "In Combination With Chemoradiotherapy for FIGO 2014 Stage III-IVA Cervical Cancer",
        shortName: "High-Risk Locally Advanced Cervical Cancer",
        statement: "<p>KEYTRUDA, in combination with chemoradiotherapy (CRT), is indicated for the treatment of patients with FIGO 2014 Stage III-IVA cervical cancer.</p>", 
        definitions: "FIGO = International Federation of Gynecology and Obstetrics."
    }
};
