import React from 'react';
import styled from 'styled-components';
import { Heading, Text, WYSIWYG } from '../../util/typography';
import * as colors from '../../util/colors';
import { createLocalScaleFunction } from '../../util/functions';
import ComponentWrapper from '../layout/ComponentWrapper';
import icon from '../../assets/icon-zoom.svg';
import { NotationBlock } from '../../util/typography';
import { componentTheme } from '../../util/functions';
import { SecondaryCallout } from '../../components';

const Container = styled.div`
    border: 1px solid ${colors.silver};
    border-radius: 8px;
    background-color: ${colors.white};
`;

const HeaderContainer = styled.div`
    margin-bottom: ${createLocalScaleFunction(16, 320, 20, 992)};
`;

const ChartTitle = styled(Heading.H4)``;

const ChartLabel = styled(Heading.H6)`
    text-align: center;
    padding: 16px 20px clamp(10px, 3%, 16px);
    border-bottom: 1px solid ${colors.pebble};
`;

const Limitation = styled(Text.Body)`
    display: block;
    margin-top: 10px;
`;
const CurveImageWrapper = styled.div`
    overflow: hidden;
    padding: 20px;

    > img {
        max-width: 100%;
    }
`;

const PinchContainer = styled.div`
    display: flex;
    align-items: center;
    margin-top: 16px;

    @media (hover:hover) {
        display: none;
    }
`;

const PinchIcon = styled.span`
    height: 40px;
    width: 40px;
    display: block;
    background-size: 35px 35px;
    background-repeat: no-repeat;
    background-image: url(${icon});
    margin-right: 6px;
`;

const PinchLabel = styled(Text.Eyebrow)`
    text-transform: uppercase;
    color: ${colors.slate};
`;

const ChartAttribution = styled(Text.Footnote)`
    display: block;
    margin-top: clamp(20px, 4%, 30px);
`
const CopyrightAttribution = styled(Text.Footnote)`
    display: block;
    margin-top: clamp(20px, 4%, 30px);
`

const AdditionalInformationTitle = styled(Heading.H5)`
    margin-top: 30px;
`;

const AdditionalInformationDescription = styled(WYSIWYG)`
    display: inline-block;
    margin-top: 10px;
`;


const KMCurve = props => {
    const theme = componentTheme(true, props.sectionTheme, props.theme);
    return (
        <ComponentWrapper>
            <HeaderContainer>
                <ChartTitle as='H3' theme={theme} dangerouslySetInnerHTML={{ __html: props.title }}/>
                {props.limitation !== '' && (
                    <Limitation forwardedAs="div" theme={theme} dangerouslySetInnerHTML={{ __html: props.limitation }} />
                )}

                <PinchContainer>
                    <PinchIcon />
                    <PinchLabel>Pinch to zoom & enlarge</PinchLabel>
                </PinchContainer>
            </HeaderContainer>
            {(props.hr || props.image) &&
                <Container>
                    {props.hr && <ChartLabel dangerouslySetInnerHTML={{ __html: props.hr }} theme={theme} />}
                    {props.image &&
                        <CurveImageWrapper>
                            {typeof props.image === 'function' && props.image()}
                        </CurveImageWrapper>
                    }
                </Container>
            }
            {props.chartAttribution && <ChartAttribution dangerouslySetInnerHTML={{ __html: props.chartAttribution }} />}

            {props.additionalInformation &&
                <>
                    {props.additionalInformation.map((item, index) =>
                        <div key={index}>
                            {item.title && <AdditionalInformationTitle as='H4' theme={theme} dangerouslySetInnerHTML={{ __html: item.title }}></AdditionalInformationTitle>}
                            {item.text && <AdditionalInformationDescription theme={theme} dangerouslySetInnerHTML={{ __html: item.text }}></AdditionalInformationDescription>}
                        </div>
                    )}
                </>
            }

            { (props.secondarycallout) && <SecondaryCallout title={props.secondarycallout.title} text={props.secondarycallout.text} alt={props.secondarycallout.alt} /> }

            {(props.footnotes || props.definitions) && <NotationBlock footnotes={props.footnotes} definitions={props.definitions} theme={theme} />}
        </ComponentWrapper>
    );
};

export default KMCurve;
