import React from 'react';
import styled from 'styled-components';
import * as colors from '../../util/colors';
import FooterLink from './FooterLink';

const Menu = styled.ul`
    list-style: none;
    padding-inline-start: 0;
    margin-block-start: 0;
    margin-block-end: 0;
    margin-bottom: 16px;
    flex: 1 1 233px;
    max-width: 280px;
    margin-right: 10px;
`;

const MenuItem = styled.li`
    margin-bottom: 8px;
`;

const Heading = styled.h2`
    font-size: 14px;
    font-weight: inherit;
    color: ${colors.white};
`;

const FooterSubMenu = ({ item }) => {
    return (
        <>
            <Menu>
                <MenuItem>
                    <Heading>{item.label}</Heading>
                </MenuItem>
                {item.children.map((item, i) => (
                    <FooterLink key={i} url={item.url} label={item.label} />
                ))}
            </Menu>
        </>
    );
};

export default FooterSubMenu;
