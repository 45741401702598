import React from 'react';
import styled from 'styled-components';
import { Text } from '../../util/typography';
import * as colors from '../../util/colors';
import { setPropsFromURL } from '../../util/CTA';

const MenuItem = styled.li`
    margin-bottom: 8px;
`;

const StyledLink = styled.a`
    text-decoration: none;
    color: ${colors.sky};

    &:hover {
        color: ${colors.skyLight};
    }

    > span {
        color: inherit;
    }
`;

const FooterLink = ({ label, url }) => {

    return (
        <MenuItem>
            <StyledLink {...setPropsFromURL(url)} data-design-category='bottom_nav_vd'>
                <Text.Caption>{label}</Text.Caption>
            </StyledLink>
        </MenuItem>
    );
};

export default FooterLink;
