import React from 'react';
import styled from 'styled-components';

import FooterSubMenu from './FooterSubMenu';

const Container = styled.div`
    display: flex;
    flex-wrap: wrap;

    div {
        flex: 1 1 243px;
        max-width: 280px;
    }
`;

const FooterMenuColumn = ({ items }) => {
    return (
        <div>
            {items.map((item, i) => (
                <FooterSubMenu item={item} key={i} />
            ))}
        </div>
    );
};

const FooterMenu = ({ items }) => {
    const columns = [ [ items[0], items[1] ], [ items[2], items[3] ], [ items[4] ] ];

    return (
        <Container>
            {columns.map((items, i) => (
                <FooterMenuColumn key={i} items={items} />
            ))}
        </Container>
    );
};

export default FooterMenu;
