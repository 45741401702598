import React, { createContext, useState, useEffect } from 'react';
import { cancerTypeKeyIdMap } from '../../util/data/cancerTypes';
import { useGroup } from '../../util/hooks';
import { REFRESHED_PAGE_KEY, SELECTED_CANCER_TYPES_KEY } from '../../store/storage-keys';

export const FilterContext = createContext(null);

const FilterProvider = ({ children }) => {
    /**
     * filteredCancerTypes USED to be stored in global Redux and would persist across pages, but now it is fully localized, so
     * each time you arrive on a Triage page, the filter is reinitialized. We use React Context so multiple components can access
     * this state. FilterProvider wraps the layout for each page that uses the filter, and its components can access it with useContext.
     */

    let isBrowser = typeof window !== 'undefined' && window.location.protocol !== 'data:';
    let onHomepage = isBrowser && window.location.pathname == '/';

    const group = useGroup();
    
    const [filteredCancerTypes, _setFilteredCancerTypes] = useState(() => {
        if (group) {
            // If there's a valid group string, we'll use that to initialize filteredCancerTypes. See more info in hooks.js
            return cancerTypeKeyIdMap[group];
        }
        else if (isBrowser && !onHomepage
            && window.sessionStorage.getItem(REFRESHED_PAGE_KEY) == 'true'
            && window.sessionStorage.getItem(SELECTED_CANCER_TYPES_KEY)
        ) {
            /**
             * If we're NOT on the homepage, and the browser has been refreshed, then we will persist the selected cancer types
             * (On the homepage, the user can't customize cancer types, and the group string will take care of the rest)
             */
            return JSON.parse(window.sessionStorage.getItem(SELECTED_CANCER_TYPES_KEY)) || [];
        }
        else {
            isBrowser && window.sessionStorage.setItem(SELECTED_CANCER_TYPES_KEY, '[]');
            return [];
        }
    });

    function setFilteredCancerTypes (newSet) {
        // When filteredCancerTypes is updated, update sessionStorage (used for analytics and persisting state over page refresh)
        isBrowser && window.sessionStorage.setItem(SELECTED_CANCER_TYPES_KEY, JSON.stringify(newSet));
        _setFilteredCancerTypes(newSet);
    }
    
    useEffect(() => {
        if (group) {
            setFilteredCancerTypes(cancerTypeKeyIdMap[group]);
        }
    }, [group])

    return (
        <FilterContext.Provider value={{ filteredCancerTypes, setFilteredCancerTypes }}>
            {children}
        </FilterContext.Provider>
    );
};

export default FilterProvider;