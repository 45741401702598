import React from 'react';
import styled from 'styled-components';
import { Heading, Text, NotationBlock} from '../../util/typography';
import ComponentWrapper from '../layout/ComponentWrapper';
// import Img from "gatsby-image";
// import { useStaticQuery, graphql } from "gatsby";
import * as colors from '../../util/colors';

const Container = styled.div`
    border-radius: 8px;
    overflow: hidden;
    position: relative;
`;

const ImageContainer = styled.div`
    img {
        width: 100%;
        display: block;
    }
    //@todo is there a better way of eliminating the inline-block whitespace?
    .gatsby-image-wrapper-constrained {
        // vertical-align: bottom;
    }
`;

const CaptionContainer = styled.div`
    background-color: ${colors.charcoal};
    padding: 20px;
    color: ${colors.white};
`;

const CaptionTitle = styled(Heading.H4)`
    margin-bottom: 4px;
    color: ${colors.white};
`;

const Caption = styled(Text.Body).attrs({ forwardedAs: 'div' })`
    color: ${colors.white};

    * {
        &:first-child {
            margin-top: 0;
        }

        &:last-child {
            margin-bottom: 0;
        }
    }
`;

const ImageComponent = props => {
    return (
        <ComponentWrapper>
            <Container>
                <ImageContainer>
                    {typeof props.image === 'function' && props.image()}
                </ImageContainer>
                {(props.title || props.text) && (
                    <CaptionContainer>
                        <CaptionTitle as='H3'>{props.title}</CaptionTitle>
                        <Caption dangerouslySetInnerHTML={{ __html: props.caption }}></Caption>
                    </CaptionContainer>
                )}
            </Container>
            {(props.footnotes || props.definitions) && <NotationBlock footnotes={props.footnotes} definitions={props.definitions} />}
            
        </ComponentWrapper>
    );
};

export default ImageComponent;
