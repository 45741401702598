import React from 'react';
import styled from 'styled-components';
import * as colors from '../../util/colors';
import { Heading } from '../../util/typography';
import { between, rgba } from 'polished';
import { componentTheme, createMarkup, stringToSlug } from '../../util/functions';

const Container = styled.div`
    max-width: 992px;
    margin: 20px auto;

    & + & {
        margin-top: 80px;
    }

    @media (min-width: 321px) and (max-width: 1032px) {
        margin: ${between('20px', '30px', '320px', '1032px')} auto;

        & + & {
            margin-top: ${between('80px', '100px', '320px', '1032px')}
        }
    }

    @media (min-width: 1033px) {
        margin: 30px auto;

        & + & {
            margin-top: 100px;
        }
    }

    hr {
        border: 0;
        height: 1px;
        background-color: ${({ theme }) =>
            theme === 'dark' ? colors.keytrudaGreen : rgba(colors.white, 0.4)};
        margin-top: 10px;
        margin-bottom: 30px;

        @media (min-width: 321px) and (max-width: 1032px) {
            margin-top: ${between('10px', '20px', '320px', '1032px')};
            margin-bottom: ${between('30px', '40px', '320px', '1032px')};
        }

        @media (min-width: 1033px) {
            margin-top: 20px;
            margin-bottom: 40px;
        }
    }
`;

const ComponentContainer = styled.div`
    > *:first-child {
        margin-top: 0;
        padding-top: 0;
    }

    > *:last-child {
        margin-bottom: 0;
        padding-bottom: 0;
    }
`;

const AnchorTarget = styled.span`
    display: block;
    height: 0;
    overflow: hidden;
    line-height:none;
    transform: translateY(-155px); //manage offset for fully-expanded sticky nav
`;

const PageSubSection = props => {
    let theme = componentTheme(true, props.sectionTheme, props.theme);

    // pass Section theme (dark or light, referring to the dominent background color), to child components so they can theme themselves as appropriate
    const children = React.Children.map(props.children, child => {
        return React.cloneElement(child, {
            theme: theme,
        });
    });

    return (
        <Container theme={theme}>
            <AnchorTarget id={ props.title && `${stringToSlug(props.title)}`}/>
            <Heading.H3
                as='H2'
                theme={theme}
                dangerouslySetInnerHTML={createMarkup(props.title)}
            />
            <hr theme={theme} />
            <ComponentContainer>
                {children}
            </ComponentContainer>
        </Container>
    );
};

export default PageSubSection;
