const globalSafetyList = [
    {
        name: 'Severe and Fatal Immune-Mediated Adverse Reactions',
        tagType: 'h3',
        items: [
            'KEYTRUDA is a monoclonal antibody that belongs to a class of drugs that bind to either the programmed death receptor-1 (PD&#8288;-&#8288;1) or the programmed death ligand 1 (PD&#8288;-&#8288;L1), blocking the PD&#8288;-&#8288;1/PD&#8288;-&#8288;L1 pathway, thereby removing inhibition of the immune response, potentially breaking peripheral tolerance and inducing immune-mediated adverse reactions. Immune-mediated adverse reactions, which may be severe or fatal, can occur in any organ system or tissue, can affect more than one body system simultaneously, and can occur at any time after starting treatment or after discontinuation of treatment. Important immune-mediated adverse reactions listed here may not include all possible severe and fatal immune-mediated adverse reactions.',
            'Monitor patients closely for symptoms and signs that may be clinical manifestations of underlying immune-mediated adverse reactions. Early identification and management are essential to ensure safe use of anti–PD&#8288;-&#8288;1/PD&#8288;-&#8288;L1 treatments. Evaluate liver enzymes, creatinine, and thyroid function at baseline and periodically during treatment. For patients with TNBC treated with KEYTRUDA in the neoadjuvant setting, monitor blood cortisol at baseline, prior to surgery, and as clinically indicated. In cases of suspected immune-mediated adverse reactions, initiate appropriate workup to exclude alternative etiologies, including infection. Institute medical management promptly, including specialty consultation as appropriate.',
            'Withhold or permanently discontinue KEYTRUDA depending on severity of the immune-mediated adverse reaction. In general, if KEYTRUDA requires interruption or discontinuation, administer systemic corticosteroid therapy (1 to 2&nbsp;mg/kg/day prednisone or equivalent) until improvement to Grade 1 or less. Upon improvement to Grade 1 or less, initiate corticosteroid taper and continue to taper over at least 1 month. Consider administration of other systemic immunosuppressants in patients whose adverse reactions are not controlled with corticosteroid therapy.'
        ],
    },
    {
        name: '<em>Immune-Mediated Pneumonitis</em>',
        tagType: 'h4',
        items: [
            'KEYTRUDA can cause immune-mediated pneumonitis. The incidence is higher in patients who have received prior thoracic radiation. Immune-mediated pneumonitis occurred in 3.4% (94/2799) of patients receiving KEYTRUDA, including fatal (0.1%), Grade 4 (0.3%), Grade 3 (0.9%), and Grade 2 (1.3%) reactions. Systemic corticosteroids were required in 67% (63/94) of patients. Pneumonitis led to permanent discontinuation of KEYTRUDA in 1.3% (36) and withholding in 0.9% (26) of patients. All patients who were withheld reinitiated KEYTRUDA after symptom improvement; of these, 23% had recurrence. Pneumonitis resolved in 59% of the 94 patients.',
            'Pneumonitis occurred in 8% (31/389) of adult patients with cHL receiving KEYTRUDA as a single agent, including Grades 3–4 in 2.3% of patients. Patients received high-dose corticosteroids for a median duration of 10 days (range: 2 days to 53 months). Pneumonitis rates were similar in patients with and without prior thoracic radiation. Pneumonitis led to discontinuation of KEYTRUDA in 5.4% (21) of patients. Of the patients who developed pneumonitis, 42% interrupted KEYTRUDA, 68% discontinued KEYTRUDA, and 77% had resolution.',
            'Pneumonitis occurred in 7% (41/580) of adult patients with resected NSCLC who received KEYTRUDA as a single agent for adjuvant treatment of NSCLC, including fatal (0.2%), Grade 4 (0.3%), and Grade 3 (1%) adverse reactions. Patients received high-dose corticosteroids for a median duration of 10 days (range: 1 day to 2.3 months). Pneumonitis led to discontinuation of KEYTRUDA in 26 (4.5%) of patients. Of the patients who developed pneumonitis, 54% interrupted KEYTRUDA, 63% discontinued KEYTRUDA, and 71% had resolution.',
        ],
    },
    {
        name: '<em>Immune-Mediated Colitis</em>',
        tagType: 'h4',
        items: [
            'KEYTRUDA can cause immune-mediated colitis, which may present with diarrhea. Cytomegalovirus infection/reactivation has been reported in patients with corticosteroid-refractory immune-mediated colitis. In cases of corticosteroid-refractory colitis, consider repeating infectious workup to exclude alternative etiologies. Immune-mediated colitis occurred in 1.7% (48/2799) of patients receiving KEYTRUDA, including Grade 4 (<0.1%), Grade 3 (1.1%), and Grade 2 (0.4%) reactions. Systemic corticosteroids were required in 69% (33/48); additional immunosuppressant therapy was required in 4.2% of patients. Colitis led to permanent discontinuation of KEYTRUDA in 0.5% (15) and withholding in 0.5% (13) of patients. All patients who were withheld reinitiated KEYTRUDA after symptom improvement; of these, 23% had recurrence. Colitis resolved in 85% of the 48 patients.',
        ],
    },
    {
        name:
            '<em>Hepatotoxicity and Immune-Mediated Hepatitis</em>',
        tagType: 'h4',
        items: [
            {
                name: '<i>KEYTRUDA</i> AS A SINGLE AGENT',
                tagType: 'h5',
                items: [
                    'KEYTRUDA can cause immune-mediated hepatitis. Immune-mediated hepatitis occurred in 0.7% (19/2799) of patients receiving KEYTRUDA, including Grade 4 (<0.1%), Grade 3 (0.4%), and Grade 2 (0.1%) reactions. Systemic corticosteroids were required in 68% (13/19) of patients; additional immunosuppressant therapy was required in 11% of patients. Hepatitis led to permanent discontinuation of KEYTRUDA in 0.2% (6) and withholding in 0.3% (9) of patients. All patients who were withheld reinitiated KEYTRUDA after symptom improvement; of these, none had recurrence. Hepatitis resolved in 79% of the 19 patients.',
                ],
            },
            {
                name: '<i>KEYTRUDA</i> WITH AXITINIB',
                tagType: 'h5',
                items: [
                    'KEYTRUDA in combination with axitinib can cause hepatic toxicity. Monitor liver enzymes before initiation of and periodically throughout treatment. Consider monitoring more frequently as compared to when the drugs are administered as single agents. For elevated liver enzymes, interrupt KEYTRUDA and axitinib, and consider administering corticosteroids as needed. With the combination of KEYTRUDA and axitinib, Grades 3 and 4 increased alanine aminotransferase (ALT) (20%) and increased aspartate aminotransferase (AST) (13%) were seen at a higher frequency compared to KEYTRUDA alone. Fifty-nine percent of the patients with increased ALT received systemic corticosteroids. In patients with ALT ≥3 times upper limit of normal (ULN) (Grades 2–4, n=116), ALT resolved to Grades 0–1 in 94%. Among the 92 patients who were rechallenged with either KEYTRUDA (n=3) or axitinib (n=34) administered as a single agent or with both (n=55), recurrence of ALT ≥3 times ULN was observed in 1 patient receiving KEYTRUDA, 16 patients receiving axitinib, and 24 patients receiving both. All patients with a recurrence of ALT ≥3 ULN subsequently recovered from the event.',
                ],
            },
        ],
    },
    {
        name: '<em>Immune-Mediated Endocrinopathies</em>',
        tagType: 'h4',
        items: [
            {
                name: 'ADRENAL INSUFFICIENCY',
                tagType: 'h5',
                items: [
                    'KEYTRUDA can cause primary or secondary adrenal insufficiency. For Grade 2 or higher, initiate symptomatic treatment, including hormone replacement as clinically indicated. Withhold KEYTRUDA depending on severity. Adrenal insufficiency occurred in 0.8% (22/2799) of patients receiving KEYTRUDA, including Grade 4 (<0.1%), Grade 3 (0.3%), and Grade 2 (0.3%) reactions. Systemic corticosteroids were required in 77% (17/22) of patients; of these, the majority remained on systemic corticosteroids. Adrenal insufficiency led to permanent discontinuation of KEYTRUDA in <0.1% (1) and withholding in 0.3% (8) of patients. All patients who were withheld reinitiated KEYTRUDA after symptom improvement.'
                ],
            },
            {
                name: 'HYPOPHYSITIS',
                tagType: 'h5',
                items: [
                    'KEYTRUDA can cause immune-mediated hypophysitis. Hypophysitis can present with acute symptoms associated with mass effect such as headache, photophobia, or visual field defects. Hypophysitis can cause hypopituitarism. Initiate hormone replacement as indicated. Withhold or permanently discontinue KEYTRUDA depending on severity. Hypophysitis occurred in 0.6% (17/2799) of patients receiving KEYTRUDA, including Grade 4 (<0.1%), Grade 3 (0.3%), and Grade 2 (0.2%) reactions. Systemic corticosteroids were required in 94% (16/17) of patients; of these, the majority remained on systemic corticosteroids. Hypophysitis led to permanent discontinuation of KEYTRUDA in 0.1% (4) and withholding in 0.3% (7) of patients. All patients who were withheld reinitiated KEYTRUDA after symptom improvement.'
                ],
            },
            {
                name: 'THYROID DISORDERS',
                tagType: 'h5',
                items: [
                    'KEYTRUDA can cause immune-mediated thyroid disorders. Thyroiditis can present with or without endocrinopathy. Hypothyroidism can follow hyperthyroidism. Initiate hormone replacement for hypothyroidism or institute medical management of hyperthyroidism as clinically indicated. Withhold or permanently discontinue KEYTRUDA depending on severity. Thyroiditis occurred in 0.6% (16/2799) of patients receiving KEYTRUDA, including Grade 2 (0.3%). None discontinued, but KEYTRUDA was withheld in <0.1% (1) of patients.',
                    'Hyperthyroidism occurred in 3.4% (96/2799) of patients receiving KEYTRUDA, including Grade 3 (0.1%) and Grade 2 (0.8%). It led to permanent discontinuation of KEYTRUDA in <0.1% (2) and withholding in 0.3% (7) of patients. All patients who were withheld reinitiated KEYTRUDA after symptom improvement. Hypothyroidism occurred in 8% (237/2799) of patients receiving KEYTRUDA, including Grade 3 (0.1%) and Grade 2 (6.2%). It led to permanent discontinuation of KEYTRUDA in <0.1% (1) and withholding in 0.5% (14) of patients. All patients who were withheld reinitiated KEYTRUDA after symptom improvement. The majority of patients with hypothyroidism required long-term thyroid hormone replacement. The incidence of new or worsening hypothyroidism was higher in 1185 patients with HNSCC, occurring in 16% of patients receiving KEYTRUDA as a single agent or in combination with platinum and FU, including Grade 3 (0.3%) hypothyroidism. The incidence of new or worsening hypothyroidism was higher in 389 adult patients with cHL (17%) receiving KEYTRUDA as a single agent, including Grade 1 (6.2%) and Grade 2 (10.8%) hypothyroidism. The incidence of new or worsening hyperthyroidism was higher in 580 patients with resected NSCLC, occurring in 11% of patients receiving KEYTRUDA as a single agent as adjuvant treatment, including Grade 3 (0.2%) hyperthyroidism. The incidence of new or worsening hypothyroidism was higher in 580 patients with resected NSCLC, occurring in 22% of patients receiving KEYTRUDA as a single agent as adjuvant treatment (KEYNOTE&#8288;-&#8288;091), including Grade 3 (0.3%) hypothyroidism.',
                ],
            },
            {
                name: 'TYPE 1 DIABETES MELLITUS (DM), WHICH CAN PRESENT WITH DIABETIC KETOACIDOSIS',
                tagType: 'h5',
                items: [
                    'Monitor patients for hyperglycemia or other signs and symptoms of diabetes. Initiate treatment with insulin as clinically indicated. Withhold KEYTRUDA depending on severity. Type 1 DM occurred in 0.2% (6/2799) of patients receiving KEYTRUDA. It led to permanent discontinuation in <0.1% (1) and withholding of KEYTRUDA in <0.1% (1) of patients. All patients who were withheld reinitiated KEYTRUDA after symptom improvement.'
                ],
            }            
        ],
    },
    {
        name: '<em>Immune-Mediated Nephritis With Renal Dysfunction</em>',
        tagType: 'h4',
        items: [
            'KEYTRUDA can cause immune-mediated nephritis. Immune-mediated nephritis occurred in 0.3% (9/2799) of patients receiving KEYTRUDA, including Grade 4 (<0.1%), Grade 3 (0.1%), and Grade 2 (0.1%) reactions. Systemic corticosteroids were required in 89% (8/9) of patients. Nephritis led to permanent discontinuation of KEYTRUDA in 0.1% (3) and withholding in 0.1% (3) of patients. All patients who were withheld reinitiated KEYTRUDA after symptom improvement; of these, none had recurrence. Nephritis resolved in 56% of the 9 patients.',
        ],
    },
    {
        name: '<em>Immune-Mediated Dermatologic Adverse Reactions</em>',
        tagType: 'h4',
        items: [
            'KEYTRUDA can cause immune-mediated rash or dermatitis. Exfoliative dermatitis, including Stevens-Johnson syndrome, drug rash with eosinophilia and systemic symptoms, and toxic epidermal necrolysis, has occurred with anti–PD&#8288;-&#8288;1/PD&#8288;-&#8288;L1 treatments. Topical emollients and/or topical corticosteroids may be adequate to treat mild to moderate nonexfoliative rashes. Withhold or permanently discontinue KEYTRUDA depending on severity. Immune-mediated dermatologic adverse reactions occurred in 1.4% (38/2799) of patients receiving KEYTRUDA, including Grade 3 (1%) and Grade 2 (0.1%) reactions. Systemic corticosteroids were required in 40% (15/38) of patients. These reactions led to permanent discontinuation in 0.1% (2) and withholding of KEYTRUDA in 0.6% (16) of patients. All patients who were withheld reinitiated KEYTRUDA after symptom improvement; of these, 6% had recurrence. The reactions resolved in 79% of the 38 patients.',
        ],
    },
    {
        name: '<em>Other Immune-Mediated Adverse Reactions</em>',
        tagType: 'h4',
        items: [
            `The following clinically significant immune-mediated adverse reactions occurred at an incidence of <1% (unless otherwise noted) in patients who received KEYTRUDA or were 
            reported with the use of other anti–PD&#8288;-&#8288;1/PD&#8288;-&#8288;L1 treatments. Severe or fatal cases have been reported for some of these adverse reactions. <i>Cardiac/Vascular</i>: Myocarditis, 
            pericarditis, vasculitis; <i>Nervous System</i>: Meningitis, encephalitis, myelitis and demyelination, myasthenic syndrome/myasthenia gravis (including exacerbation), Guillain-Barré 
            syndrome, nerve paresis, autoimmune neuropathy; <i>Ocular</i>: Uveitis, iritis and other ocular inflammatory toxicities can occur. Some cases can be associated with retinal 
            detachment. Various grades of visual impairment, including blindness, can occur. If uveitis occurs in combination with other immune-mediated adverse reactions, consider a 
            Vogt-Koyanagi-Harada-like syndrome, as this may require treatment with systemic steroids to reduce the risk of permanent vision loss; <i>Gastrointestinal</i>: Pancreatitis, to 
            include increases in serum amylase and lipase levels, gastritis, duodenitis; <i>Musculoskeletal and Connective Tissue</i>: Myositis/polymyositis, rhabdomyolysis (and associated 
            sequelae, including renal failure), arthritis (1.5%), polymyalgia rheumatica; <i>Endocrine</i>: Hypoparathyroidism; <i>Hematologic/Immune</i>: Hemolytic anemia, aplastic anemia, 
            hemophagocytic lymphohistiocytosis, systemic inflammatory response syndrome, histiocytic necrotizing lymphadenitis (Kikuchi lymphadenitis), sarcoidosis, immune 
            thrombocytopenic purpura, solid organ transplant rejection, other transplant (including corneal graft) rejection.`,
        ],
    },
    {
        name: 'Infusion-Related Reactions',
        tagType: 'h3',
        items: [
            'KEYTRUDA can cause severe or life-threatening infusion-related reactions, including hypersensitivity and anaphylaxis, which have been reported in 0.2% of 2799 patients receiving KEYTRUDA. Monitor for signs and symptoms of infusion-related reactions. Interrupt or slow the rate of infusion for Grade 1 or Grade 2 reactions. For Grade 3 or Grade 4 reactions, stop infusion and permanently discontinue KEYTRUDA.',
        ],
    },
    {
        name: 'Complications of Allogeneic Hematopoietic Stem Cell Transplantation (HSCT)',
        tagType: 'h3',
        items: [
            'Fatal and other serious complications can occur in patients who receive allogeneic HSCT before or after anti–PD&#8288;-&#8288;1/PD&#8288;-&#8288;L1 treatments. Transplant-related complications include hyperacute graft-versus-host disease (GVHD), acute and chronic GVHD, hepatic veno-occlusive disease after reduced intensity conditioning, and steroid-requiring febrile syndrome (without an identified infectious cause). These complications may occur despite intervening therapy between anti–PD&#8288;-&#8288;1/PD&#8288;-&#8288;L1 treatments and allogeneic HSCT. Follow patients closely for evidence of these complications and intervene promptly. Consider the benefit vs risks of using anti–PD&#8288;-&#8288;1/PD&#8288;-&#8288;L1 treatments prior to or after an allogeneic HSCT.'
        ],
    },
    {
        name: 'Increased Mortality in Patients With Multiple Myeloma',
        tagType: 'h3',
        items: [
            'In trials in patients with multiple myeloma, the addition of KEYTRUDA to a thalidomide analogue plus dexamethasone resulted in increased mortality. Treatment of these patients with an anti–PD&#8288;-&#8288;1/PD&#8288;-&#8288;L1 treatment in this combination is not recommended outside of controlled trials.',
        ],
    },
    {
        name: 'Embryofetal Toxicity',
        tagType: 'h3',
        items: [
            'Based on its mechanism of action, KEYTRUDA can cause fetal harm when administered to a pregnant woman. Advise women of this potential risk. In females of reproductive potential, verify pregnancy status prior to initiating KEYTRUDA and advise them to use effective contraception during treatment and for 4 months after the last dose.',
        ],
    },
    {
        name: 'Adverse Reactions',
        tagType: 'h3',
        items: [
            'In KEYNOTE&#8288;-&#8288;006, KEYTRUDA was discontinued due to adverse reactions in 9% of 555 patients with advanced melanoma; adverse reactions leading to permanent discontinuation in more than one patient were colitis (1.4%), autoimmune hepatitis (0.7%), allergic reaction (0.4%), polyneuropathy (0.4%), and cardiac failure (0.4%). The most common adverse reactions (≥20%) with KEYTRUDA were fatigue (28%), diarrhea (26%), rash (24%), and nausea (21%).',
            'In KEYNOTE&#8288;-&#8288;054, when KEYTRUDA was administered as a single agent to patients with stage III melanoma, KEYTRUDA was permanently discontinued due to adverse reactions in 14% of 509 patients; the most common (≥1%) were pneumonitis (1.4%), colitis (1.2%), and diarrhea (1%). Serious adverse reactions occurred in 25% of patients receiving KEYTRUDA. The most common adverse reaction (≥20%) with KEYTRUDA was diarrhea (28%). In KEYNOTE&#8288;-&#8288;716, when KEYTRUDA was administered as a single agent to patients with stage IIB or IIC melanoma, adverse reactions occurring in patients with stage IIB or IIC melanoma were similar to those occurring in 1011 patients with stage III melanoma from KEYNOTE&#8288;-&#8288;054.',
            'In KEYNOTE&#8288;-&#8288;189, when KEYTRUDA was administered with pemetrexed and platinum chemotherapy in metastatic nonsquamous NSCLC, KEYTRUDA was discontinued due to adverse reactions in 20% of 405 patients. The most common adverse reactions resulting in permanent discontinuation of KEYTRUDA were pneumonitis (3%) and acute kidney injury (2%). The most common adverse reactions (≥20%) with KEYTRUDA were nausea (56%), fatigue (56%), constipation (35%), diarrhea (31%), decreased appetite (28%), rash (25%), vomiting (24%), cough (21%), dyspnea (21%), and pyrexia (20%).',
            'In KEYNOTE&#8288;-&#8288;407, when KEYTRUDA was administered with carboplatin and either paclitaxel or paclitaxel protein‑bound in metastatic squamous NSCLC, KEYTRUDA was discontinued due to adverse reactions in 15% of 101 patients. The most frequent serious adverse reactions reported in at least 2% of patients were febrile neutropenia, pneumonia, and urinary tract infection. Adverse reactions observed in KEYNOTE&#8288;-&#8288;407 were similar to those observed in KEYNOTE&#8288;-&#8288;189 with the exception that increased incidences of alopecia (47% vs 36%) and peripheral neuropathy (31% vs 25%) were observed in the KEYTRUDA and chemotherapy arm compared to the placebo and chemotherapy arm in KEYNOTE&#8288;-&#8288;407.',
            'In KEYNOTE&#8288;-&#8288;042, KEYTRUDA was discontinued due to adverse reactions in 19% of 636 patients with advanced NSCLC; the most common were pneumonitis (3%), death due to unknown cause (1.6%), and pneumonia (1.4%). The most frequent serious adverse reactions reported in at least 2% of patients were pneumonia (7%), pneumonitis (3.9%), pulmonary embolism (2.4%), and pleural effusion (2.2%). The most common adverse reaction (≥20%) was fatigue (25%).',
            'In KEYNOTE&#8288;-&#8288;010, KEYTRUDA monotherapy was discontinued due to adverse reactions in 8% of 682 patients with metastatic NSCLC; the most common was pneumonitis (1.8%). The most common adverse reactions (≥20%) were decreased appetite (25%), fatigue (25%), dyspnea (23%), and nausea (20%).',
            'In KEYNOTE&#8288;-&#8288;671, adverse reactions occurring in patients with resectable NSCLC receiving KEYTRUDA in combination with platinum-containing chemotherapy, given as neoadjuvant treatment and continued as single-agent adjuvant treatment, were generally similar to those occurring in patients in other clinical trials across tumor types receiving KEYTRUDA in combination with chemotherapy.',
            'The most common adverse reactions (reported in ≥20%) in patients receiving KEYTRUDA in combination with chemotherapy were fatigue/asthenia, nausea, constipation, diarrhea, decreased appetite, rash, vomiting, cough, dyspnea, pyrexia, alopecia, peripheral neuropathy, mucosal inflammation, stomatitis, headache, weight loss, abdominal pain, arthralgia, myalgia, insomnia, palmar-plantar erythrodysesthesia, urinary tract infection, and hypothyroidism.',
            'In the neoadjuvant phase of KEYNOTE&#8288;-&#8288;671, when KEYTRUDA was administered in combination with platinum-containing chemotherapy as neoadjuvant treatment, serious adverse reactions occurred in 34% of 396 patients. The most frequent (≥2%) serious adverse reactions were pneumonia (4.8%), venous thromboembolism (3.3%), and anemia (2%). Fatal adverse reactions occurred in 1.3% of patients, including death due to unknown cause (0.8%), sepsis (0.3%), and immune-mediated lung disease (0.3%). Permanent discontinuation of any study drug due to an adverse reaction occurred in 18% of patients who received KEYTRUDA in combination with platinum-containing chemotherapy; the most frequent adverse reactions (≥1%) that led to permanent discontinuation of any study drug were acute kidney injury (1.8%), interstitial lung disease (1.8%), anemia (1.5%), neutropenia (1.5%), and pneumonia (1.3%).',
            'Of the KEYTRUDA-treated patients who received neoadjuvant treatment, 6% of 396 patients did not receive surgery due to adverse reactions. The most frequent (≥1%) adverse reaction that led to cancellation of surgery in the KEYTRUDA arm was interstitial lung disease (1%).',
            'In the adjuvant phase of KEYNOTE&#8288;-&#8288;671, when KEYTRUDA was administered as a single agent as adjuvant treatment, serious adverse reactions occurred in 14% of 290 patients. The most frequent serious adverse reaction was pneumonia (3.4%). One fatal adverse reaction of pulmonary hemorrhage occurred. Permanent discontinuation of KEYTRUDA due to an adverse reaction occurred in 12% of patients who received KEYTRUDA as a single agent, given as adjuvant treatment; the most frequent adverse reactions (≥1%) that led to permanent discontinuation of KEYTRUDA were diarrhea (1.7%), interstitial lung disease (1.4%), increased aspartate aminotransferase (1%), and musculoskeletal pain (1%).',
            'Adverse reactions observed in KEYNOTE&#8288;-&#8288;091 were generally similar to those occurring in other patients with NSCLC receiving KEYTRUDA as a single agent, with the exception of hypothyroidism (22%), hyperthyroidism (11%), and pneumonitis (7%). Two fatal adverse reactions of myocarditis occurred.',
            'In KEYNOTE&#8288;-&#8288;048, KEYTRUDA monotherapy was discontinued due to adverse events in 12% of 300 patients with HNSCC; the most common adverse reactions leading to permanent discontinuation were sepsis (1.7%) and pneumonia (1.3%). The most common adverse reactions (≥20%) were fatigue (33%), constipation (20%), and rash (20%).',
            'In KEYNOTE&#8288;-&#8288;048, when KEYTRUDA was administered in combination with platinum (cisplatin or carboplatin) and FU chemotherapy, KEYTRUDA was discontinued due to adverse reactions in 16% of 276 patients with HNSCC. The most common adverse reactions resulting in permanent discontinuation of KEYTRUDA were pneumonia (2.5%), pneumonitis (1.8%), and septic shock (1.4%). The most common adverse reactions (≥20%) were nausea (51%), fatigue (49%), constipation (37%), vomiting (32%), mucosal inflammation (31%), diarrhea (29%), decreased appetite (29%), stomatitis (26%), and cough (22%).',
            'In KEYNOTE&#8288;-&#8288;012, KEYTRUDA was discontinued due to adverse reactions in 17% of 192 patients with HNSCC. Serious adverse reactions occurred in 45% of patients. The most frequent serious adverse reactions reported in at least 2% of patients were pneumonia, dyspnea, confusional state, vomiting, pleural effusion, and respiratory failure. The most common adverse reactions (≥20%) were fatigue, decreased appetite, and dyspnea. Adverse reactions occurring in patients with HNSCC were generally similar to those occurring in patients with melanoma or NSCLC who received KEYTRUDA as a monotherapy, with the exception of increased incidences of facial edema and new or worsening hypothyroidism.',
            'In KEYNOTE&#8288;-&#8288;204, KEYTRUDA was discontinued due to adverse reactions in 14% of 148 patients with cHL. Serious adverse reactions occurred in 30% of patients receiving KEYTRUDA; those ≥1% were pneumonitis, pneumonia, pyrexia, myocarditis, acute kidney injury, febrile neutropenia, and sepsis. Three patients died from causes other than disease progression: 2 from complications after allogeneic HSCT and 1 from unknown cause. The most common adverse reactions (≥20%) were upper respiratory tract infection (41%), musculoskeletal pain (32%), diarrhea (22%), and pyrexia, fatigue, rash, and cough (20% each).',
            'In KEYNOTE&#8288;-&#8288;087, KEYTRUDA was discontinued due to adverse reactions in 5% of 210 patients with cHL. Serious adverse reactions occurred in 16% of patients; those ≥1% were pneumonia, pneumonitis, pyrexia, dyspnea, GVHD, and herpes zoster. Two patients died from causes other than disease progression: 1 from GVHD after subsequent allogeneic HSCT and 1 from septic shock. The most common adverse reactions (≥20%) were fatigue (26%), pyrexia (24%), cough (24%), musculoskeletal pain (21%), diarrhea (20%), and rash (20%).',
            'In KEYNOTE&#8288;-&#8288;170, KEYTRUDA was discontinued due to adverse reactions in 8% of 53 patients with PMBCL. Serious adverse reactions occurred in 26% of patients and included arrhythmia (4%), cardiac tamponade (2%), myocardial infarction (2%), pericardial effusion (2%), and pericarditis (2%). Six (11%) patients died within 30 days of start of treatment. The most common adverse reactions (≥20%) were musculoskeletal pain (30%), upper respiratory tract infection and pyrexia (28% each), cough (26%), fatigue (23%), and dyspnea (21%).',
            'In KEYNOTE&#8288;-&#8288;A39, when KEYTRUDA was administered in combination with enfortumab vedotin to patients with locally advanced or metastatic urothelial cancer (n=440), fatal adverse reactions occurred in 3.9% of patients, including acute respiratory failure (0.7%), pneumonia (0.5%), and pneumonitis/ILD (0.2%). Serious adverse reactions occurred in 50% of patients receiving KEYTRUDA in combination with enfortumab vedotin; the serious adverse reactions in ≥2% of patients were rash (6%), acute kidney injury (5%), pneumonitis/ILD (4.5%), urinary tract infection (3.6%), diarrhea (3.2%), pneumonia (2.3%), pyrexia (2%), and hyperglycemia (2%). Permanent discontinuation of KEYTRUDA occurred in 27% of patients. The most common adverse reactions (≥2%) resulting in permanent discontinuation of KEYTRUDA were pneumonitis/ILD (4.8%) and rash (3.4%). The most common adverse reactions (≥20%) occurring in patients treated with KEYTRUDA in combination with enfortumab vedotin were rash (68%), peripheral neuropathy (67%), fatigue (51%), pruritus (41%), diarrhea (38%), alopecia (35%), weight loss (33%), decreased appetite (33%), nausea (26%), constipation (26%), dry eye (24%), dysgeusia (21%), and urinary tract infection (21%).',
            'In KEYNOTE&#8288;-&#8288;052, KEYTRUDA was discontinued due to adverse reactions in 11% of 370 patients with locally advanced or metastatic urothelial carcinoma. Serious adverse reactions occurred in 42% of patients; those ≥2% were urinary tract infection, hematuria, acute kidney injury, pneumonia, and urosepsis. The most common adverse reactions (≥20%) were fatigue (38%), musculoskeletal pain (24%), decreased appetite (22%), constipation (21%), rash (21%), and diarrhea (20%).',
            'In KEYNOTE&#8288;-&#8288;045, KEYTRUDA was discontinued due to adverse reactions in 8% of 266 patients with locally advanced or metastatic urothelial carcinoma. The most common adverse reaction resulting in permanent discontinuation of KEYTRUDA was pneumonitis (1.9%). Serious adverse reactions occurred in 39% of KEYTRUDA-treated patients; those ≥2% were urinary tract infection, pneumonia, anemia, and pneumonitis. The most common adverse reactions (≥20%) in patients who received KEYTRUDA were fatigue (38%), musculoskeletal pain (32%), pruritus (23%), decreased appetite (21%), nausea (21%), and rash (20%).',
            'In KEYNOTE&#8288;-&#8288;057, KEYTRUDA was discontinued due to adverse reactions in 11% of 148 patients with high-risk NMIBC. The most common adverse reaction resulting in permanent discontinuation of KEYTRUDA was pneumonitis (1.4%). Serious adverse reactions occurred in 28% of patients; those ≥2% were pneumonia (3%), cardiac ischemia (2%), colitis (2%), pulmonary embolism (2%), sepsis (2%), and urinary tract infection (2%). The most common adverse reactions (≥20%) were fatigue (29%), diarrhea (24%), and rash (24%).',
            'Adverse reactions occurring in patients with MSI&#8288;-&#8288;H or dMMR CRC were similar to those occurring in patients with melanoma or NSCLC who received KEYTRUDA as a monotherapy.',
            'In KEYNOTE&#8288;-&#8288;158 and KEYNOTE&#8288;-&#8288;164, adverse reactions occurring in patients with MSI&#8288;-&#8288;H or dMMR cancer were similar to those occurring in patients with other solid tumors who received KEYTRUDA as a single agent.',
            'In KEYNOTE&#8288;-&#8288;859, when KEYTRUDA was administered in combination with fluoropyrimidine- and platinum-containing chemotherapy, serious adverse reactions occurred in 45% of 785 patients. Serious adverse reactions in >2% of patients included pneumonia (4.1%), diarrhea (3.9%), hemorrhage (3.9%), and vomiting (2.4%). Fatal adverse reactions occurred in 8% of patients who received KEYTRUDA, including infection (2.3%) and thromboembolism (1.3%). KEYTRUDA was permanently discontinued due to adverse reactions in 15% of patients. The most common adverse reactions resulting in permanent discontinuation of KEYTRUDA (≥1%) were infections (1.8%) and diarrhea (1.0%). The most common adverse reactions (reported in ≥20%) in patients receiving KEYTRUDA in combination with chemotherapy were peripheral neuropathy (47%), nausea (46%), fatigue (40%), diarrhea (36%), vomiting (34%), decreased appetite (29%), abdominal pain (26%), palmar-plantar erythrodysesthesia syndrome (25%), constipation (22%), and weight loss (20%).', 
            'In KEYNOTE&#8288;-&#8288;590, when KEYTRUDA was administered with cisplatin and fluorouracil to patients with metastatic or locally advanced esophageal or GEJ (tumors with epicenter 1 to 5 centimeters above the GEJ) carcinoma who were not candidates for surgical resection or definitive chemoradiation, KEYTRUDA was discontinued due to adverse reactions in 15% of 370 patients. The most common adverse reactions resulting in permanent discontinuation of KEYTRUDA (≥1%) were pneumonitis (1.6%), acute kidney injury (1.1%), and pneumonia (1.1%). The most common adverse reactions (≥20%) with KEYTRUDA in combination with chemotherapy were nausea (67%), fatigue (57%), decreased appetite (44%), constipation (40%), diarrhea (36%), vomiting (34%), stomatitis (27%), and weight loss (24%).',
            'Adverse reactions occurring in patients with esophageal cancer who received KEYTRUDA as a monotherapy were similar to those occurring in patients with melanoma or NSCLC who received KEYTRUDA as a monotherapy.',
            'In KEYNOTE&#8288;-&#8288;A18, when KEYTRUDA was administered with CRT (cisplatin plus external beam radiation therapy [EBRT] followed by brachytherapy [BT]) to patients with FIGO 2014 Stage III-IVA cervical cancer, fatal adverse reactions occurred in 1.4% of 292 patients, including 1 case each (0.3%) of large intestinal perforation, urosepsis, sepsis, and vaginal hemorrhage. Serious adverse reactions occurred in 30% of patients; those ≥1% included urinary tract infection (2.7%), urosepsis (1.4%), and sepsis (1%). KEYTRUDA was discontinued for adverse reactions in 7% of patients. The most common adverse reaction (≥1%) resulting in permanent discontinuation was diarrhea (1%). For patients treated with KEYTRUDA in combination with CRT, the most common adverse reactions (≥10%) were nausea (56%), diarrhea (50%), vomiting (33%), urinary tract infection (32%), fatigue (26%), hypothyroidism (20%), constipation (18%), decreased appetite and weight loss (17% each), abdominal pain and pyrexia (12% each), hyperthyroidism, dysuria, rash (11% each), and pelvic pain (10%).',
            'In KEYNOTE&#8288;-&#8288;826, when KEYTRUDA was administered in combination with paclitaxel and cisplatin or paclitaxel and carboplatin, with or without bevacizumab (n=307), to patients with persistent, recurrent, or first-line metastatic cervical cancer regardless of tumor PD&#8288;-&#8288;L1 expression who had not been treated with chemotherapy except when used concurrently as a radio-sensitizing agent, fatal adverse reactions occurred in 4.6% of patients, including 3 cases of hemorrhage, 2 cases each of sepsis and due to unknown causes, and 1 case each of acute myocardial infarction, autoimmune encephalitis, cardiac arrest, cerebrovascular accident, femur fracture with perioperative pulmonary embolus, intestinal perforation, and pelvic infection. Serious adverse reactions occurred in 50% of patients receiving KEYTRUDA in combination with chemotherapy with or without bevacizumab; those ≥3% were febrile neutropenia (6.8%), urinary tract infection (5.2%), anemia (4.6%), and acute kidney injury and sepsis (3.3% each).',
            'KEYTRUDA was discontinued in 15% of patients due to adverse reactions. The most common adverse reaction resulting in permanent discontinuation (≥1%) was colitis (1%).',
            'For patients treated with KEYTRUDA, chemotherapy, and bevacizumab (n=196), the most common adverse reactions (≥20%) were peripheral neuropathy (62%), alopecia (58%), anemia (55%), fatigue/asthenia (53%), nausea and neutropenia (41% each), diarrhea (39%), hypertension and thrombocytopenia (35% each), constipation and arthralgia (31% each), vomiting (30%), urinary tract infection (27%), rash (26%), leukopenia (24%), hypothyroidism (22%), and decreased appetite (21%).',
            'For patients treated with KEYTRUDA in combination with chemotherapy with or without bevacizumab, the most common adverse reactions (≥20%) were peripheral neuropathy (58%), alopecia (56%), fatigue (47%), nausea (40%), diarrhea (36%), constipation (28%), arthralgia (27%), vomiting (26%), hypertension and urinary tract infection (24% each), and rash (22%).',
            'In KEYNOTE&#8288;-&#8288;158, KEYTRUDA was discontinued due to adverse reactions in 8% of 98 patients with previously treated recurrent or metastatic cervical cancer. Serious adverse reactions occurred in 39% of patients receiving KEYTRUDA; the most frequent included anemia (7%), fistula, hemorrhage, and infections [except urinary tract infections] (4.1% each). The most common adverse reactions (≥20%) were fatigue (43%), musculoskeletal pain (27%), diarrhea (23%), pain and abdominal pain (22% each), and decreased appetite (21%).',
            'In KEYNOTE&#8288;-&#8288;966, when KEYTRUDA was administered in combination with gemcitabine and cisplatin, KEYTRUDA was discontinued for adverse reactions in 15% of 529 patients with locally advanced unresectable or metastatic biliary tract cancer. The most common adverse reaction resulting in permanent discontinuation of KEYTRUDA (≥1%) was pneumonitis (1.3%). Adverse reactions leading to the interruption of KEYTRUDA occurred in 55% of patients. The most common adverse reactions or laboratory abnormalities leading to interruption of KEYTRUDA (≥2%) were decreased neutrophil count (18%), decreased platelet count (10%), anemia (6%), decreased white blood cell count (4%), pyrexia (3.8%), fatigue (3.0%), cholangitis (2.8%), increased ALT (2.6%), increased AST (2.5%), and biliary obstruction (2.3%).',
            'In KEYNOTE&#8288;-&#8288;017 and KEYNOTE&#8288;-&#8288;913, adverse reactions occurring in patients with MCC (n=105) were generally similar to those occurring in patients with melanoma or NSCLC who received KEYTRUDA as a single agent.',
            'In KEYNOTE&#8288;-&#8288;426, when KEYTRUDA was administered in combination with axitinib, fatal adverse reactions occurred in 3.3% of 429 patients. Serious adverse reactions occurred in 40% of patients, the most frequent (≥1%) were hepatotoxicity (7%), diarrhea (4.2%), acute kidney injury (2.3%), dehydration (1%), and pneumonitis (1%). Permanent discontinuation due to an adverse reaction occurred in 31% of patients; KEYTRUDA only (13%), axitinib only (13%), and the combination (8%); the most common were hepatotoxicity (13%), diarrhea/colitis (1.9%), acute kidney injury (1.6%), and cerebrovascular accident (1.2%). The most common adverse reactions (≥20%) were diarrhea (56%), fatigue/asthenia (52%), hypertension (48%), hepatotoxicity (39%), hypothyroidism (35%), decreased appetite (30%), palmar-plantar erythrodysesthesia (28%), nausea (28%), stomatitis/mucosal inflammation (27%), dysphonia (25%), rash (25%), cough (21%), and constipation (21%).',
            'In KEYNOTE&#8288;-&#8288;564, when KEYTRUDA was administered as a single agent for the adjuvant treatment of renal cell carcinoma, serious adverse reactions occurred in 20% of patients receiving KEYTRUDA; the serious adverse reactions (≥1%) were acute kidney injury, adrenal insufficiency, pneumonia, colitis, and diabetic ketoacidosis (1% each). Fatal adverse reactions occurred in 0.2% including 1 case of pneumonia. Discontinuation of KEYTRUDA due to adverse reactions occurred in 21% of 488 patients; the most common (≥1%) were increased ALT (1.6%), colitis (1%), and adrenal insufficiency (1%). The most common adverse reactions (≥20%) were musculoskeletal pain (41%), fatigue (40%), rash (30%), diarrhea (27%), pruritus (23%), and hypothyroidism (21%).',
            'In KEYNOTE&#8288;-&#8288;868, when KEYTRUDA was administered in combination with chemotherapy (paclitaxel and carboplatin) to patients with advanced or recurrent endometrial carcinoma (n=759), serious adverse reactions occurred in 35% of patients receiving KEYTRUDA in combination with chemotherapy, compared to 19% of patients receiving placebo in combination with chemotherapy. Fatal adverse reactions occurred in 1.6% of patients receiving KEYTRUDA in combination with chemotherapy, including COVID-19 (0.5%) and cardiac arrest (0.3%). KEYTRUDA was discontinued for an adverse reaction in 14% of patients. Adverse reactions occurring in patients treated with KEYTRUDA and chemotherapy were generally similar to those observed with KEYTRUDA alone or chemotherapy alone, with the exception of rash (33% all Grades; 2.9% Grades 3-4).',
            'Adverse reactions occurring in patients with MSI&#8288;-&#8288;H or dMMR endometrial carcinoma who received KEYTRUDA as a single agent were similar to those occurring in patients with melanoma or NSCLC who received KEYTRUDA as a single agent.',
            'Adverse reactions occurring in patients with recurrent or metastatic cSCC or locally advanced cSCC were similar to those occurring in patients with melanoma or NSCLC who received KEYTRUDA as a monotherapy.',
            'In KEYNOTE&#8288;-&#8288;522, when KEYTRUDA was administered with neoadjuvant chemotherapy (carboplatin and paclitaxel followed by doxorubicin or epirubicin and cyclophosphamide) followed by surgery and continued adjuvant treatment with KEYTRUDA as a single agent (n=778) to patients with newly diagnosed, previously untreated, high-risk early-stage TNBC, fatal adverse reactions occurred in 0.9% of patients, including 1 each of adrenal crisis, autoimmune encephalitis, hepatitis, pneumonia, pneumonitis, pulmonary embolism, and sepsis in association with multiple organ dysfunction syndrome and myocardial infarction. Serious adverse reactions occurred in 44% of patients receiving KEYTRUDA; those ≥2% were febrile neutropenia (15%), pyrexia (3.7%), anemia (2.6%), and neutropenia (2.2%). KEYTRUDA was discontinued in 20% of patients due to adverse reactions. The most common reactions (≥1%) resulting in permanent discontinuation were increased ALT (2.7%), increased AST (1.5%), and rash (1%). The most common adverse reactions (≥20%) in patients receiving KEYTRUDA were fatigue (70%), nausea (67%), alopecia (61%), rash (52%), constipation (42%), diarrhea and peripheral neuropathy (41% each), stomatitis (34%), vomiting (31%), headache (30%), arthralgia (29%), pyrexia (28%), cough (26%), abdominal pain (24%), decreased appetite (23%), insomnia (21%), and myalgia (20%).',
            'In KEYNOTE&#8288;-&#8288;355, when KEYTRUDA and chemotherapy (paclitaxel, paclitaxel protein&#8288;-&#8288;bound, or gemcitabine and carboplatin) were administered to patients with locally recurrent unresectable or metastatic TNBC who had not been previously treated with chemotherapy in the metastatic setting (n=596), fatal adverse reactions occurred in 2.5% of patients, including cardio-respiratory arrest (0.7%) and septic shock (0.3%). Serious adverse reactions occurred in 30% of patients receiving KEYTRUDA in combination with chemotherapy; the serious reactions in ≥2% were pneumonia (2.9%), anemia (2.2%), and thrombocytopenia (2%). KEYTRUDA was discontinued in 11% of patients due to adverse reactions. The most common reactions resulting in permanent discontinuation (≥1%) were increased ALT (2.2%), increased AST (1.5%), and pneumonitis (1.2%). The most common adverse reactions (≥20%) in patients receiving KEYTRUDA in combination with chemotherapy were fatigue (48%), nausea (44%), alopecia (34%), diarrhea and constipation (28% each), vomiting and rash (26% each), cough (23%), decreased appetite (21%), and headache (20%).',
        ],
    },
    {
        name: 'Lactation',
        tagType: 'h3',
        items: [
            'Because of the potential for serious adverse reactions in breastfed children, advise women not to breastfeed during treatment and for 4 months after the last dose.',
        ],
    },
    {
        name: 'Pediatric Use',
        tagType: 'h3',
        items: [
            'In KEYNOTE&#8288;-&#8288;051, 173 pediatric patients (65 pediatric patients aged 6 months to younger than 12 years and 108 pediatric patients aged 12 years to 17 years) were administered KEYTRUDA 2&nbsp;mg/kg every 3 weeks. The median duration of exposure was 2.1 months (range: 1 day to 25 months).',
            'Adverse reactions that occurred at a ≥10% higher rate in pediatric patients when compared to adults were pyrexia (33%), leukopenia (31%), vomiting (29%), neutropenia (28%), headache (25%), abdominal pain (23%), thrombocytopenia (22%), Grade 3 anemia (17%), decreased lymphocyte count (13%), and decreased white blood cell count (11%).',
        ],
    },
    {
        name: 'Geriatric Use',
        items: [
            'Of the 564 patients with locally advanced or metastatic urothelial cancer treated with KEYTRUDA in combination with enfortumab vedotin, 44% (n=247) were 65-74 years and 26% (n=144) were 75 years or older. No overall differences in safety or effectiveness were observed between patients 65 years of age or older and younger patients. Patients 75 years of age or older treated with KEYTRUDA in combination with enfortumab vedotin experienced a higher incidence of fatal adverse reactions than younger patients. The incidence of fatal adverse reactions was 4% in patients younger than 75 and 7% in patients 75 years or older.',
        ],
    },
];

const globalSafetyFootnotes =
    `ALT = alanine aminotransferase; 
    AST = aspartate aminotransferase; 
    BT = brachytherapy; 
    BTC = biliary tract cancer;
    cHL = classical Hodgkin lymphoma; 
    CRC = colorectal cancer; 
    CRT = chemoradiotherapy; 
    cSCC = cutaneous squamous cell carcinoma; 
    dMMR = mismatch repair deficient; 
    EBRT = external beam radiation therapy; 
    FIGO = International Federation of Gynecology and Obstetrics; 
    FU = fluorouracil; 
    GEJ = gastroesophageal junction; 
    HER2 = human epidermal growth factor receptor 2; 
    HNSCC = head and neck squamous cell carcinoma; 
    ILD = interstitial lung disease; 
    MCC = Merkel cell carcinoma;
    MSI&#8288;-&#8288;H = microsatellite instability-high; 
    NMIBC = non-muscle invasive bladder cancer; 
    NSCLC = non–small cell lung cancer; 
    PMBCL = primary mediastinal large B-cell lymphoma; 
    TNBC = triple-negative breast cancer.`;

const globalPreview = '<strong>Severe and Fatal Immune-Mediated Adverse Reactions:</strong> KEYTRUDA is a monoclonal antibody that belongs to a class of drugs that bind to either the programmed death receptor-1 (PD&#8288;-&#8288;1) or the programmed death ligand 1 (PD&#8288;-&#8288;L1), blocking the PD&#8288;-&#8288;1/PD&#8288;-&#8288;L1 pathway, thereby removing inhibition of the immune response, potentially breaking peripheral tolerance and inducing immune-mediated adverse reactions. Immune-mediated adverse reactions, which may be severe or fatal, can occur in any organ system or tissue, can affect more than one body system simultaneously, and can occur at any time after starting treatment or after discontinuation of treatment. Important immune-mediated adverse reactions listed here may not include all possible severe and fatal immune-mediated adverse reactions.';

export default {
    list : globalSafetyList,
    footnotes : globalSafetyFootnotes,
    preview : globalPreview
}
