import React from 'react';
import styled from 'styled-components';
import { Text, Heading, NotationBlock } from '../../util/typography';
import ComponentWrapper from '../layout/ComponentWrapper';
import { ExclamationList, BoxedDefinitionList } from '../index'
import { createMarkup } from '../../util/functions';
import { createLocalScaleFunction } from '../../util/functions';

const ContentContainer = styled.div`
    margin-right: ${createLocalScaleFunction(0, 320, 64, 992)};
    margin-left: ${createLocalScaleFunction(0, 320, 64, 992)};
    /* margin-bottom: ${createLocalScaleFunction(-30, 320, -40, 992)}; */
`
const SectionWrapper = styled.div``
const SampleHeading = styled(Heading.H3)`
    margin-bottom: -20px;
`

const ArMgmtItem = props => {
    return (
        <ComponentWrapper>
            <ContentContainer>

                    <SampleHeading dangerouslySetInnerHTML={createMarkup(props.adverseReaction)}/>

                    {/* Monitoring */}
                    <SectionWrapper>
                        {props.monitoringData &&
                            (
                                <ExclamationList 
                                    arTitle={true}
                                    title={props.monitoringTitle || "Monitoring Considerations"}
                                    items={props.monitoringData}
                                />
                            )
                        }
                    </SectionWrapper>
                
                    {/* Management */}
                    <SectionWrapper>
                        {/* grade callout (boxed definition list) */}
                        {props.gradeCalloutData &&
                            (<BoxedDefinitionList 
                                arTitle={true}
                                title={props.optionalMgmtTitleExtension ? `Management ${props.optionalMgmtTitleExtension}` : "Management"}
                                arMgmtBullet={props.arMgmtBullet}
                                items={props.gradeCalloutData}
                            />)
                        }
                        {/* maybe I should map these sections ? */}
                        {props.gradeCalloutDataExtra &&
                            (<BoxedDefinitionList 
                                arTitle={true}
                                title={props.optionalMgmtTitleExtensionExtra ? `Management ${props.optionalMgmtTitleExtensionExtra}` : "Management"}
                                items={props.gradeCalloutDataExtra}
                            />)
                        }

                        {/* exclamation list bullets */}
                        {props.recommendationData &&
                            (<ExclamationList 
                                arTitle={true}
                                title={props.optionalMgmtTitleExtension ? `Management ${props.optionalMgmtTitleExtension}` : "Management"}
                                items={props.recommendationData}
                            />)
                        }

                        {(props.mgmtFootnotes || props.mgmtDefinitions) && <NotationBlock footnotes={props.mgmtFootnotes} definitions={props.mgmtDefinitions} />}

                    </SectionWrapper>

                    {/* Patient Counseling information */}
                    <SectionWrapper>
                        {props.patientCounselingInformation &&
                            (
                                <ExclamationList 
                                    arTitle={true}
                                    title="Patient Counseling Information"
                                    items={props.patientCounselingInformation}
                                />
                            )
                        }
                    </SectionWrapper>

                {(props.footnotes || props.definitions) && <NotationBlock footnotes={props.footnotes} definitions={props.definitions} />}
            </ContentContainer>
        </ComponentWrapper>
    );
};

export default ArMgmtItem;

