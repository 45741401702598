import React from 'react';
import styled from 'styled-components';
import { Heading, Text } from '../../util/typography';
import * as CTA from '../../util/CTA';
import { createLocalScaleFunction } from '../../util/functions';
import ComponentWrapper from '../layout/ComponentWrapper';

// const data = [
//     {
//         title: 'Nam libero tempore cum soluta nobis est',
//         content:
//             ' Alt vero eos accusamus et iusto odio dignissimos ducimus qui blanditiis praesentium voluptaum deleniti atque corrupti quos dolores et quas molestias excepturi sint occaecati cupiditate non provident, similique sunt in culpa qui officia deserunt mollitia animi, id est laborum et dolorum fuga.',
//         actions: [
//             {
//                 label: 'Download PDF', //string, visible text
//                 url: '#', //actual URL
//                 download: true, //boolean, optional
//                 external: false, //boolean, optional
//                 disabled: false, //boolean, optional
//             },
//             {
//                 label: 'View Resources', //string, visible text
//                 url: '#', //actual URL
//                 download: false, //boolean, optional
//                 external: false, //boolean, optional
//                 disabled: false, //boolean, optional
//             },
//         ],
//     },
// ];

const Container = styled.div`
    text-align: center;
`;

const ContentWrapper = styled.div`
    // padding: 40px 20px;
    margin: auto;
`;

const ButtonWrapper = styled.div`
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    padding: 0 20px;

    > button {
        // margin: 10px;
        margin: ${createLocalScaleFunction(3, 320, 10, 952)} 10px;
    }
`;

const Title = styled(Heading.H3)`
    display: block;
    margin-bottom: 14px;
`;

const Content = styled(Text.Body)`
    margin: 0 auto 20px;
    display: block;
    max-width: ${props => props.contentMaxWidth ? `${props.contentMaxWidth}px` : '654px'};
`;

const PrimaryCallOut = props => {

    const componentTheme = (props.theme) || (props.sectionTheme === 'dark') ? 'light' : 'dark';

    return (
        <ComponentWrapper>
            <Container>
                <ContentWrapper>
                    <>
                        <Title as='H2' theme={componentTheme}>{props.title}</Title>
                        <Content contentMaxWidth={props.contentMaxWidth} theme={componentTheme}>{props.content}</Content>
                        {/* Need to revisit component specs post-launch. The intention was for single buttons to always be Primary CTA, with an optional 'secondaryAction' styled as TertiaryButton. However, when the homepage was submitted for binder, a single link was styled as a Tertiary Button. This code is a hack to a match that binder. https://jira.designory.com:8443/browse/CLHMP-1426 */}
                        { (props.primaryAction || props.secondaryAction) && (
                            <ButtonWrapper>
                                { props.primaryAction && (
                                <CTA.PrimaryButton
                                    theme={componentTheme}
                                    {...props.primaryAction}
                                />
                                )}
                                { props.secondaryAction && (
                                    <CTA.TertiaryButton
                                        theme={componentTheme}
                                        {...props.secondaryAction}
                                    />
                                    )
                                }
                            </ButtonWrapper>
                        )}
                    </>
                </ContentWrapper>
            </Container>
        </ComponentWrapper>
    );
};

export default PrimaryCallOut;
