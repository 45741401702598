import React, {useState} from 'react';
import styled from 'styled-components';
import * as CTA from '../../util/CTA';
import * as colors from '../../util/colors';
import { between } from 'polished';
import { Heading, Text } from '../../util/typography';
import { createLocalScaleFunction } from '../../util/functions';

const Container = styled.a`
    text-decoration: none;
    cursor: pointer;
    display: flex;
    flex-direction: column;
    border-radius: 8px;
    border: 1px solid ${colors.pebble};
    background: white;
    transition: 0.3s ease all !important;
    width: 240px;
    position: relative;

    cursor: ${({ disabled }) => (disabled ? 'default' : 'pointer')};
    outline: none;

    &:focus, &:hover {
        border: ${({ disabled }) => (disabled ? `1px solid ${colors.pebble}` : `1px solid transparent`)};
        box-shadow: ${({ disabled }) => (disabled ? 'none' : '0px 0px 10px 5px rgba(38, 38, 38, 0.10)')};
    }

    &[disabled] {
        pointer-events: none;
    }

    @media (min-width: 850px) {
        width: 280px;
    }
    /* &:focus:not(:focus-visible) { outline: none } */
`;

const ContentWrapper = styled.div`
    flex: 1 1 auto;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
`

const CopyContainer = styled.div`
    display: grid;
    gap: 6px;
    padding: 20px 20px 0;
`;

const ImageWrapper = styled.div`
    flex: 0 1 120px;

    filter: ${({ disabled }) => (disabled ? 'grayscale(1)' : '')};
    
    img {
        border-top-left-radius: 7px;
        border-top-right-radius: 7px;
        height: 100%;
        width: -webkit-fill-available;

        /* height: -moz-available; WebKit-based browsers will ignore this.      
        height: -webkit-fill-available;Mozilla-based browsers will ignore this. 
        height: fill-available; */
    }
    
    border-top-left-radius: 7px;
    border-top-right-radius: 7px;
    border-bottom: 1px solid ${colors.pebble};
    background-color: ${colors.cloud};
`;

const Category = styled(Text.Eyebrow)``

const LinkContainer = styled.div`
    padding: 0 20px 15px;
`;

const AltLink = styled.a`
    text-decoration: none;
    font-size: 16px;
    line-height: 24px;
    color: ${colors.blueberry};
`

const Button = styled(CTA.TertiaryButton)`
    margin-top: 3px;
    margin-bottom: ${between('0px', '3.5px', '320px', '1032px')};
`;


{/* 
    Props:
      - url
      - image
      - pdf vs video (PDF default)
      - title
      - body
*/}

const Card = (props) => {

    const [isCardHovered, setIsCardHovered] = useState(false);
    const onCardMouseEnter = () => {
        setIsCardHovered(true);
    };

    const onCardMouseLeave = () => {
        setIsCardHovered(false);
    };

    return (
        <Container {...CTA.setPropsFromURL(props.url)} disabled={props.disabled} data-design-category="button_vd" onMouseEnter={onCardMouseEnter} onMouseLeave={onCardMouseLeave}>
            {props.image &&
                <ImageWrapper disabled={props.disabled}>
                    {typeof props.image === 'function' && props.image()}
                </ImageWrapper>
            }

            <ContentWrapper>
                <CopyContainer>
                    {/* PDF by default, video if specified */}
                    <Category>{props.video ? 'VIDEO' : 'PDF'}</Category>

                    <Heading.H4>{props.title}</Heading.H4>
                    {props.body && <Text.Body>{props.body}</Text.Body>}
                </CopyContainer>

                <LinkContainer>
                
                    { props.altLink && <div><AltLink href={props.altLink.url} target="_blank">{props.altLink.label}</AltLink></div>}
                    <Button
                        forwardedAs="span"
                        label={props.buttonLabel}
                        url={props.url}
                        parentHovered={isCardHovered}
                        {...props.buttonProps}
                        disabled={props.disabled}
                    />
                </LinkContainer>
            </ContentWrapper>
        </Container>
    );
};

export default Card;

