import React from 'react';
import styled from 'styled-components';
import { Heading, Text } from '../../util/typography';

const BarContainer = styled.div`
    margin-bottom: 24px;

    &:last-child {
        margin-bottom: 0;
    }
`;

const Bar = styled.div`
    position: relative;
    height: 20px;
    border-radius: 20px;
    background-image: ${props =>
        props.type === 'keytruda'
            ? `linear-gradient(to left, #6cc04a 0%, #48a446 41%, #36864a 100%);`
            : `linear-gradient(to left, #8c8c8c, #444444 100%);`};
    margin-bottom: 10px;
    width: ${props => props.percent}%;
`;

const Percent = styled(Heading.H3)`
    position: absolute;
    left: 100%;
    margin-left: 10px;
    top: 50%;
    transform: translateY(-50%);
`;

const BarGraph = props => {
    return (
        <BarContainer>
            <Bar percent={props.percent} type={props.type}>
                <Percent>{props.percent}%</Percent>
            </Bar>
            <Text.Body>{props.label}</Text.Body>
        </BarContainer>
    );
};

export default BarGraph;
