//import React, { useEffect, useState } from 'react';
import React from 'react';
import styled from 'styled-components';
import { Heading, Text, WYSIWYG } from '../../../util/typography';
//import { createLocalScaleFunction } from '../../../util/functions';
import * as colors from '../../../util/colors';
import { NotationBlock } from '../../../util/typography';
import ComponentWrapper from '../../layout/ComponentWrapper';
import { componentTheme, createMarkup } from '../../../util/functions';

// TODO: map to global colors with an rgba form hex conversion method
const DARK_HEADER_GRADIENT_FULL = `linear-gradient(90deg, rgba(235,234,235,0) 0%, rgba(235,234,235,1) 12%, rgba(221,221,221,1) 88%, rgba(235,234,235,0) 100%)`;
const LIGHT_HEADER_GRADIENT_FULL = `linear-gradient(90deg, rgba(38,38,38,0) 0%, rgba(38,38,38,1) 12%, rgba(38,38,38,1) 88%, rgba(38,38,38,0) 100%)`;

const DARK_HEADER_GRADIENT_START = `linear-gradient(90deg, rgba(235,234,235,0) 0%, rgba(235,234,235,1) 12%, rgba(235,234,235,1) 100%)`;
const DARK_HEADER_GRADIENT_MIDDLE = `rgba(235,234,235,1)`;
const DARK_HEADER_GRADIENT_END = `linear-gradient(90deg, rgba(235,234,235,1) 0%, rgba(221,221,221,1) 70%, rgba(235,234,235,0) 100%)`;
const LIGHT_HEADER_GRADIENT_START = `linear-gradient(90deg, rgba(38,38,38,0) 0%, rgba(38,38,38,1) 12%, rgba(38,38,38,1) 100%)`;
const LIGHT_HEADER_GRADIENT_MIDDLE = `rgba(38,38,38,1)`;
const LIGHT_HEADER_GRADIENT_END = `linear-gradient(90deg, rgba(38,38,38,1) 0%, rgba(38,38,38,1) 70%, rgba(38,38,38,0) 100%)`;

const ChartTitle = styled(Heading.H4)`
    margin-bottom: 20px;
`;

const ColumnHeading = styled(Heading.Subtitle)`
    display: block;
    margin: 6px 0;
`;

const SwatchSquare = styled.span`
    display: block;
    height: 10px;
    width: 10px;
    margin: 6px 10px 6px 0;
    border-radius: 2px;
    background-color: ${props => (props.color ? props.color : colors.keytrudaGreen)};
    transform: translateY(0.4em);
`;


const Limitation = styled(Text.Body)`
    margin-bottom: 20px;
    display: inline-block;
    strong {
        font-weight: 600;
    }
`;

const HrCiP = styled(Heading.H6)`
    font-size: 18px;   
`;

const CompareTable = styled.div`
    table {
        table-layout: fixed;
        display: inline-table;
        border-spacing: 0;
        border: none;
        width: 100%;
        color: ${props => (props.theme !== 'light' ? colors.ink : colors.white)};
        border-collapse: collapse;
        text-align: left;
        
        th,
        td {
            padding: 20px 2.5%;
            vertical-align: top;
            border-top: 1px solid
                ${props => (props.theme !== 'light' ? colors.pebble : colors.white)};
            border-left: 1px solid
                ${props => (props.theme !== 'light' ? colors.pebble : colors.white)};
            &:first-of-type {
                border-left: none;
                padding-left: 0;
            }
            &:last-of-type {
                padding-right: 0;
            }
            &.center-text {
                text-align:center;
            }
        }

        //IOS Safari has an issue with background-gradient styling on tr elements https://stackoverflow.com/questions/64912199/safari-table-row-gradient-background-repeat-bug
        //so to acheive the design, we set the gradient on the entire table and then apply a solid color to all other rows after the first
        tr { 
            &:last-of-type td {
                border-bottom: 1px solid
                    ${props => (props.theme !== 'light' ? colors.pebble : colors.white)};
            }
        }

        th {
            padding-top: 14px;
            padding-bottom: 14px;
            background: ${props => (props.theme !== 'light' ? colors.mist : colors.ink)};
            background: ${props => props.theme !== 'light' ? DARK_HEADER_GRADIENT_MIDDLE : LIGHT_HEADER_GRADIENT_MIDDLE};
            
            &:first-child {
                background: ${props => props.theme !== 'light' ? DARK_HEADER_GRADIENT_START : LIGHT_HEADER_GRADIENT_START};
            }

            &:last-child {
                background: ${props => props.theme !== 'light' ? DARK_HEADER_GRADIENT_END : LIGHT_HEADER_GRADIENT_END};
            }

            &:only-child {
                background: ${props => props.theme !== 'light' ? DARK_HEADER_GRADIENT_FULL : LIGHT_HEADER_GRADIENT_FULL};
            }
            
            & > span {
                display: flex;
                flex-flow: row wrap;
                align-items: flex-start;

                > ${SwatchSquare} {
                    flex: 0 0 10px;
                }

                > ${ColumnHeading} {
                    flex-basis: calc(calc(340px - 100%) * 999);
                    flex-grow: 1;
                    flex-shrink: 1;
                    hyphens: auto;
                    min-width: 0;
                }
            }
        }
        
    }
`;

// styled component to dynamically set cell to "td" or "th" based on the below
const TableCell = styled.td``;


const AdditionalInformationTitle = styled(Heading.H5)`
    margin-top: 30px;
`;

const AdditionalInformationDescription = styled(WYSIWYG)`
    display: inline-block;
    margin-top: 10px;
`;

const ComparisonTable = props => {
    const theme = componentTheme(true, props.sectionTheme, props.theme);
    
    return (
            <ComponentWrapper>
            
            <CompareTable>
                
                <ChartTitle as='H3' theme={theme} dangerouslySetInnerHTML={createMarkup(props.title)}/>
                
                {props.limitation !== '' && (
                    <Limitation forwardedAs="div" theme={theme} dangerouslySetInnerHTML={createMarkup(
                        props.limitation
                    )}></Limitation>
                )}

                <table>
                    <tbody>
                        {props.data.map((row, rowIndex) => {
                            return (
                                <tr key={`${rowIndex}-row`}>
                                    {row.map((cell, cellIndex) => {
                                        cell.legendSwatchColor = cell.legendTitle && cell.legendTitle.toUpperCase().includes('KEYTRUDA') ? colors.keytrudaGreen : colors.concrete;
                                        return (
                                        <TableCell
                                            key={`${rowIndex}-${cellIndex}-cell`}
                                            as={rowIndex !== 0 ? 'td' : 'th'}
                                            colSpan={cell.colspan || '1'}
                                            rowSpan={cell.rowspan || '1'}
                                            className={(cell.centerText) ? 'center-text' : ''}
                                        >
                                            {cell.legendTitle && cell.legendSwatchColor && (
                                                <span>
                                                    <SwatchSquare
                                                        theme={theme}
                                                        color={cell.legendSwatchColor}
                                                    ></SwatchSquare>
                                                
                                                    <ColumnHeading
                                                        theme={theme}
                                                        dangerouslySetInnerHTML={createMarkup(
                                                            cell.legendTitle
                                                        )}
                                                    ></ColumnHeading>
                                                </span>
                                            )}
                                            {cell.label && (
                                                <Heading.H7
                                                    theme={theme}
                                                    dangerouslySetInnerHTML={createMarkup(cell.label)}
                                                ></Heading.H7>
                                            )}
                                            {cell.dataPoint && (
                                                <Heading.H4
                                                    theme={theme}
                                                    dangerouslySetInnerHTML={createMarkup(
                                                        cell.dataPoint
                                                    )}
                                                ></Heading.H4>
                                            )}
                                            {cell.dataSubPoint && (
                                                <Text.Body
                                                    theme={theme}
                                                    dangerouslySetInnerHTML={createMarkup(
                                                        cell.dataSubPoint
                                                    )}
                                                ></Text.Body>
                                            )}
                                            {cell.hrCiP && (
                                                <HrCiP
                                                    theme={theme}
                                                    dangerouslySetInnerHTML={createMarkup(
                                                        cell.hrCiP
                                                    )}
                                                ></HrCiP>
                                            )}

                                        </TableCell>
                                    )})}
                                </tr>
                            );
                        })}
                    </tbody>
                </table>
                
                {props.additionalInformation && 
                    <>
                        {props.additionalInformation.map((item, index) => 
                            <div key={index}>
                                {item.title && <AdditionalInformationTitle as='H4' theme={theme} dangerouslySetInnerHTML={createMarkup(item.title)}></AdditionalInformationTitle> }
                                {item.text && <AdditionalInformationDescription theme={theme} dangerouslySetInnerHTML={createMarkup(item.text)}></AdditionalInformationDescription> }
                            </div>
                        )}
                    </>
                }
                
                {(props.footnotes || props.definitions) && 
                    <NotationBlock footnotes={props.footnotes} definitions={props.definitions} theme={theme} /> 
                }


            </CompareTable>
        </ComponentWrapper>
    );
};

export default ComparisonTable;
