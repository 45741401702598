import React from 'react';
import styled from 'styled-components';
import * as colors from '../../util/colors';
import { WYSIWYG, NotationBlock, Heading } from '../../util/typography';
import ComponentWrapper from '../layout/ComponentWrapper';
import * as CTA from '../../util/CTA';
import { componentTheme, createMarkup } from '../../util/functions';

const Container = styled.div`
    padding: clamp(20px, 6%, 40px) clamp(20px, calc(-18.96px + 13.91%), 84px) clamp(20px, 6%, 30px);
    border-radius: 8px;
    border: solid 1px ${colors.pebble};
    background-color: ${colors.white};

    > div {
        margin: 0 auto;
        display: flex;
        flex-flow: row wrap;
    }
`;

const IconContainer = styled.div`
    flex: 0 0 60px;
    margin: 0 40px 20px 0;
`;

const ContentContainer = styled.div`
    flex: 1 1 280px;
`;

const Title = styled(Heading.H4)`
    margin-bottom: 10px;
`;

const Description = styled(WYSIWYG)``;

const CTAContainer = styled.div`
    margin-top: clamp(0px, .5vw, 10px);

    > a {
        width: 100%;
    }
`;

// @todo implement theme
const FeaturedResource = props => {
    let theme = componentTheme(true, props.sectionTheme, props.theme);
    return (
        <ComponentWrapper>
            <Container>
                <div>
                    <IconContainer>
                        <img src="/static/images/badge-info@3x.png" alt={props.iconAlt || ""} width={60} height={60} />
                    </IconContainer>
                    <ContentContainer>
                        {props.title && <Title as='H3' dangerouslySetInnerHTML={createMarkup(props.title)} />}
                        {props.description && <Description dangerouslySetInnerHTML={createMarkup(props.description)} />}

                        {props.ctas && props.ctas.length &&
                            <CTAContainer>
                                {props.ctas.map((cta, i) => <CTA.TertiaryButton key={i} {...cta} />)}
                            </CTAContainer>
                        }
                    </ContentContainer>
                </div>
            </Container>
            {(props.footnotes || props.definitions) && <NotationBlock footnotes={props.footnotes} definitions={props.definitions} theme={theme} />}
        </ComponentWrapper>
    );
};

export default FeaturedResource;
