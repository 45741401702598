import React from 'react';
import TypesList from './TypesList';
import styled from 'styled-components';
import * as colors from '../../util/colors';
import { Heading, Text } from '../../util/typography';
import * as CTA from '../../util/CTA';
import { SortMethods } from './SortMethods';
import { createLocalScaleFunction } from '../../util/functions';
import { useDispatch } from 'react-redux';
import {
    closeFilter,
    closeOverlay,
    allowScrolling,
} from '../../store/actions';
import { checkAndTriggerCancerTypeChange } from '../../util/functions';

const NoMatchP = styled(Text.Body)``;

const NoMatchContainer = styled.div`
    background: ${colors.white};
    border: 1px solid ${colors.pebble};
    border-radius: 8px;

    > div {
        max-width: 824px;
        margin: 0 auto;
        padding: ${createLocalScaleFunction(20,280,40,992)} 20px;
        box-sizing: content-box;
        display: flex;
        flex-flow: row wrap;
    }
`;

const Icon = styled.img`
    flex: 0 auto;
    margin: 0 40px 10px 0;
`;

const NoMatchContent = styled.div`
    flex: 1 0 240px;
`;

const NoMatchHeading = styled(Heading.H4)`
    margin-bottom: 10px;
`;



const EmptyTypes = () => {
    const dispatch = useDispatch();

    //@TODO this can probably be consolidated
    const clickHandler = () => {
        dispatch(closeFilter());
        dispatch(closeOverlay());
        dispatch(allowScrolling());
        checkAndTriggerCancerTypeChange();
    }

    return (
        <NoMatchContainer>
            <div>
                <Icon src={'/static/images/badge-magnify.svg'} alt="" width={62} height={62} />
                <NoMatchContent>
                    <NoMatchHeading>
                        Looks like there are no matches for your specific search
                    </NoMatchHeading>
                    <NoMatchP>
                        Tip: If you can’t find what you’re looking for through search, try looking through the full list of approved indications to see whether the indication you’re looking for is listed under a different name.
                    </NoMatchP>
                    <div>
                        <CTA.TertiaryButton label="View Prescribing Information" href="/interactivepi/" external={true} target="_blank" onClick={clickHandler}/>
                    </div>
                </NoMatchContent>
            </div>
        </NoMatchContainer>
    );
};

const TypesView = ({ data, sortMethod, userSelection, onSelectCard }) => {
 
    if (!Object.values(data).length) {
        return <EmptyTypes />
    }

    if (sortMethod === SortMethods.A_Z || sortMethod === SortMethods.SEARCH) {
        return (
            <div id="filternav-tabpanel-1" role="tabpanel" aria-label="Sort by A to Z"><TypesList list={data} userSelection={userSelection} onSelectCard={onSelectCard} /></div>
        );
    } else if (sortMethod === SortMethods.DEFAULT) {
        return (
            <div id="filternav-tabpanel-2" role="tabpanel" aria-label="Sort by Default">
                {Object.keys(data).map((key, index) => (
                    <TypesList
                        key={index}
                        groupName={key}
                        list={data[key]}
                        userSelection={userSelection}
                        onSelectCard={onSelectCard}
                    />
                ))}
            </div>
        );
    }
};

export default TypesView;
