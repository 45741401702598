import React from 'react';
import styled from 'styled-components';
import * as colors from '../../util/colors';
import { Heading } from '../../util/typography';
import { createLocalScaleFunction } from '../../util/functions';
import headerBack from '../../assets/header-back.png';

export const HeaderContainer = styled.div`
    background: linear-gradient(120deg, ${colors.keytrudaGreenWeb}, ${colors.consumerGreen});
`;
export const Header = styled.div`
    background-image: url(${headerBack});
    background-repeat: no-repeat;
    background-position: center;
    background-size: 100% auto;
    max-width: 1032px;
    padding: 0 20px;
    margin: auto;
`;
export const HeaderText = styled(Heading.H1)`
    color: white;
    padding: ${createLocalScaleFunction(20, 320, 40, 992)} 0;
`;

const PageHeader = props => {
    return (
        <HeaderContainer>
            <Header>
                <HeaderText as='H1'>{props.title}</HeaderText>
            </Header>
        </HeaderContainer>
    );
};

export default PageHeader;
