import React from 'react';
import styled from 'styled-components';
import * as colors from '../../util/colors';
import { Heading, WYSIWYG, NotationBlock  } from '../../util/typography';
import ComponentWrapper from '../layout/ComponentWrapper';
import { componentTheme, createMarkup } from '../../util/functions';

const Container = styled.div`
    display: flex;
    flex-flow: row wrap;
    border: solid 1px ${colors.keytrudaGreen};
    border-radius: 8px;
    overflow: hidden;

    > div {
        padding: 30px 20px;
    }
`;

const Title = styled(Heading.H4)`
    margin-bottom: 20px;
`;

const ListContainer = styled.div`
    background-color: ${colors.cloud};
    flex: 1 1 280px;
    min-width: 280px;
    ul {
        margin-left: 0;
        padding-left: 0;
    }

    li {
        list-style: none;
        margin-left: 0;
        padding-left: 0;
        margin-bottom: 1em;
    }
`;

const TextContainer = styled.div`
    flex: 7 1 280px;
    min-width: 280px;
`;

// @todo implement theme
const TestingDefinition = props => {
    let theme = componentTheme(true, props.sectionTheme, props.theme);
    return (
        <ComponentWrapper>
            <Title as='H3' theme={theme} dangerouslySetInnerHTML={{__html: props.title}} />
            <Container>
                <ListContainer>
                    <Heading.H5>
                        {props.listHeading}
                    </Heading.H5>
                    {props.cancerList && 
                        <ul>
                            {props.cancerList.map((item, i) => <li key={i}><Heading.Subtitle dangerouslySetInnerHTML={createMarkup(item)}/></li>)}
                        </ul>
                    }
                </ListContainer>
                <TextContainer>
                    <WYSIWYG>{props.children}</WYSIWYG>
                </TextContainer>
            </Container>
            {(props.footnotes || props.definitions) && <NotationBlock footnotes={props.footnotes} definitions={props.definitions} />}
        </ComponentWrapper>
    );
};

export default TestingDefinition;

export {default as TestingEquation} from './TestingEquation';
