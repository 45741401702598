import React, { useRef, useEffect } from 'react';
import styled from 'styled-components';
import * as CTA from '../../util/CTA';
import { WYSIWYG } from '../../util/typography';
import { createMarkup } from '../../util/functions';
import * as colors from '../../util/colors';

const Container = styled.div`
    display: flex;
    align-content: baseline; 
    flex-wrap: wrap;
`;

const ImageWrapper = styled.div`   
    flex: 0 0 114px; 
    margin-right: 30px;
    
    img {
        width: 100%;
        height: 100%;
        display: block;
    }
`;

const PdfLink = styled(CTA.TertiaryButton)`
    margin-top: auto;
`;

const InlineContent =   styled.div`
    flex: 1 1 200px;

    a {
        &:after {
            align-self: baseline;
            margin-top: 15px;
        }
    }

    .break-text {
        display: none;

        @media(max-width: 800px) {
            display: block;
        }
    }
`;

const StyledWYSIWYG = styled(WYSIWYG)`
    a[disabled] {
        pointer-events: none;
        color: ${colors.slate};
    }
`;

const ResourceItem = ({ theme, label, url, image, resourceBodyText, disabled, allResourcesDisabled }) => {
    const bodyTextRef = useRef(null);

    useEffect(() => {
        // For AT/screenshots: If allResourcesDisabled, disables all links within resourceBodyText
        if (resourceBodyText && allResourcesDisabled) {
            bodyTextRef.current.querySelectorAll('a').forEach(link => {
                link.setAttribute('disabled', 'true');
            });
        }
    }, [bodyTextRef.current])

    return (
            <Container>
                <ImageWrapper>
                    {typeof image === 'function' && image()}
                </ImageWrapper>

                <InlineContent>
                    <PdfLink label={label} url={url} disabled={disabled || allResourcesDisabled} />
                    {resourceBodyText && 
                        <StyledWYSIWYG ref={bodyTextRef} theme={theme} dangerouslySetInnerHTML={createMarkup(resourceBodyText)} />
                    }
                </InlineContent>
            </Container>
    );
};

export default ResourceItem;