import React from 'react';

import IndicationAndSafetyPage from './IndicationAndSafetyPage';

function IndicationAndSafety({ inline_indications_ref, indicationId, safetyDataOverride }) {
    return (
        <div>
            <IndicationAndSafetyPage inline_indications_ref={inline_indications_ref} indicationId={indicationId} safetyDataOverride={safetyDataOverride} />
        </div>
    );
}

export default IndicationAndSafety;
