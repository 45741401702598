import React from 'react';
import styled from 'styled-components';
import { Heading, Text } from '../../util/typography';
import * as CTA from '../../util/CTA';
import * as colors from '../../util/colors';
import { rgba, between } from 'polished';
import { HOMEPAGE_HERO_CONTENT_WRAPPER } from '../../constants/z-index-map';
import { StaticImage } from 'gatsby-plugin-image';
import ComponentWrapper from '../layout/ComponentWrapper';

// @TODO: Make color gradient end point colors configurable with knobs
// @TODO: Make background image configurable with knobs

const IMAGE_DISPLAY_BREAKPOINT = '650px';

const gradient1 = `linear-gradient(
    71deg, 
    ${colors.keytrudaGreenWeb}, 
    ${colors.keytrudaGreenWeb} 33%, 
    ${rgba(colors.keytrudaGreenWeb, 0.02)} 52%, 
    ${rgba(colors.keytrudaGreenWeb, 0)} 72%
)`

const gradient2 = `radial-gradient(
    circle at top right,
    ${colors.keytrudaGreen}, 
    ${colors.keytrudaGreen} 9%, 
    ${rgba(colors.keytrudaGreen, 0)} 31%
)`

const Container = styled.div`
    width: 100%;
    background: ${colors.keytrudaGreenWebForBanner};
    background-image: ${gradient1}, ${gradient2};
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: 1fr;
`;

const ContainerInner = styled.div`
    --contentWidth: 63%;
    --imageWidth: 37%;
    --imageMaxWidth: 330px;
    position: relative;
    width: 100%;
    max-width: 1032px;
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: 1fr;
    margin: auto;
    display: flex;
    flex-direction: column;
    overflow: hidden;

    @media (min-width: ${IMAGE_DISPLAY_BREAKPOINT}) {
        flex-direction: row;
        justify-content: space-between;
        align-items: stretch;
    }
`;

const ContentColumn = styled.div`
    padding: 30px 20px 20px;
    z-index: ${HOMEPAGE_HERO_CONTENT_WRAPPER};

    flex: 0 3 auto;
    display: flex;
    flex-direction: column;
    align-items: stretch;

    @media (min-width: ${IMAGE_DISPLAY_BREAKPOINT}) {
        flex-basis: var(--contentWidth);
        padding: 40px 20px 50px;
        align-items: flex-start;
    }

    span {
        display: block;
    }
`;

const ImageColumn = styled.div`
    flex: 0 1 auto;
    display: flex;
    justify-content: center;
    margin-bottom: -30px;
    margin-top: 5px;
    
    .gatsby-image-wrapper {
        display: block;
        width: 55%;
        max-width: 240px;
    }

    @media (min-width: ${IMAGE_DISPLAY_BREAKPOINT}) {
        flex-basis: var(--imageWidth);
        max-width: var(--imageMaxWidth);
        margin: 0;

        .gatsby-image-wrapper {
            width: 100%;
            max-width: none;
            margin-top: 40px;
            margin-right: ${between('20px', '70px', IMAGE_DISPLAY_BREAKPOINT, '1032px')};
            margin-bottom: 0px;
        }
    }

    @media (min-width: 1032px) {
        .gatsby-image-wrapper {
            margin: 40px 70px 0 0;
        }
    }
`;

const Headline = styled(Heading.H2)`
    color: white;
    margin-bottom: 16px;
    margin-bottom: ${between('10px', '16px', '320px', '1032px')};

    @media (min-width: 1032px) {
        margin-bottom: 16px;
    }
`;

const BodyText = styled(Text.Body)`
    color: white;
    margin-bottom: 20px;
    margin-bottom: ${between('20px', '30px', '320px', '1032px')};

    @media (min-width: 1032px) {
        margin-bottom: 30px;
    }
`;

const ButtonWrapper = styled.div`
    > * {
        margin-bottom: 1rem;
    }
    > *:last-child {
        margin-bottom: 0;
    }

    a span {
        padding-left: 27px;
        padding-right: 27px;
    }

    @media (min-width: ${IMAGE_DISPLAY_BREAKPOINT}) {
        display: flex;
        align-items: center;
        
        > * {
            margin-right: 1rem;
            margin-bottom: 0;
        }
        > *:last-child {
            margin-right: 0;
        }
    }
`

const KeyImage = (props) => {
    const isBrowser = typeof window !== 'undefined';

    // the mobile image will always be the default displayed in order to optimiz for performance
    // let imageToRender = props.mobileImage || props.image;

    // we rely on the client side to render the desktop image instead of the mobile view
    // a more optimial use would be to rely on srcset or the like to do this
    // TODO: optimize responsive images
    // if (isBrowser && !window.matchMedia(`(max-width: ${IMAGE_DISPLAY_BREAKPOINT})`).matches) {  
    //     imageToRender = props.desktopImage || props.image;
    // }

    let imageToRender = props.desktopImage;

    return (
        <>
            {typeof imageToRender === 'function' && imageToRender()}
        </>
    );
}

const Banner = ({ image, headlineText, bodyText, button }) => {
    return (
        <Container>
            <ContainerInner>
                <ContentColumn>
                    <Headline as='H1'>{headlineText}</Headline>
                    <BodyText>{bodyText}</BodyText>
                    <ButtonWrapper>
                        <CTA.PrimaryButton {...button} />
                    </ButtonWrapper>
                </ContentColumn>
                <ImageColumn>
                    <KeyImage
                        // mobileImage={() => <StaticImage placeholder="blurred" src="../../assets/hand-1_mobile@3x.png" alt="KEYTRUDA® (pembrolizumab): Website for Health Care Professionals" objectFit="contain" objectPosition="bottom" />}
                        desktopImage={() => <StaticImage placeholder="blurred" src="../../assets/hand-1_desktop@3x.png" alt="KEYTRUDA® (pembrolizumab): Website for Health Care Professionals" objectFit="contain" objectPosition="bottom" />}
                    />
                    {/* <StaticImage alt="KEYTRUDA® (pembrolizumab): Website for Health Care Professionals" src="../../assets/hand-1_desktop@3x.png" objectFit="contain" objectPosition="bottom" placeholder="blurred"	/> */}
                </ImageColumn>
            </ContainerInner>
        </Container>
    );
};

Banner.defaultProps = {
    headlineText: 'A Key to More Possibilities for Treating Your Patients:',
    bodyText:
        'KEYTRUDA Is Approved to Treat 17 Types of Advanced Cancer. One indication is for the treatment of patients with unresectable or metastatic melanoma.',
    button: { 
        label: 'View Select Indications', 
        url: '/approved-indications/',
        theme: 'light'
    },
};

export default Banner;
