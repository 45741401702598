import React from 'react';
import styled from 'styled-components';
import { WYSIWYG, NotationBlock  } from '../../util/typography';
import ComponentWrapper from '../layout/ComponentWrapper';
import { createMarkup } from '../../util/functions';

const Container = styled(WYSIWYG)`
    > *:first-child {
        margin-top: 0;
        padding-top: 0;
    }
    > *:last-child {
        margin-bottom: 0;
        padding-bottom: 0;
    }
`;

// @todo implement theme
const TextBlock = props => {
    return (
        <ComponentWrapper>
            <Container dangerouslySetInnerHTML={createMarkup(props.children)} />
            {(props.footnotes || props.definitions) && <NotationBlock footnotes={props.footnotes} definitions={props.definitions} />}
        </ComponentWrapper>
    );
};

export default TextBlock;
