import React from 'react';
import styled from 'styled-components';
import { useEffect,useState } from 'react';
import {
    TieredCTAGrid,
    TriageList
} from '../index';
import Card from '../TieredCTAGrid/Card';
import * as CTA from '../../util/CTA';
import { between } from 'polished';
import * as colors from '../../util/colors';
import CustomHomepageHero from './CustomHomepageHero';
import { IMAGE_DISPLAY_BREAKPOINT } from './CustomHomepageHero';
import { useGroup } from '../../util/hooks';
import { createMarkup } from '../../util/functions';

const Section = styled.section`
    background-color: ${colors.mist};
    padding: 30px 0 40px;
`;

const Container = styled.div`
    margin: 0 auto;
    max-width: ${992 + 40}px;
    padding: 0 20px;
`;

const GridContainer = styled.div`
    display: grid;
    grid-template-columns: calc(67% - 10px) calc(33% - 10px);
    grid-template-areas:
        "hero card"
        "triage triage";
    column-gap: 20px;
    margin-bottom: -20px;
    
    @media (max-width: ${IMAGE_DISPLAY_BREAKPOINT - 1}px) {
        grid-template-areas:
        "hero hero"
        "triage triage"
        "card card";
    }
`;

const HeroWrapper = styled.div`
    grid-area: hero;
    margin-bottom: 20px;
`;

const CardWrapper = styled.div`
    grid-area: card;
    display: flex;
    align-items: stretch;
    margin-bottom: 20px;
`;

const TriageWrapper = styled.div`
    grid-area: triage;
`;

const HeroCtaCustom = styled.a.attrs(props => ({
    className: `${props.$cardPrefix}-card-container`
}))`
    text-decoration: none;
    cursor: pointer;
    display: flex;
    justify-content:center;
    align-items: flex-start;
    flex-wrap: wrap;
    border-radius: 8px;
    background: radial-gradient(130.27% 103.54% at 24.71% 0%, #1B7A7C 0%, #0C7397 15.1%, #36864A 68.31%, #2C6D3C 100%);
    transition: 0.3s;
    -webkit-box-flex: 1;
    flex-grow: 1;
    align-content: center;
    padding: 30px 20px;
    flex-direction: column;
    align-items: center;
    &:hover {
        border-color: ${colors.white};
        box-shadow: 0 4px 20px 0 rgba(0, 0, 0, 0.16);
    }
`
const LinkContainer = styled.div`
    margin-top: -20px;
    padding-top: 6px;
    display:flex;
    justify-content:center;
    @media (max-width: 992px) {
        width: 100%;
        align-items: stretch;
    }
    .tenyearspan span{
        padding: unset;
    }
`;

const TertiaryButton = styled(CTA.TertiaryButton).attrs(props => ({
    className: `${props.cardPrefix}-button`
}))`

    &:after {
    
        -webkit-flex: 0 0 20px;
    -ms-flex: 0 0 20px;
    flex: 0 0 20px;
    display: inline-block;
    content: '';
    background-image: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxMC40MSIgaGVpZ2h0PSIxMC4xMyIgZmlsbD0iIzAwNmVhZCI+CiAgICA8cGF0aCBkPSJNNS4zNCAxMC4xM0wzLjkzIDguNzFsMi42NS0yLjY1SDB2LTJoNi41OEwzLjkzIDEuNDEgNS4zNCAwbDUuMDcgNS4wNi01LjA3IDUuMDd6IiBmaWxsPSIjMDA2ZWFkIiAvPgo8L3N2Zz4K), linear-gradient(to right, #ffffff 0%, #ffffff 50%, #ffffff calc(50% + 1px), #ffffff 100%);
    background-repeat: no-repeat;
    background-position: center, left;
    background-size: 10px 10px, 200% 100%;
    border-radius: 50%;
    width: 20px;
    height: 20px;
    margin-left: 6px;
    
    }

    color: #ffffff;
    &:hover, &:active, &:focus{color:#ffffff}
    margin-bottom: ${between('0px', '3.5px', '320px', '1032px')};
    
    @media (max-width: 320px) {
        margin-bottom: 0px;
        }
        
        @media (min-width: 1032px) {
            margin-bottom: 3.5px;
            }
    
`;

const CustomText = styled.p`
  color:#fff;
  text-align:center;
  font-family:proxima-nova;
  font-size:12px;
  max-width:212px;
  font-style:normal;
  font-weight:500;
  line-height:15px;
  margin-top:0px
`;
// @todo implement theme
export const cancerTypeCustomContent = {
    'cer' : {
        headlineText: 'Today, one of the 40 indications for which KEYTRUDA is approved is for the treatment of patients with unresectable or metastatic melanoma.',
    },
    'nsc' : {
        headlineText: 'Today, one of the 40 indications for which KEYTRUDA is approved is for the treatment of patients with unresectable or metastatic melanoma.',
    },
    'bre' : {
        headlineText: 'Today, one of the 40 indications for which KEYTRUDA is approved is for the treatment of patients with unresectable or metastatic melanoma.',
    },
    'bla' : {
        headlineText: 'Today, one of the 40 indications for which KEYTRUDA is approved is for the treatment of patients with unresectable or metastatic melanoma.',
    },
    'mel' : {
        headlineText: 'Today, one of the 40 indications for which KEYTRUDA is approved is for the treatment of patients with unresectable or metastatic melanoma.',
    },
    'hns' : {
        headlineText: 'Today, one of the 40 indications for which KEYTRUDA is approved is for the treatment of patients with unresectable or metastatic melanoma.',
    },
    'end' : {
        headlineText: 'Today, one of the 40 indications for which KEYTRUDA is approved is for the treatment of patients with unresectable or metastatic melanoma.',
    },
    'ren' : {
        headlineText: 'Today, one of the 40 indications for which KEYTRUDA is approved is for the treatment of patients with unresectable or metastatic melanoma.',
    }
};
const CustomHomepageGrid = ({ tieredCtaRows, heroCta,heroCtaCustom  }) => {
    const group = useGroup();
    const [heroContent, setHeroContent] = useState(() => {
        if (group) {
            return cancerTypeCustomContent[group];
        }
        // else return defaultContent;
        else return '';
    });
    useEffect(() => {
        /**
         * An additional check to make sure the group string exists within cancerTypeCustomContent (although
         * because this component only exists on the homepage, useGroup() should have already validated against this)
         */
        if (group && cancerTypeCustomContent[group]) {
            setHeroContent(cancerTypeCustomContent[group]);
        }
    }, [group])

    /**
     * Render default content on the server, and force React to fully re-render on the client side by updating its key. For SEO and
     * performance, we want to SSR the default — the downside is that custom pages will flash default before loading custom content.
     * https://github.com/gatsbyjs/gatsby/discussions/17914 — see "Two pass rendering" (instead of https://www.joshwcomeau.com/react/the-perils-of-rehydration/)
     */
    // const [hasMounted, setHasMounted] = useState(false);
    // useEffect(() => {
    //     setHasMounted(true);
    // }, []);

    const [isCardHovered, setIsCardHovered] = useState(false);
    const onCardMouseEnter = () => {
        setIsCardHovered(true);
    };

    const onCardMouseLeave = () => {
        setIsCardHovered(false);
    };
    
    return (
        <Section>
            <Container>
                <GridContainer>
                    <HeroWrapper>
                        <CustomHomepageHero />
                    </HeroWrapper>
                    <CardWrapper>
                    {heroCta && !heroCtaCustom && (
                        <Card 
                        rowTier="2"
                        verticalCenter
                        rowKey={-1}
                        colKey={-1}
                        {...heroCta}
                        />
                    )}
                    {heroCtaCustom && !heroCta && (
                        <HeroCtaCustom href={heroCtaCustom.url} target='_blank' onMouseEnter={onCardMouseEnter} onMouseLeave={onCardMouseLeave} data-design-category="button_vd">
                            <img src={heroCtaCustom.imageUrl} alt={heroCtaCustom.imageAlt} style={{
                                paddingBottom: '16px',
                                width: '200px',
                                height: 'auto'
                            }} quality="90" />
                            <CustomText>Initial FDA accelerated approval in certain patients with unresectable or metastatic melanoma on <span style={{textWrap:'nowrap'}}>September 4, 2014.</span></CustomText>
                            <CustomText style={{maxWidth:'200px'}}>{heroContent.headlineText} </CustomText>
                            <LinkContainer>
                            <TertiaryButton
                                    forwardedAs="span"
                                    label={heroCtaCustom.buttonLabel}
                                    parentHovered={isCardHovered}
                                    {...heroCtaCustom.buttonProps}
                                    className="tenyearspan"
                                    style={{
                                        paddingTop: '16px'
                                    }}
                                />
                            </LinkContainer>
                        </HeroCtaCustom>
                    )}
                    </CardWrapper>
                    <TriageWrapper>
                        <TriageList listType="efficacy" homepage />
                    </TriageWrapper>
                </GridContainer>
                <TieredCTAGrid rows={tieredCtaRows} homepage />
            </Container>
        </Section>
    );
};

export default CustomHomepageGrid;
