import React from 'react';
import styled from 'styled-components';
import globalSafetyData from './data/globalSafety';
import { Heading, Text } from '../../util/typography';
import * as Colors from '../../util/colors';
import * as ISI from './styled';

import { createLocalScaleFunction, createMarkup } from '../../util/functions';
import * as List from '../../util/typography/List';

const DrawerContentTitle = styled(Text.Footnote)`
    margin: 16px 0;
    line-height: 22px;
    text-transform: uppercase;
    display: block;

    &:first-child {
        margin-top: 0;
    }
`;
const DrawerList = styled(List.Bullet)`
    margin-top: 0;

    > li:first-child {
        margin-top: 0;
    }   

    &:last-child {
        margin-bottom: 0;
    }
`;

const FootnoteContainer = styled.div`
    padding: 0 0 ${createLocalScaleFunction(20, 280, 30, 824)};
    color: ${Colors.slate};
`;

const SafetyListItem = ({ item }) => {
    if (item.items) {
        return (
            <List.Bullet>
                {item.items.map((subitem, i) => (
                    <li key={i} dangerouslySetInnerHTML={createMarkup(subitem)} />
                ))}
            </List.Bullet>
        );
    } else {
        return <li dangerouslySetInnerHTML={createMarkup(item)} />;
    }
};


const SafetyList = ({ columns = 1, scrollable_ref, safetyDataOverride }) => {

    const data = safetyDataOverride || globalSafetyData;
    
    return (
        <ISI.InformationListContainer ref={scrollable_ref}>
            {data.list.map((item, i) => {
                return (
                    <ISI.InformationItem columns={columns} key={i}>
                        <header>
                            <ISI.InformationItemLabel dangerouslySetInnerHTML={createMarkup(item.name)} {...(Boolean(item.tagType) && { as: item.tagType })} />
                        </header>
                        <div>
                            {item.items.map((child, i) => {
                                return (
                                    <div key={i}>
                                        {child.name && (
                                            <DrawerContentTitle dangerouslySetInnerHTML={createMarkup(child.name)} {...(Boolean(child.tagType) && { as: child.tagType })} />
                                        )}

                                        <DrawerList>
                                            <SafetyListItem item={child} />
                                        </DrawerList>
                                    </div>
                                );
                            })}
                        </div>
                    </ISI.InformationItem>
                );
            })}

            <FootnoteContainer>
                <Text.Footnote dangerouslySetInnerHTML={createMarkup(data.footnotes)} />
            </FootnoteContainer>
            <footer>
                <Heading.H4>
                    Before prescribing KEYTRUDA® (pembrolizumab), please read the accompanying{' '}
                    <a
                        data-design-category="content_link_vd"
                        href="/interactivepi/"
                        target="_blank"
                    >
                        Prescribing Information
                    </a>
                    . The{' '}
                    <a
                        data-design-category="content_link_vd"
                        href="http://www.merck.com/product/usa/pi_circulars/k/keytruda/keytruda_mg.pdf"
                        target="_blank"
                        rel="noopener noreferrer"
                        aria-label="Medication Guide"
                    >
                        Medication Guide
                    </a>{' '}
                    also is available.
                </Heading.H4>
            </footer>
        </ISI.InformationListContainer>
    );
};

export default SafetyList;
