import React, { useState, memo, useEffect, useRef } from "react";
import styled from 'styled-components';
import * as CTA from '../../util/CTA';
import * as colors from '../../util/colors';
import { Heading, Text } from '../../util/typography';
import { cardSlider, fadeIn, slideFromLeft, slideIn, slideUp, slideFromRight } from "../../util/keyframes";

// TODO:
//   - refactor pagination nav
//   - slide transition effects

const Wrapper = styled.div`
    max-width: 992px;
    margin: auto;
    height: max-content;
`

const SlideWrapper = styled.div`
    width: 100%;

    @media (min-width: 650px) {
      display: flex;
      flex-direction: row-reverse;
      max-height: 444px;
    }
`

const ImageWrapper = styled.a`
  flex: 1.33;
  width: 100%;
  min-height: 250px; 
  display: flex;
  align-items: center;
`

const Image = styled.div`
  background-image: ${image => `url(${image.image})`}; 
  height: 350px;
  width: 100%;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  animation: none;
  &.animate {
    animation: ${cardSlider} 0.2s 0.2s;
    animation-fill-mode: both;
  }
  border-radius: 8px;

  @media (max-width: 650px) {
    height: 250px;
    width: auto;
  }
`

const SlideContent = styled.div`
  flex: 1;

  @media (min-width: 650px) {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding-right: 40px;
  }
`

const ContentWrapper = styled.div`
  flex: 1 1 auto;
  width: 98%;
  margin: 60px 20px 26px;
  animation: none;
  &.animateFromLeft {
    animation: ${slideFromLeft} 0.2s 0.2s;
    animation-fill-mode: both;
  }
  &.animateFromRight {
    animation: ${slideFromRight} 0.2s 0.2s;
    animation-fill-mode: both;
  }

  @media (max-width: 650px) {
    margin: 40px 20px 30px;
  }
`

const Title = styled(Heading.H3)`
  margin-bottom: 16px;
`

const CtaWrapper = styled.div`
  margin-top: 5px;
`

const Navigation = styled.div`
    display: flex;
    align-items: center;
    /* justify-content: center; */
    /* margin-bottom: 72px; */

    @media (max-width: 650px) {
      margin-bottom: 40px;
    }
`

const LeftButton = styled(CTA.SliderButton)`
    transform: rotate(180deg);
    &::after {
      margin-left: 2px; //visually centers left-carret (per design direction)
    }
`

const PaginationWrapper = styled.div`
    display: grid;
    grid-auto-flow: column;
    margin: 0 52px;
    gap: 20px;

    @media (max-width: 650px) {
      gap: 8px;
    }
`

const Dot = styled.div`
    height: 8px;
    width: 8px;
    background-color: ${colors.pebble};
    border-radius: 50%;

    &.active {
      background-color:  ${colors.blueberry};
      box-shadow: 0px 0px 9px 1px ${colors.blueberry};
    }
`

const CardSlider = ({ slides }) => {

  const [currentIndex, setCurrentIndex] = useState(0);
  const [activeId, setActiveId] = useState({ activeSlide: 0 })
  const [prevDisabled, setPrevDisabled] = useState(true)
  const [nextDisabled, setnextDisabled] = useState(false)
  const { activeSlide } = activeId
  const [goToPrevSlide, setGoToPrevSlide] = useState(false);
  const [goToNextSlide, setGoToNextSlide] = useState(false);
  const imageRef = useRef(null);

  useEffect(() => {
    if(imageRef && imageRef.current){
        imageRef.current.classList.toggle('animate');
      }
  }, [currentIndex])

  useEffect(() => {

    // check if current index is 0 then reset to proper arrow states
    if (currentIndex == 0) {
      setPrevDisabled(true)
      setnextDisabled(false)
    } else {
      setPrevDisabled(false)
    }

    // if currentIndex is the last slide, disable next arrow
    if (currentIndex == (slides.length - 1)) {
      setnextDisabled(true)
    }
  });

  const goToPrevious = () => {
    const isFirstSlide = currentIndex === 0;
    const newIndex = isFirstSlide ? slides.length - 1 : currentIndex - 1;
    setCurrentIndex(newIndex);
    setGoToPrevSlide(true);
    setGoToNextSlide(false);

    setActiveId({
      activeSlide: activeSlide === 0 ? slides.length - 1 : activeSlide - 1
    })
  };

  const goToNext = () => {
    const isLastSlide = currentIndex === slides.length - 1;
    const newIndex = isLastSlide ? 0 : currentIndex + 1;
    setCurrentIndex(newIndex);
    setGoToPrevSlide(false);
    setGoToNextSlide(true);

    setActiveId({
      activeSlide: activeSlide === slides.length - 1 ? 0 : activeSlide + 1
    })
  };

  // TODO: consolidate
  const slideData = {
    title: `${slides[currentIndex].title}`,
    text: `${slides[currentIndex].text}`,
    linkLabel: `${slides[currentIndex].linkLabel}`,
    ctaUrl: `${slides[currentIndex].ctaUrl}`,
    img: `${slides[currentIndex].image}`,
    alt: `${slides[currentIndex].alt}`,
  }

  return (
    <Wrapper>
      <SlideWrapper>
        <ImageWrapper href={slideData.ctaUrl}>
          <Image image={slideData.img} ref={imageRef}  role="img" aria-label={slideData.alt}/>
        </ImageWrapper>

        <SlideContent>
          <ContentWrapper className={goToPrevSlide ? 'animateFromLeft' : goToNextSlide ? 'animateFromRight' : ''}>
            {slideData.title && <Title>{slideData.title}</Title>}
            {slideData.text && <Text.Body>{slideData.text}</Text.Body>}

            <CtaWrapper>
              <CTA.TertiaryButton
                label={slideData.linkLabel}
                url={slideData.ctaUrl}
              />
            </CtaWrapper>
          </ContentWrapper>
          {slides.length > 1 &&
            <Navigation>
              <LeftButton onClick={goToPrevious} disabled={prevDisabled} />
              <PaginationWrapper>
                {slides.map((slide, i) => (
                  <Dot key={i} className={activeSlide === i ? 'active' : ''} />
                ))}
              </PaginationWrapper>

              <CTA.SliderButton onClick={goToNext} disabled={nextDisabled} />
            </Navigation>
          }
        </SlideContent>
      </SlideWrapper>
    </Wrapper>
  );
};

// not sure if it is worth/needed to use memo here; leaving for now though
export default memo(CardSlider);
