import React from 'react';
import styled from 'styled-components';
import ComponentWrapper from '../layout/ComponentWrapper';
import { createMarkup } from '../../util/functions';
import { Heading, WYSIWYG } from '../../util/typography';
import * as colors from '../../util/colors';
import { createLocalScaleFunction } from '../../util/functions';
import { NotationBlock } from '../../util/typography';

const Container = styled.div`  
  border-radius: 8px;
  border: solid 1px ${colors.pebble};
  background-color: ${colors.white};
  padding: ${createLocalScaleFunction(0, 320, 20, 992)} 0;

  display: flex;
  flex-wrap: wrap;
  /* https://heydonworks.com/article/the-flexbox-holy-albatross/ */
  --margin: 1rem;
  --modifier: calc(50rem - 100%);

  /* margin: calc(var(--margin) * -1); */
  align-items: center;
  justify-content: center;

  > * {
    max-width: 100%;
    flex-basis: calc(var(--modifier) * 999);
    margin: var(--margin);
}
`;

const QrWrapper = styled.div`    
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    text-align: center;
    padding: 0 20px;
    flex-grow: 0.5;
`;

const QrContainer = styled.div`    
    width: 180px;
    height: 180px;
    margin-top: ${createLocalScaleFunction(20, 320, 0, 992)};
    margin-left: 0;
    margin-right: 0;
    margin-bottom: 10px;
    display: flex;
    justify-content: center;
    align-items: center;

    & img {
        width: 168px;
    }
`;

const QrDescription = styled(Heading.H7)``;

const InstructionalTitle = styled(Heading.H4)`
    margin-bottom: 16px;
`;

const ContentContainer = styled.div`
    padding: ${createLocalScaleFunction(24, 320, 0, 992)} ${createLocalScaleFunction(20, 320, 0, 992)} 0 ${createLocalScaleFunction(20, 320, 0, 992)};
    flex-grow: 2;
`;

const ImageContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 20px;
    flex-grow: 0.5;
    flex-basis: 150px;
    margin:0;
`;

const QrComponent = props => {

    return (
        <ComponentWrapper>
            <Container>

                <QrWrapper>
                    <QrContainer>
                        {typeof props.image === 'function' && props.QRimage()}
                    </QrContainer>
                    <QrDescription  dangerouslySetInnerHTML={createMarkup(props.qrDescription)}/>
                </QrWrapper>

                <ContentContainer>
                    <InstructionalTitle as='H3' theme={props.theme} dangerouslySetInnerHTML={{ __html: props.title }}/>
                    {props.text && <WYSIWYG theme={props.theme} dangerouslySetInnerHTML={createMarkup(props.text)} />}
                </ContentContainer>

                <ImageContainer>
                    {typeof props.image === 'function' && props.image()}
                </ImageContainer>

            </Container>
            {(props.footnotes || props.definitions) && <NotationBlock footnotes={props.footnotes} definitions={props.definitions} theme={props.theme} />}
        </ComponentWrapper>
    );
};

export default QrComponent;