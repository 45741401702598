import React from 'react';
import styled from 'styled-components';
import * as colors from '../../util/colors';
import { Heading, NotationBlock, Footnote } from '../../util/typography';
import ComponentWrapper from '../layout/ComponentWrapper';
import { componentTheme, createMarkup } from '../../util/functions';

const ContentWrapper = styled.div`
    display: flex;
    flex-wrap: wrap;
    --multiplier: calc(640px - 100%);
    margin-bottom: 20px;
    width: 100%;
`;


const Section = styled.div`
    display: flex;
    flex-flow: column nowrap;
    border: solid 1px ${colors.keytrudaGreen};
    border-radius: 8px;
    overflow: hidden;
    width: 100%;
    margin-bottom: 20px;

    &:last-child {
        margin-bottom: 0;
    }
`;

// const Title = styled(Heading.H2)`
//     margin-bottom: 30px;
// `;

const SectionHeader = styled.div`
    background-color: ${colors.cloud};
    padding: 10px 20px;
    border-bottom: solid 1px ${colors.keytrudaGreen};
`;

const SectionTitle = styled(Heading.H5)`
    margin-bottom: 0px;
`;

const SectionContent = styled(Heading.Subtitle)`
    background-color: ${colors.white};
    padding: clamp(20px, 4%, 26px) 20px;

    ul {
        list-style: none;
        margin: 0;
        padding: 0;
        columns: 2;
        column-width: 240px;

        li {
            padding-left: 0;
            margin-left: 0;
            margin-bottom: 1em;

            &:last-child {
                margin-bottom: 0;
            }
        }
    }
`;

const NCCNReferences = styled.div`
    margin-top: 1em;
`;

const NCCNMultitumorCallout = props => {
    let theme = componentTheme(true, props.sectionTheme, props.theme);

    return (
        <>
            <ComponentWrapper>
                {/* <TitleWrapper>
                        <Title theme={data.theme}>{data.title}</Title>
                    </TitleWrapper> */}
                <ContentWrapper>
                    {props.sections && 
                        props.sections.map(({title, tumorTypeList}) => 
                            <Section>
                                <SectionHeader>
                                    <SectionTitle dangerouslySetInnerHTML={createMarkup(title)}/>
                                </SectionHeader>
                                <SectionContent>
                                    <ul>
                                        {tumorTypeList.map(({label, value}) => 
                                            <li dangerouslySetInnerHTML={createMarkup(`${label}`)} />
                                            // <li dangerouslySetInnerHTML={createMarkup(`${label}: ${value}`)} />
                                        )}
                                    </ul>
                                </SectionContent>
                            </Section>
                        )
                    }
                </ContentWrapper>
             
                {(props.footnotes || props.definitions) && <NotationBlock footnotes={props.footnotes} definitions={props.definitions} theme={theme} />}
                {/* references are ONLY included in the display of NCCN, for regulatory/compliance reasons. All other references should be manually entered in the page-wide ReferencesBlock at the bottom of a page */}
                {props.references && 
                    <NCCNReferences>
                        {props.references.map((item, index) => {
                            return <Footnote key={index} label={item.label} text={item.text} theme={props.theme} />;
                        })}
                    </NCCNReferences>
                }
            </ComponentWrapper>
        </>
    );
};

export default NCCNMultitumorCallout;
