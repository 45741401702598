import React from 'react';
import styled from 'styled-components';
import { Heading, Text } from '../../util/typography';
import { createLocalScaleFunction } from '../../util/functions';
import ComponentWrapper from '../layout/ComponentWrapper';
import * as colors from '../../util/colors';
import BarGraph from './BarGraph';

const ChartTitle = styled(Heading.H4)`
    margin-bottom: 20px;
`;

const Container = styled.div`
    border: 1px solid ${colors.silver};
    border-radius: 8px;
    background-color: ${colors.white};
    padding: 20px ${createLocalScaleFunction(20, 320, 65, 992)}
        ${createLocalScaleFunction(30, 320, 40, 992)} ${createLocalScaleFunction(20, 320, 145, 992)};
`;

const ChartLabel = styled(Heading.H7)`
    text-align: center;
    margin-bottom: 20px;
    max-width: calc(100% - ${createLocalScaleFunction(0, 320, 115, 992)});
`;

const ScaleNum = styled(Text.Eyebrow)`
    position: absolute;
    flex: 0 0 auto;
    z-index: 1;
    text-align: center;
    display: block;
    background-color: ${colors.white};
    padding: 0 6px;
    color: ${colors.pebble};
`;

const ScaleNum0 = styled(ScaleNum)`
    padding-left: 0;
    left: 0;
`;

const ScaleNum50 = styled(ScaleNum)`
    left: 50%;
    transform: translateX(-50%);
`;

const ScaleNum100 = styled(ScaleNum)`
    padding-right: 0;
    right: 0;
`;

const ScaleContainer = styled.div`
    margin-bottom: 35px;
    position: relative;
    height: 16px;
`;

const NumContainer = styled.div`
    position: relative;
`;

const ScaleBar = styled.span`
    position: absolute;
    top: 50%;
    left: 0;
    right: 0;
    width: 100%;
    height: 1px;
    background-color: ${colors.mist};
    flex: 1 1 auto;
    z-index: 0;
`;

const BarWrapper = styled.div``;

const GraphsContainer = styled.div`
    max-width: calc(100% - ${createLocalScaleFunction(60, 320, 85, 992)});
`;

const BarChart = props => {
    let data = props.data;

    return (
        <ComponentWrapper>
            <ChartTitle as='H3' theme={data.theme}>{data.chartTitle}</ChartTitle>
            <Container>
                <ChartLabel>{data.chartLabel}</ChartLabel>
                <GraphsContainer>
                    <ScaleContainer>
                        <NumContainer>
                            <ScaleNum0>0%</ScaleNum0>
                            <ScaleNum50>50%</ScaleNum50>
                            <ScaleNum100>100%</ScaleNum100>
                        </NumContainer>
                        <ScaleBar />
                    </ScaleContainer>
                    <BarWrapper>
                        {data.barGraphs.map((item, index) => {
                            return (
                                <BarGraph
                                    percent={item.percent}
                                    label={item.label}
                                    type={item.type}
                                    key={index}
                                />
                            );
                        })}
                    </BarWrapper>
                </GraphsContainer>
            </Container>
        </ComponentWrapper>
    );
};

export default BarChart;
