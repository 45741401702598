import React from 'react';
import styled from 'styled-components';
import { Text } from '../../util/typography';
import * as CTA from '../../util/CTA';
import * as colors from '../../util/colors';
import ChatbotLaunchButton from '../Chatbot/ChatbotLaunchButton';
import { UTILITY_NAV_MENU } from '../../constants/z-index-map';
import { NAVIGATION_BREAKPOINT } from '../../constants/layout';
import { useDispatch } from 'react-redux';
import {
    openGeneralModal,
    preventScrolling,
} from '../../store/actions';

const bp = NAVIGATION_BREAKPOINT;

const UtilityMenu = styled.nav`
    background-color: ${colors.charcoal};
    width: 100%;
    pointer-events: auto;
    z-index: ${UTILITY_NAV_MENU};
    position: relative;
`;

const UtilityMenuContainer = styled.div`
    max-width: 1032px;
    box-sizing: border-box;
    margin: 0 auto;
    padding: 0 10px;
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
`;

const UtilityMenuList = styled.ul`
    flex: 1 auto;
    list-style: none;
    margin: 0 -5px;
    padding: 0;
    display: ${({ hideOnSmallScreen }) => (hideOnSmallScreen ? 'none' : 'flex')};
    justify-content: space-between;
    align-items: center;

    @media (min-width: ${bp}) {
        display: flex;
        margin: 0;
    }

    &:last-child {
        flex: 1 100%;
        justify-content: flex-end;
    }
`;

const UtilityMenuItem = styled.li`
    flex: 0 auto;
    margin: 0 5px;

    @media (min-width: ${bp}) {
        margin: 0 10px;
    }
`;

const StyledMenuLink = styled.a.attrs(props => ({
    'data-design-category':
        props.dataDesignCategory || props['data-design-category'] || 'top_nav_vd',
}))`
    display: block;
    white-space: nowrap;
    text-decoration: none;
    cursor: pointer;
`;

const MenuLabel = styled(Text.Button)`
    color: ${colors.white};
    font-size: 12px;
    padding: 0.9em 0 0.8em;
    display: inline-block;
    background: none;
`;

const UtilityTertiaryButton = styled(CTA.TertiaryButton)`
    > span {
        font-size: 12px;
    }
`;

export const medicalInformationModal = {
    copy: 'You will now be leaving this site and will be redirected to the Merck Medical Portal. The Merck Medical Portal is for health care professionals in the US and its territories only.',
    buttons: [
        {
            label: 'Continue',
            href: 'https://www.merckmedicalportal.com/s/medical-information?AN=P8K6FQyyRYeLD&HCP=ITEyPIfQXRnA2DA&RC=RplraQ7prRPckd',
            external: true
        },
    ],
};

const UtilityNavigation = ({ chatDisabled, utility_nav_ref }) => {

    const dispatch = useDispatch();
    // For an internal link that opens in a new tab, use href instead of url and add target: '_blank'
    // Using url, CTA.setPropsFromURL will automatically set links starting with '/' to be an internal Gatsby Link
    const persistentItems = [
        {
            label: 'Prescribing Information',
            href: '/interactivepi/',
            target: '_blank',
        },
        {
            label: 'Medication Guide',
            url: 'https://www.merck.com/product/usa/pi_circulars/k/keytruda/keytruda_mg.pdf',
            ariaLabel: 'Medication Guide'
        },
        {
            label: 'Indications',
            url: '/approved-indications/',

        },
    ];
    return (
        <UtilityMenu ref={utility_nav_ref}>
            <UtilityMenuContainer>
                <UtilityMenuList>
                    {persistentItems.map((item, i) => (
                        <UtilityMenuItem key={i}>
                            <StyledMenuLink
                                data-design-category="top_nav_vd"
                                as={(!item.url && !item.href) ? 'span' : undefined}
                                href={item.url || item.href}
                                target={item.target || undefined}
                                {...CTA.setPropsFromURL(item.url)}
                                onClick={item.onClick}
                                aria-label={item.ariaLabel}
                            >
                                <MenuLabel>{item.label}</MenuLabel>
                            </StyledMenuLink>
                        </UtilityMenuItem>
                    ))}
                </UtilityMenuList>
                <UtilityMenuList hideOnSmallScreen={true}>
                    <UtilityMenuItem key={1}>
                        <UtilityTertiaryButton
                            dataDesignCategory="top_nav_vd"
                            external="true"
                            label="Ask Merck"
                            theme="light"
                            url="https://www.askmerckoncology.com"
                            target="_blank"
                        />
                    </UtilityMenuItem>
                    <UtilityMenuItem key={2}>
                        <UtilityTertiaryButton
                            tagType="button"
                            dataDesignCategory="top_nav_vd"
                            label="Medical Information"
                            theme="light"
                            external="true"
                            onClick={() => {
                                dispatch(openGeneralModal(medicalInformationModal))
                                dispatch(preventScrolling())
                            }}
                        />
                    </UtilityMenuItem>
                    <UtilityMenuItem key={3}>
                        <UtilityTertiaryButton
                            dataDesignCategory="top_nav_vd"
                            label="Visit Patient Site"
                            theme="light"
                            url="https://www.keytruda.com/"
                            aria-label="Visit Patient Site"
                        />
                    </UtilityMenuItem>
                    <UtilityMenuItem key={4}>
                        <ChatbotLaunchButton disabled={chatDisabled} />
                    </UtilityMenuItem>
                </UtilityMenuList>
            </UtilityMenuContainer>
        </UtilityMenu>
    );
};

export default UtilityNavigation;
