export const SortMethods = {
    DEFAULT: 'default',
    DATE: 'byDate',
    A_Z: 'aToz',
    SEARCH: 'search',
};

//Exclude from Filter specific CancerTypes
const excludedCancerTypes = [20];

export const sortByDefault = list => {
    const defaultGroups = {
        'Select Cancer Types': [3, 2, 4, 5],
        'Other Cancer Types' : [19, 10, 7, 9, 13, 17, 16, 11, 12, 8, 22, 14, 21],
        'Multi-Cancer Types' : [15],
    };

    if (!list || !Object.keys(list).length) return {};

    let groups = {};

    Object.entries(defaultGroups).forEach(([groupName, indicationIds]) => {
        groups[groupName] = indicationIds.map(id => Object.values(list).find(item => id === item.id));
    });

    return groups;
};

// Date Sort removed at launch for logistical/legal reasons. Will be revisited in future phases.

// export const sortByDate = list => {

// const dateGroups = [
//     {
//         name: '2020',
//         cancerTypeIds: [9, 12, 8, 4, 16, 11],
//     },
//     {
//         name: '2019',
//         cancerTypeIds: [6, 7, 14],
//     },
//     {
//         name: '2018',
//         cancerTypeIds: [3, 5, 1, 15, 13, 2, 17, 10],
//     },
// ];
//     if (!list || !list.length) return [];

//     const sorts = list.sort((a, b) => (Date.parse(a.date) < Date.parse(b.date) ? 1 : -1));
//     let groups = {};
//     dateGroups.map(group => {
//     groups[group.name] = group.cancerTypeIds.map(id => list.find(item => id === item.id));
// });

//     return groups;
// };

export const sortByAZ = list => {
    if (!list || !Object.keys(list).length) return {};
    const sortedList = Object.values(list).filter(cancerType => !excludedCancerTypes.includes(cancerType.id)).sort((a, b) => a.name.localeCompare(b.name, undefined, {'sensitivity': 'base'}));
    return sortedList;
};

export const sortBySearch = (list, search) => {
    if (!list || !Object.keys(list).length) return {};
    let _terms = search.split(' ').filter(el => {
        return el;
    });
    let allMatches = list;

    _terms.forEach(term => {
        let _search = term.toLowerCase();
        let _termMatches = Object.values(list).filter(cancerType => !excludedCancerTypes.includes(cancerType.id)).filter(cancerType => {
            let _name = cancerType.name.toLowerCase();
            let _modifier = cancerType.modifier.toLowerCase();
            // let _tags = cancerType.tags.join('').toLowerCase();
            return (
                _name.includes(_search) || _modifier.includes(_search) 
            );
        });
        let matchesIDArray = _termMatches.filter(cancerType => Object.values(allMatches).includes(cancerType));
            allMatches = {};
            matchesIDArray.forEach(cancerType => {
                allMatches[cancerType.id] = cancerType;
            })

    });

    return sortByAZ(allMatches);
};
