import React from 'react';
import styled from 'styled-components';
import Media from 'react-media';
import { Heading, Text } from '../../util/typography';
import * as colors from '../../util/colors';
import * as FilterButtons from './FilterButtons';
import { closeBlueberry } from '../../util/icons';
import { NAVIGATION_BREAKPOINT_UNITLESS } from '../../constants/layout';

//@todo this entire component could probably stand to be refactored/rethought - it made sense with the original design, but seems overly complex with subsequent changes
const bp = NAVIGATION_BREAKPOINT_UNITLESS;

const HeaderContainer = styled.div`
    flex: 0 auto;
    background: ${colors.white};
    box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.15);

    @media (min-width: ${bp}px) {
        border-top: 2px solid ${colors.silver};
    }

    > div {
        max-width: 1032px;
        padding: 0;
        margin: 0 auto;
        box-sizing: border-box;
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        align-items: center;
    }
`;

const ActionContainer = styled.div`
    display: flex;
    flex-wrap: nowrap;
    padding: 0 0 0 20px;
    flex: 0 100%;
    justify-content: flex-end;
    align-items: center;
    box-sizing: content-box;

    @media (min-width: ${bp}px) {
        padding: 20px;
    }
`;

const ActionHeader = styled(Heading.H5)`
    margin: 0 20px 0 0;
    flex: 1 1 0;
`

export const MessageContainer = styled.div`
    order: 0;
    padding: 20px 20px 0;
    flex: 1 1 0;
`;

export const ResetButton = styled.div`
    flex: 2 0 auto;
`;

export const CloseButton = styled.button.attrs(props => ({
    'data-design-category': props.dataDesignCategory || props['data-design-category'] || 'button_vd'
}))`
    flex: 0 0 50px;
    background-color: transparent;
    border: none;
    background-image: url(${closeBlueberry});
    background-repeat: no-repeat;
    background-size: 14px 14px;
    height: 62px;
    border-left: 2px solid ${colors.silver};
    padding: 14px 10px 12px;
    margin: 0 0 0 20px;
    background-origin: content-box;
    background-position: center top;
    cursor: pointer;
    display: flex;
    flex-flow: column nowrap;
    justify-content: flex-end;
    box-sizing: border-box;
`;

const CloseButtonText = styled(Text.Button)`
    font-size: 12px;
`;

const FilterHeader = ({ resetUserSelection, setShow, filter_header_ref }) => {
    return (
        <HeaderContainer ref={filter_header_ref}>
            <div>
                <ActionContainer>
                    <Media
                        query={{ minWidth: bp }}
                        render={() => (
                            <ActionHeader>
                                Select 1 or more. Filters only apply to website pages with content
                                about specific tumors.
                            </ActionHeader>
                        )}
                    />
                    <Media
                        query={{ maxWidth: bp - 1 }}
                        render={() => (
                            <ResetButton>
                                <FilterButtons.ResetFilterPrompt
                                    onClick={() => resetUserSelection()}
                                />
                            </ResetButton>
                        )}
                    />
                    <FilterButtons.ApplyButton />
                    <Media
                        query={{ maxWidth: bp - 1 }}
                        render={() => (
                            <CloseButton onClick={() => setShow()}>
                                <CloseButtonText>Close </CloseButtonText>
                            </CloseButton>
                        )}
                    />
                </ActionContainer>
            </div>
        </HeaderContainer>
    );
};

export default FilterHeader;
