import React from 'react';
import styled from 'styled-components';
import { NotationBlock } from '../../util/typography';
import ComponentWrapper from '../layout/ComponentWrapper';
import Card from './Card';
import {componentTheme} from '../../util/functions';

const GridContainer = styled.div`
    width: 100%;
    display: grid;
    margin: 0 auto;
    grid-template-columns: repeat(auto-fill, minmax(280px, 1fr));
    /* grid-template-rows: repeat(auto-fit, minmax(0, 1fr)); */

    grid-gap: 20px;
    justify-content: start;
    list-style-type: none;
`;

const LogoCTAGrid = props => {
    let theme = componentTheme(true, props.sectionTheme, props.theme);

    return (
        <ComponentWrapper>
            <GridContainer>
                {props.items.map((item, index) => {
                    return (
                        <div key={index}>
                            <Card key={index} {...item} />
                        </div>
                    );
                })}
            </GridContainer>
            {(props.footnotes || props.definitions) && <NotationBlock footnotes={props.footnotes} definitions={props.definitions} theme={theme}/>}
        </ComponentWrapper>
    );
};

export default LogoCTAGrid;