import React from 'react';
import styled from 'styled-components';
import { Heading, Text } from '../../util/typography';

import Chart from './Chart';
import * as colors from '../../util/colors';
import { createLocalScaleFunction } from '../../util/functions';
import ComponentWrapper from '../layout/ComponentWrapper';

const SINGLE_MIN_BREAKPOINT = '630px';

const minBP = styles => {
    return `@media (min-width: ${SINGLE_MIN_BREAKPOINT}) {
        ${styles}
    }`;
};

const BoxesContainer = styled.div`
    ${props => {
        if (props.count === 2) {
            return `${minBP(
                `display:flex;
                flex-wrap: wrap;
                align-items: center;
                justify-content: space-evenly`
            )}`;
        } else {
        }
    }};
`;

const DonutBox = styled.div`
    padding: ${createLocalScaleFunction(10, 320, 20, 952)};
    border-radius: 8px;
    border: solid 1px #dddddd; // NOTE! - this hex code is not in the color palette
    background-color: ${colors.white};
    box-sizing: border-box;
    ${props => {
        if (props.count === 2) {
            return `${minBP(
                `flex: 1 1 auto;
                max-width:315px;`
            )}`;
        } else {
            return `display:flex;
                flex-wrap: wrap;
                align-items: center;
                justify-content: center;`;
        }
    }};
`;

const VsContainer = styled.div`
    text-align: center;
    // padding: 20px 0;
    padding: ${createLocalScaleFunction(10, 320, 20, 952)} 0;
    ${minBP(
        `min-width: 50px;
        flex: 0 1 auto;`
    )}
`;

const VsLabel = styled(Heading.Subtitle)`
    font-weight: 300;
`;

const ChartLegend = styled.div`
    flex: 1 1 auto;
    // margin: 0 calc(20px + 0.05%);
    margin-left: ${createLocalScaleFunction(5, 320, 40, 952)};

    > * {
        margin-top: 0;
    }
`;

const LegendLabel = styled(Heading.H4)`
    margin-bottom: 2px;
    ${minBP(`text-align: left;`)}
    ${props => {
        if (props.count === 2) {
            return `${minBP(`text-align: center;`)}`;
        } else {
        }
    }};
`;

const LegendCaption = styled(Text.Body)`
    margin-top: 0;
    ${minBP(`text-align: left;`)}
    font-weight: 400;
    ${props => {
        if (props.count === 2) {
            return `${minBP(`text-align: center;`)}`;
        } else {
        }
    }};
`;

const DataCaption = styled(Text.Body)`
    font-weight: 400;
`;

const Proportion = styled.div`
    margin: 4px 0;

    strong {
        font-weight: 500;
        color: ${colors.ink};
        padding-left: 5px;
    }
`;

const Swatch = styled.span`
    background: ${props => props.color}; 
    display: inline-block;
    height: 10px;
    width: 10px;
    border-radius: 2px;
    margin-right: 5px;
    strong {
        font-weight: 500;
        color: ${colors.ink}
        padding-left: 10px;
    }
`;

const ProportionColorMap = {
    Keytruda: [
        colors.keytrudaGreenWeb,
        colors.consumerGreen,
        colors.keytrudaGreen,
        colors.afternoon,
    ],
    //Other: [colors.ink, colors.slate, colors.concrete, colors.pebble] NOTE! - there is a discrepency between Zeplin and the DSM docs...this line is following the DSM docs. the Following line follows Zeplin as much as can be done
    Other: [colors.charcoal, colors.slate, colors.pebble, colors.pebble], // Zeplin version
};

const ChartTitle = styled(Heading.Subtitle)`
    font-weight: 400;
    margin-bottom: ${createLocalScaleFunction(20, 320, 30, 952)};
    flex: 1 1 100%;
`;

const InnerWrapper = styled.div`
    flex: 1 1 auto;
    max-width: 692px;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
`;

const ComparisonDonut = props => {
    return (
        <ComponentWrapper>
            <BoxesContainer count={props.data.DonutStack.length}>
                <ChartTitle as='H3' theme={props.data.Theme}>{props.data.Title}</ChartTitle>

                {props.data.DonutStack.map((donutStack, stackIndex) => (
                    <>
                        <DonutBox count={props.data.DonutStack.length}>
                            <InnerWrapper>
                                <Chart
                                    ProportionColorMap={ProportionColorMap}
                                    DonutStack={donutStack}
                                    extendedStyles="flex: 1 1 auto; max-width: 222px; margin: 0 calc(20px + .05%);"
                                />

                                <ChartLegend count={props.data.DonutStack.length}>
                                    {donutStack.Title && (
                                        <LegendLabel
                                            forwardedAs="h2"
                                            count={props.data.DonutStack.length}
                                        >
                                            {donutStack.Title}
                                        </LegendLabel>
                                    )}
                                    {donutStack.SubTitle && (
                                        <LegendCaption
                                            forwardedAs="h3"
                                            count={props.data.DonutStack.length}
                                        >
                                            {donutStack.SubTitle}
                                        </LegendCaption>
                                    )}

                                    {donutStack.Proportions.map((proportion, proportionIndex) => (
                                        <Proportion key={`proportion-${proportionIndex}`}>
                                            <Swatch
                                                color={
                                                    proportion.ColorOverride ||
                                                    ProportionColorMap[donutStack.Theme][
                                                        proportionIndex
                                                    ]
                                                }
                                            />{' '}
                                            <DataCaption>{`${
                                                proportion.PercentLabelOverride ||
                                                proportion.Percent
                                            }%`}</DataCaption>{' '}
                                            <DataCaption>{proportion.Description}</DataCaption>
                                        </Proportion>
                                    ))}
                                </ChartLegend>
                            </InnerWrapper>
                        </DonutBox>

                        {props.data.DonutStack.length > 1 &&
                            props.data.DonutStack.length - 1 !== stackIndex && (
                                <VsContainer key={`stack-${stackIndex}`}>
                                    <VsLabel theme={props.data.Theme}>
                                        {props.VsLabel || 'VS'}
                                    </VsLabel>
                                </VsContainer>
                            )}
                    </>
                ))}
            </BoxesContainer>
        </ComponentWrapper>
    );
};

export default ComparisonDonut;
