import React from 'react';
import styled from 'styled-components';
import { Heading, Text, WYSIWYG } from '../../../util/typography';
import * as colors from '../../../util/colors';
import { createLocalScaleFunction } from '../../../util/functions';
import ComponentWrapper from '../../layout/ComponentWrapper';
import icon from '../../../assets/icon-zoom.svg';
import { NotationBlock } from '../../../util/typography';

const IMAGE_DOSPLAY_BREAKPOINT = '768';

const Container = styled.div`
    border-radius: 8px 8px 0 0;
    text-align: center;
`;

const HeaderContainer = styled.div`
    margin-bottom: ${createLocalScaleFunction(16, 320, 20, 992)};
`;

const TableTitle = styled(Heading.H4)``;

const Limitation = styled(Text.Body)`
    margin-top: 10px;
`;
const TableImageWrapper = styled.div`
    position: relative;
    img {
        display: block;
        max-width: 100%;
    }
`;

const PinchContainer = styled.div`
    display: flex;
    align-items: center;
    margin-top: 16px;

    @media (hover:hover) {
        display: none;
    }
`;

const PinchIcon = styled.span`
    height: 40px;
    width: 40px;
    display: block;
    background-size: 35px 35px;
    background-repeat: no-repeat;
    background-image: url(${icon});
    margin-right: 6px;
`;

const PinchLabel = styled(Text.Eyebrow)`
    text-transform: uppercase;
    color: ${colors.slate};
`;

const AdditionalInformationTitle = styled(Heading.H5)`
    margin-top: 30px;
`;

const AdditionalInformationDescription = styled(WYSIWYG)`
    display: inline-block;
    margin-top: 10px;
`;

const DataTable = props => {

    // const [imageDisplayType, setImageDisplayType] = useState('mobile');
    const isBrowser = typeof window !== 'undefined';

    // Looks like this effect isnt being used to determine which image to render
    // Leaving this here for now
    // useEffect(() => {
    //     const mediaQuery = `(max-width: ${IMAGE_DOSPLAY_BREAKPOINT}px)`;
    //     const mediaQueryList = window.matchMedia(mediaQuery);

    //     mediaQueryList.addEventListener('change', event => {
    //         if (event.matches) setImageDisplayType('mobile')
    //         else setImageDisplayType('desktop')
    //     })
    // }, []);

    // the mobile image will always be the default displayed in order to optimiz for performance
    let imageToRender = props.mobileImage || props.image;

    // we rely on the client side to render the desktop image instead of the mobile view
    // a more optimial use would be to rely on srcset or the like to do this
    // TODO: optimize responsive images
    if (isBrowser && !window.matchMedia(`(max-width: ${IMAGE_DOSPLAY_BREAKPOINT}px)`).matches) {  
        imageToRender = props.desktopImage || props.image;
    }

    return (
        <ComponentWrapper>
            <HeaderContainer>
                {props.title && <TableTitle as='H3' theme={props.theme} dangerouslySetInnerHTML={{__html: props.title}}/>}
                {props.limitation !== '' && (
                    <Limitation forwardedAs="div" theme={props.theme}>
                        {props.limitation}
                    </Limitation>
                )}

                <PinchContainer>
                    <PinchIcon />
                    <PinchLabel>Pinch to zoom & enlarge</PinchLabel>
                </PinchContainer>
            </HeaderContainer>
            <Container>
                <TableImageWrapper >
                    {typeof imageToRender === 'function' && imageToRender()}
                </TableImageWrapper>

            </Container>

            {props.additionalInformation && 
                <>
                    {props.additionalInformation.map((item, index) => 
                        <div key={index}>
                            {item.title && <AdditionalInformationTitle as='H4' dangerouslySetInnerHTML={{__html: item.title}}></AdditionalInformationTitle> }
                            {item.text && <AdditionalInformationDescription dangerouslySetInnerHTML={{__html: item.text}}></AdditionalInformationDescription> }
                        </div>
                    )}
                </>
            }

            {(props.footnotes || props.definitions) && <NotationBlock footnotes={props.footnotes} definitions={props.definitions} />}
        </ComponentWrapper>
    );
};

export default DataTable;
