// STRINGS
const _EFFICACY = 'Efficacy for Other Indications';
const _RELATED_INFO = 'Related Information';
const _INTERESTED_IN = 'You Might Be Interested In';
const _BIOMARKER = 'BIOMARKER TESTING';

// Mock DATA
const efficacyData = [
    { text: '1L Combo Therapy: Squamous in mNSCLC', url: '#' },
    { text: '1L Monotherapy in mNSCLC', url: '#' },
    { text: '2L Monotherapy in mNSCLC', url: '#' },
];

const efficacyDataObject = {
    heading: _EFFICACY,
    // railDataType: 'efficacy',
    items: efficacyData,
    showAsCard: false,
};

const relatedData = [
    { text: 'Safety for NSCLC', url: '#' },
    { text: 'Adverse Reactions', url: '#' },
    { text: 'Testing & Scoring', url: '#' },
];

const relatedDataObject = {
    heading: _RELATED_INFO,
    // railDataType: 'related',
    items: relatedData,
    showAsCard: false,
};

const defaultRailCardItemData = {
    eyebrow: _BIOMARKER,
    text: 'PD&#8288;-&#8288;L1 Testing & Scoring',
    url: '#',
};

const interestedData = [
    {
        eyebrow: _BIOMARKER,
        text: 'PD&#8288;-&#8288;L1 Testing & Scoring',
        url: '#',
    },
];

const interestedDataObject = {
    heading: _INTERESTED_IN,
    // railDataType: 'intereseted',
    items: interestedData,
    showAsCard: true,
};

const sampleRightRailData = [efficacyDataObject, relatedDataObject, interestedDataObject];

export {
    _EFFICACY,
    _RELATED_INFO,
    _INTERESTED_IN,
    _BIOMARKER,
    efficacyData,
    relatedData,
    interestedData,
    defaultRailCardItemData,
    sampleRightRailData,
    efficacyDataObject,
    relatedDataObject,
    interestedDataObject,
};
