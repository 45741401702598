import React from 'react';
import styled from 'styled-components';
import { Heading, WYSIWYG, NotationBlock } from '../../util/typography';
import PropTypes from 'prop-types';
import { indications as indicationsData } from '../../util/data/indications';
import { cancerTypes as cancerTypesData } from '../../util/data/cancerTypes';
import ComponentWrapper from '../layout/ComponentWrapper';
import { createMarkup } from '../../util/functions';

const Container = styled.div`
    > *:first-child {
        margin-top: 0;
        padding-top: 0;
    }

    > *:last-child {
        margin-bottom: 0;
        padding-bottom: 0;
    }
`;

const CancerGroupSubheadline = styled(Heading.Subtitle)``;

const IndicationHeader = styled(Heading.H3)`
    * + & {
        margin-top: 6px;
    }
`;

// this only handles a simple string
const IndicationText = styled(WYSIWYG)`
    display: block;
    * + & {
        margin-top: 20px;
    }
`;

const emptyIndication = {
    modifier: '',
    name: 'No matching indication',
    statement: '',
};

const emptyCancerType = {
    modifier: '',
    name: 'No matching cancer type',
};

const IndicationPageIntro = ({ indicationId }) => {
    // indicationId = 2;
    const getIndication = indicationsData[indicationId];
    const getCancerType = Object.values(cancerTypesData).find(cancerType =>
        cancerType.indications.includes(indicationId * 1)
    );
    // safety check for indications object
    const indication = !!getIndication ? getIndication : emptyIndication;
    const cancerType = !!getCancerType ? getCancerType : emptyCancerType;

    const indicationTitle = (indication.modifier) ? `${indication.modifier} ${indication.name}` : indication.name;

    return (
        <ComponentWrapper>
            <Container>
                <CancerGroupSubheadline dangerouslySetInnerHTML={createMarkup(`${cancerType.modifier} ${cancerType.name}`)} />
                <IndicationHeader as='H2' dangerouslySetInnerHTML={ createMarkup(indicationTitle)} />
                <IndicationText dangerouslySetInnerHTML={ createMarkup(indication.statement)} />
                {(indication.definitions) && <NotationBlock definitions={indication.definitions} />}
            </Container>
        </ComponentWrapper>
    );
};

IndicationPageIntro.propTypes = {
    /**
    ID of indication to display
    */
    indicationId: PropTypes.number,
};

IndicationPageIntro.defaultProps = {
    indicationId: 1,
};

export default IndicationPageIntro;
