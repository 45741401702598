import React, { useContext } from 'react';
import styled from 'styled-components';
import * as colors from '../../../util/colors';
import * as SVGIcons from '../../CustomizeFilter/icons';
import { Text } from '../../../util/typography';
import { checkAndTriggerCancerTypeChange, createMarkup } from '../../../util/functions';
import {
    openFilter,
    openOverlay,
    preventScrolling,
} from '../../../store/actions';
import { cancerTypes } from '../../../util/data/cancerTypes';
import { useDispatch } from 'react-redux';
import { FilterContext } from '../../FilterProvider';

export const splitGradient = (leftColor, rightColor) =>
    `linear-gradient(to right, ${leftColor} 0%, ${leftColor} 50%, ${rightColor} calc(50% + .1px), ${rightColor} 100%);`;

const Row = styled.div`
    margin: -9px -10px 11px;
    display: flex;
    justify-content: ${({justifyContent}) => justifyContent};
    align-items: center;
    flex-wrap: wrap;
`;

const ButtonContainer = styled.div`
    margin: 9px 10px;
`;

const TextLinkContainer = styled.div`
    display: flex;
    flex-wrap: wrap;
    margin: 7px 0;
`;

const TextLink = styled(Text.Body)`
    display: block;
    margin: 2px 10px;
`;

const FilterPrompt = styled.button.attrs(props => ({
    'data-design-category': props.dataDesignCategory || props['data-design-category'] || 'button_vd'
}))`
    background-image: ${({ theme, buttonType, backgroundColor }) =>
        buttonType === 'primary'
            ? theme === 'light'
                ? splitGradient(colors.white, colors.mist)
                : splitGradient(colors.blueberry, colors.blueberryDark)
            : theme === 'light'
                ? splitGradient('transparent', colors.white)
                : splitGradient(backgroundColor || colors.white, colors.blueberry)};
        
    background-size: 201% 100%;
    background-origin: border-box;
    border: none;
    border-radius: 4px;
    color: ${colors.white};
    padding: ${({ buttonType }) => buttonType === 'primary'
        ? '12.8px 22px 11.2px'
        : '11.8px 22px 10.2px'};
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    transition: border-radius 0.2s ease-in-out, background-position 0.2s ease-in-out,
        color 0.2s ease-in-out;
    
    ${({ buttonType, theme, backgroundColor }) => buttonType === 'secondary' && `
        border: 1px solid transparent;
        border-color: ${theme === 'light' ? colors.white : colors.blueberry};
        color: ${theme === 'light' ? colors.white : colors.blueberry};
        background-color: ${backgroundColor || 'transparent'};
    `}

    svg {
        flex-shrink: 0;
        margin-right: 12px;
        fill: currentColor;
        display: block;
    }

    > span {
        color: currentColor;
        line-height: 20px;
    }

    @media (hover:hover) {
        &:hover,
        &:active,
        &:focus {
            background-position: right center;
            color: ${({ theme }) => (theme === 'light' ? colors.blueberryDark : colors.white)};
        }
    }
`;

const FilterTrigger = props => {
    /**
     * This is an inline version of what used to be a global FilterBanner — it appears at the top of the TriageList (aside from on
     * the homepage) and allows a user to interface with the CustomizeFilter menu.
     */
    const { filteredCancerTypes, setFilteredCancerTypes } = useContext(FilterContext);
    const dispatch = useDispatch();

    const handleOpenFilter = () => {
        dispatch(openFilter());
        dispatch(openOverlay());
        dispatch(preventScrolling());
    };

    const handleReset = () => {
        setFilteredCancerTypes([]);
        checkAndTriggerCancerTypeChange();
    }

    return (
        <>
            {
                filteredCancerTypes.length
                    ?
                        <Row justifyContent={'space-between'}>
                            <TextLinkContainer>
                                <TextLink><b>Selected:</b> {`${filteredCancerTypes.length} of ${Object.keys(cancerTypes).length} Cancer Types`}</TextLink>
                                <TextLink>
                                    <b><a onClick={handleReset}>Clear Selection</a></b>
                                </TextLink>
                            </TextLinkContainer>
                            <ButtonContainer>
                                <FilterPrompt buttonType='secondary' onClick={handleOpenFilter} backgroundColor={'transparent'}>
                                    <SVGIcons.FilterIcon />
                                    <Text.Button
                                        style={{ color: 'inherit' }}
                                        dangerouslySetInnerHTML={createMarkup('Change Cancer Types')}
                                    />
                                </FilterPrompt>
                            </ButtonContainer>
                        </Row>
                    :
                        <Row justifyContent={'flex-start'}>
                            <ButtonContainer>
                                <FilterPrompt buttonType='primary' onClick={handleOpenFilter}>
                                    <SVGIcons.FilterIcon />
                                    <Text.Button
                                        style={{ color: 'inherit' }}
                                        dangerouslySetInnerHTML={createMarkup('Filter by Cancer Type')}
                                    />
                                </FilterPrompt>
                            </ButtonContainer>
                        </Row>
                }
        </>
    );
};

export default FilterTrigger;
