import React from 'react';
import styled from 'styled-components';
import { Footnote } from '../../util/typography';
import * as colors from '../../util/colors';
import ComponentWrapper from '../layout/ComponentWrapper';
import { createLocalScaleFunction } from '../../util/functions';

const Container = styled.div`
    background-color: ${({ card }) => (card ? colors.white : 'transparent')};
    border-radius: ${({ card }) => (card ? '8px' : '0')};
    border: ${({ card }) => (card ? `1px solid ${colors.silver}` : '0')};
    // padding: ${createLocalScaleFunction(16, 320, 30, 992)} 20px;
    padding: ${({ card }) => (card ? `${createLocalScaleFunction(16, 320, 30, 992)} 20px` : '0')};
    color: ${({ card }) => (card ? colors.charcoal : 'inherit')};

    ${({ card }) => card ? `* {color: ${colors.charcoal};}`: ''};

    /* allows long urls with underscores to break on mobile */
    & div {
       word-break: break-word;
    }
`;

const ReferencesBlock = props => {
    return (
        <ComponentWrapper>
            <Container>
                {props.references.map((item, index) => {
                    if (item.text !== '' && item.label !== '') {
                        return (
                            <Footnote
                                theme={props.theme}
                                label={item.label}
                                text={item.text}
                                key={index}
                            />
                        );
                    } else {
                        return true;
                    }
                })}
            </Container>
        </ComponentWrapper>
    );
};

export default ReferencesBlock;
