import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Heading, Text } from '../../util/typography';
import * as colors from '../../util/colors';

const ItemHead = styled.div`
    min-width: calc(30% - (2 * var(--childPadding)));
    flex-grow: 1;
    flex-basis: calc(var(--multiplier) * 999);
    padding: 0 var(--childPadding) var(--childPadding);
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
`;

const ItemIcon = styled.div`
    --iconPadding: 16px;
    --iconContainer: calc(var(--iconSize) + (var(--iconPadding) * 2));
    border: 1px solid ${colors.pebble};
    border-radius: 50%;
    padding: var(--iconPadding);
    width: var(--iconContainer);
    height: var(--iconContainer);
    background-color: white;

    & > img {
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
`;

const ItemHeading = styled(Heading.H3)`
    margin-top: 20px;
    color: inherit;
`;

const ItemSubHeading = styled(Text.Subtitle)`
    color: inherit;
`;

const IconListItemIconColumn = ({ icon, alt, heading, subHeading }) => {
    return (
        <ItemHead>
            {icon && (
                <ItemIcon>
                    <img alt={alt} src={icon} />
                </ItemIcon>
            )}
            {heading && <ItemHeading as='H3'>{heading}</ItemHeading>}
            {subHeading && <ItemSubHeading>{subHeading}</ItemSubHeading>}
        </ItemHead>
    );
};

IconListItemIconColumn.prototypes = {
    icon: PropTypes.string,
    heading: PropTypes.string,
    subHeading: PropTypes.string,
};

export default IconListItemIconColumn;
