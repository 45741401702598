import React from 'react';
import styled from 'styled-components';
import * as colors from '../../util/colors';
import { Heading, WYSIWYG, NotationBlock } from '../../util/typography';
import ComponentWrapper from '../layout/ComponentWrapper';
import { createMarkup } from '../../util/functions';
import { BoxedDefinitionList, ExclamationList } from '../../components';

const StyledComponentWrapper = styled(ComponentWrapper)`
    border-bottom: 1px solid ${colors.pebble};
    max-width: 824px;

    &:last-child {
        border-bottom: none;
    }
`;

const IndicationDosingContent = styled.article``;

const IndicationDosingTitle = styled(Heading.H3)`
    margin-bottom: -10px;
`;

const DosingCallout = styled(WYSIWYG)`
    margin: 30px 0 40px 0;
    font-size: 20px;
`;

const IndicationDosing = props => {
    const adultDosingHeading = `Dosing${props.body.adult?.headingAddon || ''}`;
    const pediatricDosingHeading = `Dosing${props.body.pediatric?.headingAddon || ''}`;
    const durationHeading = 'Duration and Timing of Treatment';

    return (
        <StyledComponentWrapper>
            <IndicationDosingContent>
                <IndicationDosingTitle dangerouslySetInnerHTML={createMarkup(props.title)} />
                {props.body.adult && 
                (<BoxedDefinitionList
                    title='Adult Patients'
                    arTitle={true}
                    items={[
                        {
                            header: adultDosingHeading,
                            description: `<ul>
                                            ${props.body.adult.dosing}
                                        </ul>
                            `
                        },
                        {
                            header: durationHeading,
                            description: props.body.adult.duration
                        },
                    ]}
                />)}
                {props.body.pediatric && 
                (<BoxedDefinitionList
                    title='Pediatric Patients'
                    arTitle={true}
                    items={[
                        {
                            header: pediatricDosingHeading,
                            description: props.body.pediatric.dosing
                        },
                        {
                            header: durationHeading,
                            description: props.body.pediatric.duration
                        },
                    ]}
                />)}
                {props.body.callout &&
                (
                    <DosingCallout>
                        <a href={props.body.callout.url}>{props.body.callout.text}</a>
                    </DosingCallout>
                )}
                {props.body.patientSelection && 
                (
                    <ExclamationList 
                        title='Patient Selection'
                        arTitle={true}
                        items={props.body.patientSelection}
                    />
                )}
                {(props.definitions || props.footnotes) && <NotationBlock definitions={props.definitions} footnotes={props.footnotes} />}
            </IndicationDosingContent>
        </StyledComponentWrapper>
    );
};

export default IndicationDosing;
