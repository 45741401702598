import styled from 'styled-components';
import { Text } from '../../util/typography';

export const BaseChip = styled(Text.Body)`
    border: 1px solid var(--chipBorder);
    border-radius: 4px;
    padding: 0px 10px;
    color: var(--chipText);
    display: inline-flex;
`;
