import { useEffect } from 'react';
import { getFocusableElements } from './functions';

const useTrapFocus = (containerRef, enable = true, dependencies = []) => {
  useEffect(() => {
    if (!containerRef.current || !enable) return;
    const container = containerRef.current;
    const focusableElements = getFocusableElements(container);

    if (focusableElements.length === 0) return;

    const firstElement = focusableElements[0];
    const lastElement = focusableElements[focusableElements.length - 1];

    const handleKeydown = (e) => {
      if (e.key === 'Tab' && !e.shiftKey && e.target === lastElement) {
        e.preventDefault();
        firstElement.focus();
      } else if (e.key === 'Tab' && e.shiftKey && e.target === firstElement) {
        e.preventDefault();
        lastElement.focus();
      }
    };

    document.addEventListener('keydown', handleKeydown);

    return () => {
      document.removeEventListener('keydown', handleKeydown);
    };
  }, [containerRef, enable, ...dependencies]);
};

export default useTrapFocus;
