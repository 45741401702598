import React from 'react';
import styled from 'styled-components';
import * as colors from '../../util/colors';
import { Heading, WYSIWYG, NotationBlock } from '../../util/typography';
import * as CTA from '../../util/CTA';
import ComponentWrapper from '../layout/ComponentWrapper';
import { between } from 'polished';
import { createMarkup } from '../../util/functions';

const StyledComponentWrapper = styled(ComponentWrapper)`
    border-bottom: 1px solid ${colors.pebble};
    max-width: 824px;
    padding-bottom: 20px;

    @media (min-width: 320px) and (max-width: 1032px) {
        padding-bottom: ${between('20px', '30px', '320px', '1032px')};
    }

    @media (min-width: 1033px) {
        padding-bottom: 30px;
    }

    &:last-child {
        border-bottom: none;
    }
`;

const IndicationCTAContent = styled.article``;

const IndicationCTATitle = styled(Heading.H4)`
    margin-bottom: 9px;
`;

const ActionContainer = styled.div`
    margin-top: 0.5em;
    display: block;
`;

const IndicationCTA = props => {
    return (
        <StyledComponentWrapper>
            <IndicationCTAContent>
                <IndicationCTATitle as='H4' dangerouslySetInnerHTML={createMarkup(props.title)} />
                <WYSIWYG dangerouslySetInnerHTML={createMarkup(props.body)} />
                {(props.definitions) && <NotationBlock definitions={props.definitions} />}
                <ActionContainer>
                    <CTA.TertiaryButton {...props.cta} />
                </ActionContainer>
            </IndicationCTAContent>
        </StyledComponentWrapper>
    );
};

export default IndicationCTA;
