import React from 'react';
import styled from 'styled-components';
import CalloutImg from '../../assets/hcp-opt-callout-key-cut.png';
import * as colors from '../../util/colors';
import { Text, Heading, NotationBlock } from '../../util/typography';
import { createLocalScaleFunction } from '../../util/functions';
import ComponentWrapper from '../layout/ComponentWrapper';
import { createMarkup } from '../../util/functions';

const Wrapper = styled.div`
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: 1fr;
    margin: auto; 
    justify-items:stretch;
    align-items: stretch;
`;

const Container = styled.div`
    grid-column: 1 / 1;
    grid-row: 1 / 1;
    padding: ${createLocalScaleFunction(20, 320, 24, 952)} ${createLocalScaleFunction(20, 320, 30, 952)};
    background-color: ${colors.white};
    border: 1px solid ${colors.pebble};
    color: ${props => (props.theme === 'dark' ? 'black' : colors.white)};
    border-radius: 8px;
    margin: 0 10px 10px 0;
    /* margin: -10px 0 0 -10px;     */
`;

const ContentWrapper = styled.div`
    display: flex;
    flex-wrap: wrap;
`;
const InlineImage = styled.div`
    padding-top: 5px; //adjust to align with text on desktop

    margin-right: 20px;
    flex: 0 1 auto;

    & img {
        margin-bottom: 10px;
    }
`;

const StyledTitle = styled(Heading.H4)`
    margin-bottom: 10px;
`;

const InlineContent = styled.div`
    flex: 1 1 230px;
    min-width: 230px;
    display: flex;
    flex-direction: column;
    justify-content: center;
`;

const GradientBG = styled.div`
    grid-column: 1 / 1;
    grid-row: 1 / 1;
    background-image: radial-gradient(circle at 23% 0, #006ead, #36864a 87%);
    width: calc(100% - 10px);
    /* margin: 10px 0 0 10px;
    padding: 1px 10px 10px 1px; */
    background-color: ${colors.white};
    color: ${props => (props.theme === 'dark' ? 'black' : colors.white)};
    border-radius: 8px;
    box-sizing: border-box;
    margin: 10px 0 0 10px;
`;

const SecondaryCallout = props => {
    return (
        <ComponentWrapper>
            <Wrapper>
                <GradientBG/>
                <Container>
                    <ContentWrapper>
                        <InlineImage>
                            <img alt={props.alt} src={CalloutImg} aria-hidden="true"/>
                        </InlineImage>
                        <InlineContent>
                            <StyledTitle dangerouslySetInnerHTML={createMarkup(props.title)} />

                            <Text.Body dangerouslySetInnerHTML={createMarkup(props.text)}/>
                        </InlineContent>
                    </ContentWrapper>
                </Container>
                {(props.footnotes || props.definitions) && <NotationBlock footnotes={props.footnotes} definitions={props.definitions} />}
            </Wrapper>
        </ComponentWrapper>
    );
};

export default SecondaryCallout;
