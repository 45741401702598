import React from 'react';
import styled from 'styled-components';
import { Heading, Text, WYSIWYG } from '../../util/typography';
import PropTypes from 'prop-types';
import * as colors from '../../util/colors';
import ComponentWrapper from '../layout/ComponentWrapper';
import { NotationBlock } from '../../util/typography';
import exclamation_icon from '../../assets/icons/exclamation-icon.svg';

const Title = styled(Heading.H4)`
    margin-bottom: 20px;
`;

const ArTitle = styled(Heading.H5)`
    margin-bottom: 20px;
`;

const ArMgmtBulletContainer = styled.div`
    ul {
        padding-left: 0;

        li {
            display: flex;
            margin-left: 16px;

            :not(:first-child){
                margin-top: 14px;
            }
            
            &:before {
                display: block;
                content: '';
                width: 20px;
                height: 20px;
                background-image: url(${exclamation_icon});
                background-repeat: no-repeat;
                background-size: 20px 20px;
                flex: 0 0 20px;
                position: relative;
                right: 16px;
            }
        }
    }
`

const BoxedDefinitionListContainer = styled.div``

const ItemContainer = styled.div`
    border: 1px solid ${colors.silver};
    border-radius: 8px;
    margin-top: 20px;
`
const ItemTitle = styled(Heading.H7)`
    padding: 12px 20px;
    background: ${colors.keytrudaGreenBackground};
    border-top-right-radius: 8px;
    border-top-left-radius: 8px;
`
const ItemTextContainer = styled.div`
    margin: 12px 20px;
`
const ItemText = styled(WYSIWYG)``

const createMarkup = content => {
    return { __html: content };
};

const BoxedDefinitionList = props => {
    return (
        <ComponentWrapper>
            <BoxedDefinitionListContainer>
                    {props.arTitle ? 
                        <ArTitle as='H4' dangerouslySetInnerHTML={createMarkup(props.title)} />
                    : 
                        <Title as='H4' dangerouslySetInnerHTML={createMarkup(props.title)}/>
                    }

                    {props.arMgmtBullet &&
                        <ArMgmtBulletContainer>
                            <Text.Body dangerouslySetInnerHTML={createMarkup(props.arMgmtBullet)}/>
                        </ArMgmtBulletContainer>
                    }

                    {props.items.map((item, i) => (
                        <ItemContainer>
                            <ItemTitle dangerouslySetInnerHTML={createMarkup(item.header)} />
                            <ItemTextContainer>
                                <ItemText dangerouslySetInnerHTML={createMarkup(item.description)} />
                            </ItemTextContainer>
                        </ItemContainer> 
                    ))}
            </BoxedDefinitionListContainer>
            {(props.footnotes || props.definitions) && <NotationBlock footnotes={props.footnotes} definitions={props.definitions} />}
        </ComponentWrapper>
    );
};

BoxedDefinitionList.propTypes = {
    title: PropTypes.string.isRequired,
    text: PropTypes.string.isRequired,
};

export default BoxedDefinitionList;