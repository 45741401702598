import { useState, useCallback, useEffect } from 'react';
import { useSelector, shallowEqual } from 'react-redux';
import { cancerTypeCustomContent } from '../components/CustomHomepageGrid/CustomHomepageHero';
import { cancerTypeKeyIdMap } from './data/cancerTypes';
import { GROUP_PARAM_KEY } from '../store/storage-keys';

/**
 * @see https://react-redux.js.org/api/hooks#recipe-useshallowequalselector
 * @param {Function} selector - A selector functions. Is called with the current state, and should return a slice of that same state.
 * @example const filteredCancerTypes = useShallowEqualSelector((state) => state.filteredCancerTypes)
 */
export function useShallowEqualSelector(selector) {
    return useSelector(selector, shallowEqual);
}

/**
 * @see https://reactjs.org/docs/hooks-faq.html#how-can-i-measure-a-dom-node
 * @returns {Array<DOMRect,React.MutableRefObject>} Returns an array of `[rect, ref]` to be destructred.
 */
export function useClientRect() {
    const [rect, setRect] = useState(null);
    const ref = useCallback(node => {
        if (node !== null) {
            setRect(node.getBoundingClientRect());
        }
    }, []);
    return [rect, ref];
}

/**
 * Same as `useState`, but value is persisted into localStorage.
 * Importantly, even though these stored values can be accessed by any component,
 * two components that `_useLocalStorage('same-key')` won't have their `storedValue`s sync'd.
 * If you need multiple components to share this value, you need to:
 *
 * - Lift state up, and pass values down via props
 * - Or sync state to some global store (e.g. redux), and have components access value from redux
 *
 * @see https://usehooks.com/useLocalStorage/
 * @param {String} key
 * @param {String} [initialValue='']
 * @returns {Array<String, Function>} Returns the `value` and `setValue` function, same as `useState`.
 */
function _useLocalStorage(key, initialValue = '') {
    // State to store our value
    // Pass initial state function to useState so logic is only executed once
    const [storedValue, setStoredValue] = useState(() => {
        try {
            // Get from local storage by key
            const item = window.localStorage.getItem(key);

            return item ?? initialValue;
        } catch (error) {
            return initialValue;
        }
    });

    // Return a wrapped version of useState's setter function that ...
    // ... persists the new value to localStorage.
    const setValue = value => {
        try {
            // Allow value to be a function so we have same API as useState
            const valueToStore = value instanceof Function ? value(storedValue) : value;
            // Save state
            setStoredValue(valueToStore);
            // Save to local storage
            window.localStorage.setItem(key, valueToStore);
        } catch (error) {
            // A more advanced implementation would handle the error case
            console.error(error);
        }
    };

    return [storedValue, setValue];
}

/**
 * An SSR-safe version of `_useLocalStorage`. This should be used since this is a Gatsby site.
 *
 * Same as `useState`, but value is persisted into localStorage.
 * Importantly, even though these stored values can be accessed by any component,
 * two components that `_useLocalStorage('same-key')` won't have their `storedValue`s sync'd.
 * If you need multiple components to share this value, you need to:
 *
 * - Lift state up, and pass values down via props
 * - Or sync state to some global store (e.g. redux), and have components access value from redux
 *
 * @see https://github.com/dance2die/react-use-localstorage/issues/24#issuecomment-581479939
 * @param {String} key
 * @param {String} [initialValue]
 * @returns {Array<String, Function>} - Returns the `value` and `setValue` function, similar to `useState`.
 */
export function useLocalStorage(key, initialValue = '') {
    return typeof window === 'undefined'
        ? [initialValue, value => undefined]
        : _useLocalStorage(key, initialValue);
}

export function useGroup () {
    /**
     * This hook is included in FilterProvider and CustomHomepageHero — its purpose is to check for the presence of a group string
     * (such as 'nsc', 'hns'), validate it against a list of approved strings, and then return it. The components that use this
     * hook will then use that string to initialize their state (filteredCancerTypes and heroContent, respectively).
     * 
     * A group string can either come from the URL (a marketing driver includes ?group=nsc) or postMessage (Interaction Studio identifies
     * a user and sends us window.postMessage({ action: 'setFilteredCancerTypes', group: 'nsc' })). When we mount, we'll check both places,
     * and if neither has a group, we'll set up an additional postMessage listener to setGroup if we receive a postMessage after mount.
     * 
     * Before we return a group string, we'll validate it against one of two lists depending on what page we're on.
     * ON THE HOMEPAGE, we only allow groups that match the keys in cancerTypeCustomContent.
     * ON ANY OTHER PAGE, we allow any group that matches a key in cancerTypeKeyIdMap.
     * 
     * URL GROUP STRINGS:
     * - Only apply to the current page we're on, are read on any page, and take precedence over postMessage group strings.
     * POSTMESSAGE GROUP STRINGS:
     * - Are only read on the homepage (see groupToValidate)
     * - Are added to sessionStorage by gatsby-browser.js and persist every time we return to home.
     */
    const isBrowser = typeof window !== 'undefined' && window.location.protocol !== 'data:';
    const onHomepage = isBrowser && window.location.pathname == '/';

    function isGroupAllowedOnPage(group) {
        const allowedGroups = onHomepage
            ? Object.keys(cancerTypeCustomContent)
            : Object.keys(cancerTypeKeyIdMap);

        return allowedGroups.includes(group);
    }

    const [group, setGroup] = useState(() => {
        if (isBrowser) {
            const urlGroup = new URLSearchParams(window.location.search).get('group'),
                sessionStorageGroup =  window.sessionStorage.getItem(GROUP_PARAM_KEY);

            const groupToValidate = onHomepage
                ? urlGroup || sessionStorageGroup
                : urlGroup;

            return isGroupAllowedOnPage(groupToValidate) ? groupToValidate : null;
        }
        else return null;
    });

    function handleMessage (event) {
        if (event.data.action === 'setFilteredCancerTypes' && event.data.group && isGroupAllowedOnPage(event.data.group)) {
            setGroup(event.data.group);
        }
    }

    useEffect(() => {
        if (!group && onHomepage) {
            window.addEventListener("message", handleMessage);
        }

        return () => window.removeEventListener("message", handleMessage);
    }, [])

    return group;
}