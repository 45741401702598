import React from 'react';
import styled from 'styled-components';
import * as colors from '../../util/colors';
import { Heading, Text, NotationBlock, WYSIWYG } from '../../util/typography';
import ComponentWrapper from '../layout/ComponentWrapper';
import EndpointCard from './Card';
import {componentTheme} from '../../util/functions';
import { createMarkup } from '../../util/functions';

const ChartTitle = styled(Heading.H4)`
`;

const Limitation = styled(Text.Body)`
    display: block;
    margin: 10px 0;
`;

const EndpointCardGroup = styled.div`
    flex-basis: var(--y);
    flex-grow: 1;
    flex-shrink: 1;
    align-self: stretch;
    display: flex;
    flex-flow: column nowrap;
    justify-content: stretch;

    &:first-child:nth-last-child(5),
    &:first-child:nth-last-child(5) ~ & {
        flex-basis: 100%
    }
`;

const EndpointStyled = styled.div`
    margin-top: 20px;
    --groupBreakpoint: 600px;
    --x: (var(--groupBreakpoint) - 100%);
    --y: calc(var(--x) * 999);
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;

`;

const AdditionalInformationTitle = styled(Heading.H5)`
    margin-top: 30px;
`;

const AdditionalInformationDescription = styled(WYSIWYG)`
    display: inline-block;
    margin-top: 10px;
`;

const DosingSchedule = props => {
    let theme = componentTheme(true, props.sectionTheme, props.theme);

    const firstItem = props.cards.shift();
    
    return (
        <ComponentWrapper className={props.className}>
            <ChartTitle theme={theme} dangerouslySetInnerHTML={createMarkup(props.title)}/>
            {props.limitation !== '' && (
                <Limitation forwardedAs="div" theme={theme}  dangerouslySetInnerHTML={createMarkup(props.limitation)}/>
            )}
            <EndpointStyled>
                <EndpointCardGroup >
                    <EndpointCard {...firstItem} />
                </EndpointCardGroup>
            </EndpointStyled>
            {(props.footnotes || props.definitions) && <NotationBlock footnotes={props.footnotes} definitions={props.definitions} theme={theme}/>}
        </ComponentWrapper>
    );
};

export default DosingSchedule;
