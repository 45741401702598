import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import AccordionDrawer from './AccordionDrawer';
import FilterTrigger from './FilterTrigger';
import ComponentWrapper from '../ComponentWrapper';

const StyledComponentWrapper = styled(ComponentWrapper)`
    ${props => props.homepage && `margin: 0 auto 20px;`}
`;

const AccordionGroup = props => {
    //for AT/screenshots
    const [openAllItemsOverride, setOpenAllItemsOverride] = useState(false);

    const [openItems, setOpenItems] = useState([]);

    const clickHandler = id => {
        if (openItems.includes(id)) {
            setOpenItems(openItems => openItems.filter(item => item !== id));
        } else {
            setOpenItems(openItems => [...openItems, id]);
        }
    };
    
    useEffect(() => {
        //for AT/screenshots - provide a postMessage listener so the accordions can be opened via client-side JS with window.postMessage({action: 'openAllAccordions'}) 
        window.addEventListener("message", (event) => {
            if (event.data.action === 'openAllAccordions') {
                setOpenAllItemsOverride(true);
            }
            
          }, false);
    },[])

    useEffect(() => {
        if (props.defaultAllOpen) {
            //set the open items array to match the length of the array, which will open all accordions on page load
            setOpenItems([...props.items.keys()]);
        } else {
            setOpenItems([]);
        }
    },[props.defaultAllOpen])
    

    return (
        <StyledComponentWrapper homepage={props.homepage}>
            {props.includeFilter && <FilterTrigger />}
            {props.items.map((item, i) => {
               
            return (
                <AccordionDrawer
                    key={i}
                    drawerId={i} //for animation
                    isOpen={openItems.includes(i) || openAllItemsOverride}
                    clickHandler={() => clickHandler(i)}
                    {...item}
                    transcript={props.transcript}
                >
                    {item.children}
                </AccordionDrawer>
            )})}
        </StyledComponentWrapper>
    );
};

export default AccordionGroup;