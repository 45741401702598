import React from 'react';
import styled from 'styled-components';
import { Heading } from '../../util/typography';
import ComponentWrapper from '../layout/ComponentWrapper';
import { WYSIWYG } from '../../util/typography';
import { componentTheme, createMarkup } from '../../util/functions';

const Container = styled.div`
    text-align: ${({centered}) => (centered) ? 'center' : 'left'};

    > *:first-child {
        margin-top: 0;
        padding-top: 0;
    }

    > *:last-child {
        margin-bottom: 0;
        padding-bottom: 0;
    }
`;

const Title = styled(Heading.H2)`
    margin-bottom: 16px;
`;

const Body = styled.div`

`;

const PageIntro = (props) => {
    let theme = componentTheme(true, props.sectionTheme, props.theme);
    return (
        <ComponentWrapper>
            <Container centered={props.centered}>
                {props.title && <Title
                    as='H2'
                    theme={theme}
                >
                    {props.title}
                </Title>}
                <Body>{props.children && <WYSIWYG theme={theme} dangerouslySetInnerHTML={createMarkup(props.children)} />}</Body>
            </Container>
        </ComponentWrapper>
    );
};

export default PageIntro;
