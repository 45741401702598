import React from 'react';
import styled from 'styled-components';
import * as colors from '../../util/colors';
import { Text, Heading, NotationBlock } from '../../util/typography';
import { componentTheme } from '../../util/functions';
import ComponentWrapper from '../layout/ComponentWrapper';
import { createMarkup } from '../../util/functions';

const Container = styled.div`
    padding: 30px 20px;
    background-color: ${colors.white};
    border: 1px solid ${colors.pebble};
    border-radius: 8px;

    > div {
        max-width: 706px;
        margin: 0 auto;
       
    }
`;

const ContentContainer = styled.div`
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(230px, auto));
    grid-column-gap: 40px;
    grid-row-gap: 26px;
    align-items: center;
`;

const StatContainer = styled.div`
    display: grid;
    grid-template: 1fr / 1fr;
    align-items: center;
    justify-items: center;
    place-self: center;
    background-image: radial-gradient(circle at 23% 0, ${colors.blueberry}, #36864a 86%);
    border-radius: 50%;
    overflow: hidden;
    height: clamp(180px, 16vw, 200px);
    width: clamp(180px, 16vw, 200px);
`;

const Stat = styled(Text.Data1)`
    color: ${colors.white};
`;

const TextContainer = styled.div`
    max-width: 466px;
    min-width: 230px;
    display: flex;
    flex-direction: column;
    justify-content: center;
`;

const StyledTitle = styled(Heading.H4)`
    margin-bottom: 10px;
`;

const HR = styled(Heading.H6)`
`;

const Body = styled(Text.Body)`
    margin-top: 20px;
`;

const FeaturedStat = props => {
    let theme = componentTheme(true, props.sectionTheme, props.theme);
    return (
        <ComponentWrapper>
            <Container>
                <div>
                    <ContentContainer>
                        <StatContainer>
                            <Stat dangerouslySetInnerHTML={createMarkup(props.statistic)} />
                        </StatContainer>
                        <TextContainer>
                            {props.title && <StyledTitle dangerouslySetInnerHTML={createMarkup(props.title)} /> }
                            {props.hr && <HR dangerouslySetInnerHTML={createMarkup(props.hr)} /> }
                            {props.text && <Body dangerouslySetInnerHTML={createMarkup(props.text)}/>}
                        </TextContainer>
                    </ContentContainer>
                </div>
            </Container>
            {(props.footnotes || props.definitions) && <NotationBlock footnotes={props.footnotes} definitions={props.definitions} theme={theme} />}
        </ComponentWrapper>
    );
};

export default FeaturedStat;
