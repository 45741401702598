import React from 'react';
import styled from 'styled-components';
import ComponentWrapper from '../layout/ComponentWrapper';
import ResourceItem from './ResourceItem';

const Wrapper = styled.div` 
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 25px 20px;
`;

const ResourceLibrary = props => {

    return (
        <ComponentWrapper>
            <Wrapper>
                {props.items.map((item) => {
                    return (
                        <ResourceItem {...item} allResourcesDisabled={props.allResourcesDisabled} />
                    );
                })}
            </Wrapper>   
        </ComponentWrapper>
    );
};

export default ResourceLibrary;
