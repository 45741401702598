import React from 'react';
import styled from 'styled-components';
import Row from './Row';
import ComponentWrapper from '../layout/ComponentWrapper';

const GridContainer = styled.div`
    // padding: 0 10px;
    margin: -10px 0;
`;

const StyledComponentWrapper = styled(ComponentWrapper)`
    margin: ${props => props.homepage ? `10px auto 20px` : `20px auto`};
`;

const TieredCTAGrid = props => {
    return (
        <StyledComponentWrapper homepage={props.homepage}>
            <GridContainer>
                {props.rows.map((row, index) => (
                    <Row key={index} type={row.type} rowTier={row.rowTier} items={row.items} rowKey={index} />
                ))}
            </GridContainer>
        </StyledComponentWrapper>
    );
};

export default TieredCTAGrid;
