function generateChatBotStyles(usableSpaceObject) {
    const { top, right, bottom, height } = usableSpaceObject;


    // the below 

    /* 

    .alme--window #null:nth-child(1) + #null:nth-child(2) {
            display: none;
        }
        .alme--window .alme--window--conversation--response--alme:nth-child(1) + .alme--window--conversation--response--alme:nth-child(2) {
            display: none;
        }
    
    */

    // is a terrible (but secretly satisfying) hack 
    // that will keep the chatbot from displaying thw welcom message twice
    // these are rather ugly, but worth it in order to make launch in time
    // we will need to revisit this with the chatbot team to determine the true
    // root cause of this

    const positionStyles = `
        .alme--window {
            top: ${top}px!important;
        }
        @media(max-width: 768px) {
            .alme--window {
                height: ${height}px!important;
            }
        }
        @media(min-width: 769px) {
            .alme--window {
                top: initial!important;
                left: initial!important;
                right: ${right}px!important;
                bottom: ${bottom}px!important;
                max-height: ${height}px!important;
                min-height: initial!important;
            }
        }
        .alme--window #null:nth-child(1) + #null:nth-child(2) {
            display: none;
        }
        .alme--window .alme--window--conversation--response--alme:nth-child(1) + .alme--window--conversation--response--alme:nth-child(2) {
            display: none;
        }
    `;

    return positionStyles;
}

export default generateChatBotStyles;
