import { CHATBOT_USER_OPEN_KEY } from '../../constants/analytics';

const chatbotPostMessageData = {
    message: {
        type: 'LaunchIva',
        source: 'client',
        accessKey: '4b8ae5cf-b960-4b0b-aacf-e9bbc5b4d061',
        launchPointName: 'Global',
        launchParameter: 'MainPage',
    },
};

/**
 * Actually open the chatbot window. 
 */
 export const launchChatbotWindow = (evt) => {
   
    if (evt.scrolledPastInPageSSI) {
        window.scrollTo({
            top: 0,
            behavior: 'smooth'
        });
    }
    
    // track chatbot opens
    const analyticsObj = {};
    analyticsObj[CHATBOT_USER_OPEN_KEY] = true;

    if (window.utag) window.utag.view(analyticsObj);
    if (window.dataLayer && CHATBOT_USER_OPEN_KEY) {     
        analyticsObj.event = CHATBOT_USER_OPEN_KEY;
        window.dataLayer.push(analyticsObj);
    }
    
    window.postMessage(JSON.stringify(chatbotPostMessageData), '*'); //this will want to be made dynamic/specific to domains

    if (evt.callback) evt.callback();
}