import React from 'react';
import styled from 'styled-components';
import * as colors from '../../util/colors';
import { Text } from '../../util/typography';
import { StaticImage } from 'gatsby-plugin-image';
import { setPropsFromURL } from '../../util/CTA';
import yourPrivacyChoices from '../../assets/yourprivacychoices.svg';

const MOBILE_BREAKPOINT = '420px';

const LinkWrapper = styled.div`
    display: flex;
    align-items: center;
    padding: 10px 20px 20px 20px;
    max-width: 1032px;
    margin: 0 auto;

    & hr {
        flex: 1 1 auto;
        min-width: 16px;
        border: 0;
        border-bottom: 1px solid ${colors.slate};
    }

    & div {
        display: flex;
        justify-content: space-evenly;
        width: 100%;
        flex-wrap: wrap;
        max-width: 840px;
    }

    & span {
        white-space: nowrap;
        width: auto;
        padding-bottom: 6px;
    }

    @media (max-width: ${MOBILE_BREAKPOINT}) {
        display: block;

        & div {
            margin-top: 14px;
            display: block;
        }

        & a {
            display: block;
            margin-bottom: 6px;
        }

        & button {
            display: block;
            margin-bottom: 6px;
        }

        & a:last-child {
            margin-bottom: 0;
        }
    }
`;

const FooterContainer = styled.div`
    background-color: ${colors.ink};
    width: 100%;
`;

const FooterWrapper = styled.div`
    background-color: ${colors.ink};
    color: ${colors.white};
    padding: 20px 20px 0;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    max-width: 1032px;
    margin: 0 auto;
    justify-content: space-between;

    span {
        color: ${colors.silver};
    }

    > div {
        min-width: 280px;
        margin-bottom: 20px;
    }

    p {
        margin-bottom: 0;
    }
`;

const FooterLeftContainer = styled.div`
    flex-basis: 485px;
`;

const FooterRightContainer = styled.div`
    display: flex;
    flex-direction: column;
    
    @media (min-width: 790px) {
        flex-direction: row;
    }

    > div:first-child   {
        margin: 0px 20px 15px 0;

        @media (min-width: 790px) {
            margin: 0;
        }
    }

    > div:last-child {

        > a {
            display: block;
            max-width: 100px;
            float: left;

            &:first-child {
                margin-right: 20px;
            }
        }
    }

    > * {

        img {
            object-fit: contain;
            max-width: 100%;
            display: block;
        }
    }
`;

const FirstGroupLogoContainer = styled.div`
    display: flex;
    flex-direction: column;

    @media (min-width: 790px) {
        flex-direction: row;
        align-items: center;

        > div:nth-child(2)   {
            margin-right: 20px;
        }
    }
`;

const StyledFooterLink = styled.a`
    text-decoration: none;
    span {
        color: ${colors.sky};
    }
`;

const StyledFooterButton = styled(Text.Footnote).attrs({ as: 'button' })`
    text-decoration: none;
    color: ${colors.sky};
    padding: 0;
    margin: 0;
    border: 0;
    background: transparent;
    appearance: none;
    -webkit-appearance: none;
    position: relative;
    top: 1px;
    cursor: pointer;

    span {
        color: inherit;
    }
`;

const TopHr = styled.hr`
    @media (max-width: ${MOBILE_BREAKPOINT}) {
        margin: 0;
    }
`;

const BottomHr = styled.hr`
    @media (max-width: ${MOBILE_BREAKPOINT}) {
        display: none;
    }
`;

const TrusteLink = styled.a`
    text-decoration: none;
    
    > img {
        border: none;
    }
`

const AccessibilityIconLink = styled.a`
    margin: 10px 0 30px 0;

    @media (min-width: 790px) {
        margin: 0 40px 0 0;
    }
`

const JobCode = styled.div`
    margin-top: 1em;
`;

const TrusteLinkContainer = styled.div`
    width: 22px;
`;

const FooterLegal = (props) => {

    return (
        <>
            <LinkWrapper>
                <TopHr />
                <div>
                    <StyledFooterLink href="https://www.msdprivacy.com/us/en/" data-design-category='footer_ref_vd' target="_blank">
                        <Text.Footnote theme="light">Privacy Policy</Text.Footnote>
                    </StyledFooterLink>
                    <StyledFooterLink href="https://www.merck.com/terms-of-use/" data-design-category='footer_ref_vd' target="_blank">
                        <Text.Footnote theme="light">Terms of Use</Text.Footnote>
                    </StyledFooterLink>
                    <StyledFooterLink {...setPropsFromURL('/sitemap/')} data-design-category='footer_ref_vd'>
                        <Text.Footnote theme="light">Site Map</Text.Footnote>
                    </StyledFooterLink>
                    <StyledFooterButton data-design-category='footer_ref_vd' className="ot-sdk-show-settings">
                        <Text.Footnote theme="light">Cookie Preferences</Text.Footnote>
                    </StyledFooterButton>
                    <StyledFooterLink href="https://www.msdaccessibility.com/" data-design-category='footer_ref_vd' target="_blank">
                        <Text.Footnote theme="light">Accessibility</Text.Footnote>
                    </StyledFooterLink>
                    <StyledFooterLink href="https://dsr.msd.com/DSR/United_States/English/" data-design-category='footer_ref_vd' target="_blank">
                        <Text.Footnote theme="light">Your Privacy Choices</Text.Footnote>
                        <img src={yourPrivacyChoices} style={{ marginLeft: '6px' }} />
                    </StyledFooterLink>
                    <StyledFooterLink href="https://www.msdprivacy.com/us/en/chd-policy/" data-design-category='footer_ref_vd' target="_blank">
                        <Text.Footnote theme="light">Consumer Health Data Privacy Policy</Text.Footnote>
                    </StyledFooterLink>
                </div>
                <BottomHr />
            </LinkWrapper>
            <FooterContainer>
                <FooterWrapper>
                    <FooterLeftContainer>
                        <Text.Footnote theme="light">
                            This site is intended for health care professionals of the United States, its territories, and Puerto Rico. Copyright © 2024 Merck & Co., Inc., Rahway, NJ, USA and its affiliates. All rights reserved.
                        </Text.Footnote>
                        <JobCode>
                            <Text.Footnote theme="light">{props.jobCode}</Text.Footnote>
                        </JobCode>
                    </FooterLeftContainer>
                    <div>
                        <FooterRightContainer>
                                <FirstGroupLogoContainer>
                                    <AccessibilityIconLink href="https://www.essentialaccessibility.com/merck/" target="_blank"><StaticImage height={32} width={84} alt="Level Access Company Logo" src="../../assets/e-a-logo-e-a-hor-white-v-1@2x.png" /></AccessibilityIconLink>
                                    <div><StaticImage height={33} width={113} alt="Merck Logo" src="../../assets/hcp-opt-merck-logo-reversed.png" /></div>
                                </FirstGroupLogoContainer>

                                {/* We have to keep this in for now to avoid resubmission across the entire site */}
                                <TrusteLinkContainer>
                                    {/* Temporary fix for screenshots: TRUSTe hosted images (1px transparent) were causing hang and blocking screenshots */}
                                    {/* <TrusteLink href="" target="_blank"> */}
                                        {/* <img src="//privacy-policy.truste.com/privacy-seal/seal?rid=0077ae82-f489-452b-a850-0e896f5a1566" alt="TRUSTe Verified Privacy" /> */}
                                        {/* <StaticImage src="../../assets/1px_transparent.png" alt="" layout="fixed" width={1} height={1} /> */}
                                    {/* </TrusteLink> */}
                                    
                                    {/* <TrusteLink href="" target="_blank"> */}
                                        {/* <img src="//privacy-policy.truste.com/privacy-seal/seal?rid=11c1aa82-b7e7-4a2f-b02e-ba935e661720" alt="TRUSTe APEC Privacy" /> */}
                                        {/* <StaticImage src="../../assets/1px_transparent.png" alt="" layout="fixed" width={1} height={1} /> */}
                                    {/* </TrusteLink>    */}
                                </TrusteLinkContainer>
                           
                        </FooterRightContainer>
                    </div>
                </FooterWrapper>
            </FooterContainer>
        </>
    );
};

export default FooterLegal;
