import React, { useState } from 'react';
import styled from 'styled-components';
import * as CTA from '../../util/CTA';
import * as colors from '../../util/colors';


const Container = styled.a`
    text-decoration: none;
    cursor: pointer;
    display: flex;
    flex-direction: column;
    padding: 30px 20px 20px;
    border-radius: 5px;
    border: 1px solid ${colors.pebble};
    background: white;
    transition: box-shadow 0.3s ease-in-out;
    height: 154px;
    &:hover {
        border-color: ${colors.white};
        box-shadow: 0 4px 20px 0 rgba(0, 0, 0, 0.16);
    }
    max-width:420px;
    margin: 0 auto;
`;

const ImageWrapper = styled.div`
    pointer-events: none; //resolves issue where hovering over image 'stops' onMouseOver for the Card
    
    img {
        width: 100%;
        height: 100%;
        display: block;
        margin-bottom: 10px;
    }
`;

const Link = styled(CTA.TertiaryButton)`
    margin-top: auto;
`;

const Card = props => {
    const [isCardHovered, setIsCardHovered] = useState(false);
    const onCardMouseEnter = () => {
        setIsCardHovered(true);
    };

    const onCardMouseLeave = () => {
        setIsCardHovered(false);
    };

    return (
            <Container href={props.url} onMouseEnter={onCardMouseEnter} onMouseLeave={onCardMouseLeave} {...CTA.setPropsFromURL(props.url)} data-design-category="button_vd">
                <ImageWrapper>
                    {typeof props.image === 'function' && props.image()}
                </ImageWrapper>
                <Link forwardedAs="span" label={props.label} url={props.url} parentHovered={isCardHovered} />
            </Container>
    );
};

export default Card;
