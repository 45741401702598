import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import IconListItemIconColumn from './IconColumn';
import IconListItemContentColumn from './ContentColumn';
import { createLocalScaleFunction } from '../../util/functions';
import { charcoal, ink, keytrudaGreen } from '../../util/colors';
import ComponentWrapper from '../layout/ComponentWrapper';
import { NotationBlock } from '../../util/typography';

const ItemWrapper = styled.div`
    --primaryText: ${({ theme }) => (theme === 'light' ? ink : 'white')};
    --secondaryText: ${({ theme }) => (theme === 'light' ? charcoal : 'white')};
    --borderColor: ${({ theme }) => (theme === 'light' ? keytrudaGreen : 'white')};
    --iconSize: 60px;
    padding: ${createLocalScaleFunction(30, 320, 40, 992)} 0;
    border-top: 1px solid var(--borderColor);
    color: var(--primaryText);

    &:first-child {
        padding-top: 0;
        border-top: 0;
    }

    &:last-child {
        padding-bottom: 0;
    }
`;

const ItemRow = styled.div`
    --multiplier: calc(768px - 100%);
    --childPadding: 20px;
    display: flex;
    flex-wrap: wrap;
    margin: 0 calc(-1 * var(--childPadding));
`;

const IconListItem = ({ iconColumn, contentMarkup, children, theme }) => {
    return (
        <ItemWrapper theme={theme}>
            <ItemRow>
                <IconListItemIconColumn {...iconColumn} />
                <IconListItemContentColumn>
                    {contentMarkup ? contentMarkup : children}
                </IconListItemContentColumn>
            </ItemRow>
        </ItemWrapper>
    );
};

IconListItem.propTypes = {
    iconColumn: PropTypes.shape({ ...IconListItemIconColumn.prototypes }),
    children: PropTypes.node,
};

const IconListWrapper = styled.div`
    padding: ${createLocalScaleFunction(10, 320, 20, 992)} 0;

    &:first-child {
        padding-top: 0;
    }

    &:last-child {
        padding-bottom: 0;
    }
`;

const IconList = ({ data, theme, footnotes, definitions }) => {
    return (
        <ComponentWrapper>
            <IconListWrapper>
                {data.map((listItemData, i) => (
                    <IconListItem {...listItemData} key={`iconItem-${i}`} theme={theme} />
                ))}
            </IconListWrapper>
            {(footnotes || definitions) && <NotationBlock footnotes={footnotes} definitions={definitions} />}
        </ComponentWrapper>
    );
};

IconList.propTypes = {
    data: PropTypes.arrayOf(
        PropTypes.shape({
            ...IconListItem.prototypes,
        })
    ),
};

export default IconList;
export { IconListItem };
