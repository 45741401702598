import React, { useRef } from 'react';
import styled from 'styled-components';
import logo from '../../assets/keytruda-logo.png';
import * as colors from '../../util/colors';
import Link from 'gatsby-link';

// These are shared locally, and only matter for local components. No need to share outside this component.
const nav_bottom_border_size = '2px';
const inner_flex_nav_z_index = 3;

const StyledMinimalHeader = styled.nav`
    width: 100%;
    background: ${colors.white};
    position: sticky;
    border-bottom: ${nav_bottom_border_size} solid ${colors.silver};
    z-index: 0;

    > .inner-flex {
        justify-content: space-between;
        box-sizing: border-box;
        padding: 0 20px;
        max-width: 1032px;
        margin: 0 auto;

        position: relative;
        background-color: ${colors.white};
        z-index: ${inner_flex_nav_z_index};
    }
`;

const LogoContainer = styled(Link)`
    padding: clamp(10px, 2vw, 16px) 0;
    flex: 2 1 auto;
    display: grid;
    align-items: center;
    justify-content: center;
    > img {
        width: 100%;
        max-width: 160px;
        display: block;
    }
`;

const MinimalHeader = (props) => (
    <StyledMinimalHeader>
        <div className="inner-flex">
            <LogoContainer
                title="Home"
                data-design-category="top_nav_vd"
            >
                <img src={logo} alt="KEYTRUDA® (pembrolizumab): Website for Health Care Professionals" />
            </LogoContainer>
        </div>
    </StyledMinimalHeader>
);

export default MinimalHeader;
