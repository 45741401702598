import React from 'react';
import styled from 'styled-components';
import Card from './Card';

const RowContainer = styled.div`
    display: flex;
    flex-wrap: wrap;
    align-items: stretch;
    margin: 0 -10px;

    > div {
        flex-grow: 1;
        flex-shrink: 1;
        padding: 10px;
        box-sizing: border-box;
        min-width: 280px;
        display: grid;
    }

    ${({ type }) =>
        type === '50/50' &&
        `
        > div {
            flex-basis: 50%;
        }
    `}

    ${({ type }) =>
        type === '33/33/33' &&
        `
        > div {
            flex-basis: 33.3%;
        }
    `}

    ${({ type }) =>
        type === '70/30' &&
        `
        > div:first-child {
            flex-shrink: 2;
            flex-grow: 30;
            flex-basis: 33.3%;
        }

        > div:last-child {
            flex-grow: 0;
            flex-shrink: 1;
            flex-basis: 33.3%;
        }
    `}
`;

const Row = ({ type, rowTier, items, rowKey }) => {
    return (
        <RowContainer type={type}>
            {items.map((item, index) => {
                return (
                    <div key={index}>
                        <Card {...item} rowKey={rowKey} colKey={index} rowTier={rowTier} />
                    </div>
                );
            })}
        </RowContainer>
    );
};

export default Row;
