import React from 'react';
import styled from 'styled-components';
import { Heading, Text } from '../../util/typography';
import PropTypes from 'prop-types';
import * as colors from '../../util/colors';
import ComponentWrapper from '../layout/ComponentWrapper';
import DefinitionListItem, { StyledDefinitionListItem } from './DefinitionListItem';
import {componentTheme} from '../../util/functions';
import { NotationBlock } from '../../util/typography';

const StyledDefinitionList = styled.div`
    --textPrimary: ${props => (props.theme === 'light' ? 'white' : colors.ink)};
    --textSecondary: ${props => (props.theme === 'light' ? 'white' : colors.charcoal)};
    --textTertiary: ${props => (props.theme === 'light' ? 'white' : colors.keytrudaGreenWeb)};
    --borderColor: ${props => (props.theme === 'light' ? 'rgba(255,255,255,0.2)' : colors.keytrudaGreen)};
    --sectionBackground: ${props =>
        props.theme === 'light'
            ? 'linear-gradient(to left, rgba(38, 38, 38, 0), rgba(38, 38, 38, 0.2) 8%, rgba(38, 38, 38, 0.2) 92%, rgba(38, 38, 38, 0) 100%);'
            : 'linear-gradient(to left, rgba(235, 234, 235, 0), #ebeaeb 8%, #ebeaeb 92%, rgba(235, 234, 235, 0) 100%)'};
`;

const ListTitle = styled(Heading.H4)`
    margin-bottom: 20px;
`;

const ListSection = styled.div`
    &:last-child ${StyledDefinitionListItem}:last-child {
        padding-bottom: 0;
    }
`;

const ListSectionTitle = styled(Text.Eyebrow)`
    display: block;
    padding: 10px 0;
    background: var(--sectionBackground);
    border-top: 1px solid var(--borderColor);
    color: var(--textTertiary);
`;

const DefinitionList = props => {
    let theme = componentTheme(true, props.sectionTheme, props.theme);

    return (
        <ComponentWrapper>
            <StyledDefinitionList theme={theme}>
                <ListTitle as='H3' theme={theme}>{props.title}</ListTitle>
                {props.sections && props.sections.map((section, index) => (
                        <ListSection key={`section-${index}` } theme={theme}>
                            {/* optional section title */}
                            {!!section.title && (
                                <ListSectionTitle theme={theme}>{section.title}</ListSectionTitle>
                            )}
                            {/* list items */}
                            <div>
                                {section.items.map((item, index) => (
                                    <DefinitionListItem key={index} title={item.title} content={item.content} theme={theme}/>
                                ))}
                            </div>
                        </ListSection>
                    ))}
            </StyledDefinitionList>
            {(props.footnotes || props.definitions) && <NotationBlock footnotes={props.footnotes} definitions={props.definitions} />}
        </ComponentWrapper>
    );
};

DefinitionList.propTypes = {
    title: PropTypes.string.isRequired,
    sections: PropTypes.array.isRequired,
};

export default DefinitionList;
