import { useEffect } from 'react';

export const KEY_CODES = {
  Escape: 27,
  Enter: 13,
  ArrowUp: 38,
  ArrowDown: 49,
};

const useKeydownHandler = (callback, key = '') => {
  useEffect(() => {
    const handleKeydown = (event) => {
      if (event.keyCode === KEY_CODES[key]) {
        callback(event);
      }
    };

    document.addEventListener('keydown', handleKeydown);

    return () => {
      document.removeEventListener('keydown', handleKeydown);
    };
  }, [callback, key]);
};

export default useKeydownHandler;