import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { Heading, Text } from '../../util/typography';
import * as colors from '../../util/colors';

const ChartWrapper = styled.div`
    position: relative;
    display: block;
    text-align: center;
    opacity: 0;
    transform: translateY(-10px);
    transition: transform 0.3s cubic-bezier(0.43, -0.04, 0.49, 1.51) 0.3s, opacity 0.5s ease 0.3s;
    ${props => props.extendedStyles || ''};
    margin: 0 auto;

    &.active {
        opacity: 1;
        transform: translateY(0);
    }
`;

const ChartAndLabel = styled.div`
    position: relative;
    max-width: 222px;
    width: 100%;
    margin: 0;
`;

const ChartLabels = styled.div`
    position: absolute; // Dan, this one is for old time sajke and for you! :)
    top: 32%;
    left: 20%;
    right: 20%;
    text-align: center;
`;

const Label = styled(Text.Footnote)`
    font-weight: 400;
    color: ${colors.slate};
    max-width: 120px;
    letter-spacing: 0.5px;
`;

const Chart = props => {
    const ProportionColorMap = props.ProportionColorMap;
    const DonutStack = props.DonutStack;
    const size = 100;
    const [active, setActive] = useState(false);

    useEffect(() => {
        setActive(true); // this may not be necessary, not oh well, here is a load in animation...set useState default to true above and romove useEffect() to turn it off
    }, []);

    let filled = 0;

    return (
        <ChartWrapper extendedStyles={props.extendedStyles} className={active ? 'active' : ''}>
            <ChartAndLabel>
                <svg width="100%" height="100%" viewBox={`0 0 ${size} ${size}`}>
                    {DonutStack.Proportions.map((proportion, proportionIndex) => {
                        const isLast =
                            proportionIndex === DonutStack.Proportions.length - 1 ? true : false;
                        const color =
                            proportion.ColorOverride ||
                            ProportionColorMap[DonutStack.Theme][proportionIndex];
                        const startAngle = -90;
                        const strokeWidth = size / 10 - 1;
                        const radius = size / 2 - strokeWidth;
                        const cx = 50;
                        const cy = 50;
                        const dashArray = 2 * Math.PI * radius;
                        const dashOffset =
                            dashArray - (dashArray * parseFloat(proportion.Percent)) / 100;
                        const angle = (filled * 360) / 100 + startAngle;

                        filled += parseFloat(proportion.Percent);

                        const emptyDashOffset = isLast
                            ? dashArray - (dashArray * (100 - filled)) / 100
                            : 0;
                        const emptyAngle = isLast ? (filled * 360) / 100 + startAngle : 0;

                        return (
                            <>
                                <circle
                                    key={proportionIndex}
                                    r={radius}
                                    cx={cx}
                                    cy={cy}
                                    fill="transparent"
                                    stroke={color}
                                    strokeWidth={strokeWidth}
                                    strokeDasharray={dashArray}
                                    strokeDashoffset={dashOffset}
                                    transform={`rotate(${angle} ${cx} ${cy})`}
                                ></circle>

                                {/* We need a fill color to cover the empty space in the charts */}
                                {isLast && (
                                    <circle
                                        key={proportionIndex}
                                        r={radius}
                                        cx={cx}
                                        cy={cy}
                                        fill="transparent"
                                        stroke={DonutStack.EmptyProportionColor || colors.silver}
                                        strokeWidth={strokeWidth}
                                        strokeDasharray={dashArray}
                                        strokeDashoffset={emptyDashOffset}
                                        transform={`rotate(${emptyAngle} ${cx} ${cy})`}
                                    ></circle>
                                )}
                            </>
                        );
                    })}
                </svg>

                <ChartLabels>
                    <Heading.H2>{DonutStack.PercentLabel}</Heading.H2>
                    {/* NOTE! - Zeplin calls for the "eyebrow" class below, there is a font styling discrepency between this and what the actual font size in Zeplin actually is -> 12px vs 14px */}
                    <Label>{DonutStack.TypeLabel}</Label>
                </ChartLabels>
            </ChartAndLabel>
        </ChartWrapper>
    );
};

export default Chart;
