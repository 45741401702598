import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { caratRightSmallBlueberry } from '../../../util/icons';
import { Text } from '../../../util/typography';
import * as colors from '../../../util/colors';
import Link from 'gatsby-link';

const MenuLabel = styled(Text.Button)`
    line-height: 1.13;
    flex: 2 auto;
    color: inherit;
`;

const StyledMenuLink = styled(Link).withConfig({
    shouldForwardProp: prop => !['hasChildren', 'isOpen', 'inActivePath', 'activePath', 'dataDesignCategory'].includes(prop),
})`
    padding: 13px 20px;
    display: flex;
    flex-flow: row nowrap;
    flex: 1 1 100%;
    text-decoration: none;
    color: ${colors.charcoal};
    align-items: flex-start;
    text-align: left;
    align-items: center;

    -webkit-appearance: none;
    appearance: none;
    border: 0;
    background: transparent;

    ${({ hasChildren, inActivePath, level }) => {
        let styles = ``;
        if (hasChildren) {
            styles += `&::after {
                display: block;
                content: "";
                margin: 0 5px;
                width: 10px;
                height: 10px;
                background-image: url(${caratRightSmallBlueberry});
                background-size: contain;
                background-repeat: no-repeat;
                background-position: center;
            }`;
        }

        if (inActivePath) {
            styles += `
                border-left: 4px solid ${colors.keytrudaGreen};
                padding-left: 16px;
            `;
        }

        if (level === "1") {
            styles += `
                &.active {
                    border-left: 4px solid ${colors.keytrudaGreen};
                    padding-left: 16px;
                }
            `;
        }

        return styles;
    }}
`;

const MenuLink = ( props ) => {
    // workaround for the fact that Gatsby Link doesn't seem to allow you to pass it's active class to a non-link (like our spans)
    let [inActivePath, setInActivePath] = useState(false);

    useEffect(() => {
        // set 'active' indicator
        if (typeof window !== 'undefined' && window.origin !== 'null') { //check to avoid errors in Gatsby build and in DSM's sandboxed iframe
            setInActivePath(window.location.pathname.startsWith(props.activePath));
        }
    },[props.activePath])
 
    const linkProps = {
        as: props.hasChildren ? 'span' : undefined,
        to: props.url,
        inActivePath: inActivePath,
        'data-design-category': 'top_nav_vd',
        ...props
    };
    
    //avoid errors for adding Link props to non-links (eg the parent spans)
    if (!props.hasChildren) {
        linkProps.activeClassName = 'active';
    }

    //filter out the line breaks needed for the large screen display
    let label = props.label.replace(/<[^>]*>?/gm, '');
    return (
        <StyledMenuLink
            {...linkProps}
        >
            <MenuLabel dangerouslySetInnerHTML={{ __html: label }} />
        </StyledMenuLink>
    );
};
export default MenuLink;
