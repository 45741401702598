import React from 'react';
import styled from 'styled-components';
import * as colors from '../../util/colors';
import { WYSIWYG, NotationBlock, Heading } from '../../util/typography';
import ComponentWrapper from '../layout/ComponentWrapper';
import * as CTA from '../../util/CTA';
import { componentTheme, createMarkup } from '../../util/functions';
import { StaticImage } from 'gatsby-plugin-image';

const Container = styled.div`
    // padding: clamp(20px, 6%, 40px) clamp(20px, calc(-18.96px + 13.91%), 84px) clamp(20px, 6%, 30px);
    // border-radius: 8px;
    // border: solid 1px ${colors.pebble};
    // background-color: ${colors.white};

    > div {
        margin: 0 auto;
        display: flex;
        flex-flow: row wrap;
    }
`;

const IconContainer = styled.div`
    flex: 0 0 94px;
    height: 94px;
    padding: 17px;
    border: 1px solid #bebebe;
    border-radius: 50%;
    margin: 0 30px 16px 0;
`;

const ContentContainer = styled.div`
    flex: 1 1 280px;
`;

const TextContainer = styled.div`
    margin-bottom: 16px;
`

const Title = styled(Heading.H4)`
    margin-bottom: 8px;
`;

const Description = styled(WYSIWYG)`
    margin-bottom: 8px;
`;

const CTAContainer = styled.div`
    margin-top: clamp(0px, .5vw, 10px);

    > a {
        display: inline-box;
        padding: 12px 22px;
        width: auto;
    }
`;

// @todo implement theme
const FeaturedCTA = props => {
    let theme = componentTheme(true, props.sectionTheme, props.theme);
    return (
        <>
            <Container>
                <div>
                    <IconContainer>
                        <img src="/static/images/badge-iv.svg" alt={props.iconAlt} width={60} height={60} />
                    </IconContainer>
                    <ContentContainer>
                        <TextContainer>
                            {props.title && <Title dangerouslySetInnerHTML={createMarkup(props.title)} />}
                            {props.description && <Description dangerouslySetInnerHTML={createMarkup(props.description)} />}
                        </TextContainer>

                        {props.ctas && props.ctas.length &&
                            <CTAContainer>
                                {props.ctas.map((cta, i) => <CTA.PrimaryButton key={i} {...cta} />)}
                            </CTAContainer>
                        }
                    </ContentContainer>
                </div>
            </Container>
            {(props.footnotes || props.definitions) && <NotationBlock footnotes={props.footnotes} definitions={props.definitions} theme={theme} />}
        </>
    );
};

export default FeaturedCTA;
