import React from 'react';
import styled from 'styled-components';
import { indicationsData, getCancerTypeIdFromIndicationId, createIndicationListEntryByCancerType } from './data';
import * as colors from '../../util/colors';
import { DEFAULT_FONT_FAMILY } from '../../util/typography/base';

import * as ISI from './styled';
import { createMarkup } from '../../util/functions';

//Just prior to launch, PRT mandated that in the in-page and drawer, the indications HAD to be formatted like a bulleted list. Rather than reformat the paragraph content in util/data/indications.js (which is used everywhere else), we use CSS here to fake the look
const IndicationsListContent = styled.div`
    font-family: ${DEFAULT_FONT_FAMILY};
    font-size: 16px;
    line-height: 22px;
    font-weight: 400;

    > *:first-child {
        padding-top:0;
        margin-top: 0;
    }

    > *:last-child {
        padding-top:0;
        margin-top: 0;
    }
    
    p {
        padding-left: calc(8px + 1.2em);
        margin: 10px 0;
        color: ${colors.charcoal};

        &::before {
            content: '';
            width: 8px;
            height: 2px;
            background-color: ${colors.keytrudaGreen};
            display: inline-block;
            margin: 0 1.2em 0 calc(-8px - 1.2em);
            vertical-align: middle;
        }

        /* SPECIAL, FRAGILE HANDLING FOR MSI-H */
        //MSI-H has a 'trailing paragraph' that should not have a bullet (as it semantically still 'belongs' to the first/only top level item). However we follow paragraph styling most other places, so it is coded as a <p>. 

        &.no-bullet::before {
            background-color: transparent;
        }
    }

    ul {
        padding: 0;
        margin: 10px 0 10px calc(8px + 1.2em);
        color: ${colors.charcoal};

        li {
            padding-left: calc(4px + 1.2em);
            list-style: none;
            margin: 10px 0;

            &::before {
                content: '';
                width: 4px;
                height: 4px;
                border-radius: 2px;
                background-color: ${colors.keytrudaGreen};
                display: inline-block;
                margin: 0 1.2em 0 calc(-4px - 1.2em);
                vertical-align: middle;
            }

            ul li::before {
                background-color: transparent;
                border: 1px solid ${colors.keytrudaGreen};
            }
        }
    }
`;

const IndicationsList = ({ columns = 1, scrollable_ref, indicationId }) => {
    //Because there will often be cases where we need to launch a 'standalone indication page' before the full content has been approved for global elements, we will pull content for 'singular indications' directly from the global array (rather than the list created in ./data.js).
    let indicationsListByCancerType = [];
    if (indicationId) {
        const cancerTypeId = getCancerTypeIdFromIndicationId(indicationId);
        indicationsListByCancerType = [createIndicationListEntryByCancerType(cancerTypeId)];
    } else {
        indicationsListByCancerType = indicationsData;
    }

    return (
        <ISI.InformationListContainer ref={scrollable_ref}>
            {indicationsListByCancerType
                .filter(item => (indicationId) ? item.indicationIds.includes(indicationId) : item)
                .map((item, i) => {
                let indicationsContent = item.items
                .filter(indication => (!indication.isCombination || indication.showOnISI))
                .map((child, i) => child.statement);

                return (

                    <ISI.InformationItem columns={columns} key={i}>
                        <header>
                            <ISI.InformationItemLabel dangerouslySetInnerHTML={createMarkup(item.modifierAndName)} as='h3'>
                                
                            </ISI.InformationItemLabel>
                        </header>
                        
                        <IndicationsListContent dangerouslySetInnerHTML={createMarkup(indicationsContent.join(''))} />
                    </ISI.InformationItem>
            )})}
        </ISI.InformationListContainer>
    );
};

export default IndicationsList;
