import React from 'react';
import styled from 'styled-components';
import { Heading, Text } from '../../util/typography';
import PropTypes from 'prop-types';
import ComponentWrapper from '../layout/ComponentWrapper';
import {componentTheme} from '../../util/functions';
import exclamation_icon from '../../assets/icons/exclamation-icon.svg';

const Title = styled(Heading.H4)`
    margin-bottom: 20px;
`;

const ArTitle = styled(Heading.H5)`
    margin-bottom: 20px;
`;

const ItemContainer = styled.div`
`

const ItemTextContainer = styled.div`
    display: flex;
    justify-content: row;

    :not(:first-child){
        margin-top: 14px;
    }

    &:before {
        display: block;
        content: '';
        width: 20px;
        height: 20px;
        background-image: url(${exclamation_icon});
        background-repeat: no-repeat;
        background-size: 20px 20px;
        flex: 0 0 20px;
    }
`
const ItemText = styled(Text.Body)`
        margin-left: 16px;
`

const createMarkup = content => {
    return { __html: content };
};

const ExclamationList = props => {
    let theme = componentTheme(true, props.sectionTheme, props.theme);
    return (
        <ComponentWrapper>

                    {props.arTitle ? 
                        <ArTitle as='H4' dangerouslySetInnerHTML={createMarkup(props.title)} />
                     : 
                        <Title as='H4' dangerouslySetInnerHTML={createMarkup(props.title)}/>
                    }
                    

                    <ItemContainer>
                        {props.items.map((item, index) => (
                            <ItemTextContainer>
                                <ItemText dangerouslySetInnerHTML={createMarkup(item.description)} theme={theme}/>
                            </ItemTextContainer>
                        ))}
                    </ItemContainer> 
        </ComponentWrapper>
    );
};

ExclamationList.propTypes = {
    title: PropTypes.string.isRequired,
    text: PropTypes.string.isRequired,
};

export default ExclamationList;
