import React from 'react';
import styled from 'styled-components';
import { menuBlueberry } from '../../util/icons';
import { Text } from '../../util/typography';
import * as colors from '../../util/colors';
import { NAVIGATION_BREAKPOINT } from '../../constants/layout';

const StyledMenuLaunchButton = styled.button.attrs(props => ({
    'data-design-category': props.dataDesignCategory || props['data-design-category']
}))`
    flex: 0 0 50px;
    background-color: transparent;
    border: none;
    background-image: url(${menuBlueberry});
    background-repeat: no-repeat;
    height: 60px;
    border-left: 2px solid ${colors.silver};
    padding: 13px 10px 12px;
    background-origin: content-box;
    background-position: center top 2px;
    cursor: pointer;
    display: flex;
    flex-flow: column nowrap;
    justify-content: flex-end;
    margin: 0;

    @media (min-width: ${NAVIGATION_BREAKPOINT}) {
        display: none;
    }
`;

const ButtonText = styled(Text.Button)`
    font-size: 12px;
    text-align: center;
    white-space: nowrap;
    line-height: 1;
`;

const MenuLaunchButton = ({ clickHandler }) => (
    <StyledMenuLaunchButton dataDesignCategory="bottom_nav_vd" onClick={clickHandler}>
        <ButtonText>Menu</ButtonText>
    </StyledMenuLaunchButton>
);

export default MenuLaunchButton;
