import React from 'react';
import styled from 'styled-components';
import { Heading, WYSIWYG, NotationBlock } from '../../util/typography';
import * as CTA from '../../util/CTA';
import * as colors from '../../util/colors';
import { createLocalScaleFunction, createMarkup } from '../../util/functions';
import { rgba } from 'polished';

const IndicationContainer = styled.div`
    display: flex;
    flex-wrap: wrap;
    padding: ${createLocalScaleFunction(20, 320, 30, 952)} 0
        ${createLocalScaleFunction(16, 320, 20, 952)} 0;
    border-top-width: 1px;
    border-top-style: solid;
    border-top-color: ${({ theme }) =>
        theme === 'light' ? rgba(colors.white, 0.4) : colors.pebble};
`;
const TitleWrapper = styled.div`
    padding: 0 50px 20px 0;
    flex: 1 1 200px;
`;
const Content = styled.div`
    flex: 2 1 480px;
    display: flex;
    flex-direction: column;
`;

// const IndicationTitle = styled(Heading.H5)`
//     margin-bottom: 16px;
// `;

const ItemContent = styled(WYSIWYG)``;

const ActionsWrapper = styled.div`
    display: flex;
    flex-wrap: wrap;
    margin-top: 20px;
    > * {
        margin-right: 32px;
    }
`;

const Indication = props => {
    const indicationTitle = (props.item.indicationModifier) ? `${props.item.indicationModifier} ${props.item.indicationName}` : props.item.indicationName;
    return (
        <IndicationContainer key={props.item.id} theme={props.theme}>
            <TitleWrapper>
                <Heading.H4 as='H3' theme={props.theme} dangerouslySetInnerHTML={createMarkup(indicationTitle)} />
            </TitleWrapper>
            <Content>
                <ItemContent theme={props.theme} dangerouslySetInnerHTML={createMarkup(props.item.content)} />
                {(props.item.definitions) && <NotationBlock definitions={props.item.definitions} />}
                <ActionsWrapper>
                    {props.item.ctaUrl && props.item.ctaLabel && (
                        <CTA.TertiaryButton
                            theme={props.theme}
                            label={props.item.ctaLabel}
                            url={props.item.ctaUrl}
                            disabled={props.item.ctaDisabled}
                        />
                    )}
                    <CTA.TertiaryButton theme={props.theme} label="Ask Merck" url="https://www.askmerckoncology.com"/>
                </ActionsWrapper>
            </Content>
        </IndicationContainer>
    );
};

export default Indication;
