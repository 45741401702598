import React from 'react';
import styled from 'styled-components';
import { Heading } from '../../util/typography';
import * as CTA from '../../util/CTA';
import Indication from './Indication';
import ComponentWrapper from '../layout/ComponentWrapper';
import { createLocalScaleFunction } from '../../util/functions';
import { indications } from '../../util/data/indications';
import { cancerTypes } from '../../util/data/cancerTypes';

const Header = styled.div`
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    margin-bottom: 30px;
    justify-content: space-between;
    > span {
        margin-bottom: ${createLocalScaleFunction(12, 320, 0, 952)};
        flex-basis: 536px;
    }
`;

// let cancerTypeId = cancerTypesByIndicationId[indicationId];

// const cancerTypesByIndicationId = {};
// Object.keys(cancerTypes).forEach(cancerTypeId => {
//     cancerTypes[cancerTypeId].indications.map(
//         indicationId => (cancerTypesByIndicationId[indicationId] = cancerTypeId)
//     );
// });

           

const FeaturedIndications = props => {

    
    return (
        <ComponentWrapper>
            <Header>
                <Heading.H2 as='H2' theme={props.theme}>
                    Featured Indication{props.items.length > 1 ? 's' : ''}
                </Heading.H2>
                <CTA.PrimaryButton theme={props.theme} label="View indications" url="/efficacy" />
            </Header>
            {props.items.map(item => {
                if (!item.indicationId) {
                    return false;
                }
                const indicationId = item.indicationId,
                cancerType = Object.values(cancerTypes).find(cancerType => cancerType.indications.includes(parseInt(indicationId))),
                cancerTypeId = cancerType.id;
                item.cancerTypeName = cancerTypes[cancerTypeId].name;
                item.cancerTypeModifier = cancerTypes[cancerTypeId].modifier;
                item.indicationName = indications[indicationId].name;
                item.indicationModifier = indications[indicationId].modifier;
                item.content = indications[indicationId].statement;
                item.definitions = indications[indicationId].definitions;
                return (
                    <Indication theme={props.theme} item={item} key={item.indicationId}></Indication>
            )})}
        </ComponentWrapper>
    );
};

export default FeaturedIndications;
