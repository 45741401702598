import React from 'react';
import styled from 'styled-components';
import { createLocalScaleFunction } from '../../util/functions';
import { Heading } from '../../util/typography';

import SafetyList from './SafetyList';
import IndicationsList from './IndicationsList';

const Container = styled.div`
    height: 100%;
    max-width: 864px;
    padding: 0 20px;
    margin: ${createLocalScaleFunction(40, 320, 60, 992)} auto 0;
    > section {
        > header {
            margin-bottom: ${createLocalScaleFunction(20, 280, 30, 824)};
        }
    }
`;

function IndicationAndSafetyPage({ inline_indications_ref, indicationId, safetyDataOverride }) {
    return (
        <Container>
            <section>
                <header>
                    <Heading.H3 as='h2'>Selected Indications for KEYTRUDA® (pembrolizumab)</Heading.H3>
                </header>
                <div>
                    <IndicationsList indicationId={indicationId} />
                </div>
            </section>
            <section ref={inline_indications_ref}>
                <header>
                    <Heading.H3 as='h2'>Selected Safety Information</Heading.H3>
                </header>
                <div>
                    <SafetyList safetyDataOverride={safetyDataOverride}/>
                </div>
            </section>
        </Container>
    );
}

export default IndicationAndSafetyPage;
