import React from 'react';
import styled from 'styled-components';
import * as colors from '../../util/colors';
import { Heading } from '../../util/typography';
import { ReactComponent as Equal } from '../../assets/icon-equals.svg';
import { ReactComponent as Divide } from '../../assets/icon-divide.svg';
import { ReactComponent as Multiply } from '../../assets/icon-multiply.svg';

const Container = styled.div`
    margin: 16px auto;
    display: flex;
    flex-flow: row wrap;
    align-items: stretch;
    justify-content: center;
    border-radius: 8px;
    overflow: hidden;

    > * {
        min-width: clamp(var(--min-width, 0px), (400px - 100%) * 999, 100% - 40px);
        display: flex;
        flex-flow: column;
        justify-content: center;
    }
`;

const Symbol = styled.span`
    display: inline-flex;
    position: relative;
    padding: 0 10px;
    margin: 10px auto;
    box-sizing: content-box;
    flex: 0 auto;
    align-items: center;
    justify-content: center;
    > span {
        display: inline-block;
        position: relative;
    }

    svg {
        display: block;
        position: relative;
        /* width: 100%; */
        /* top: 50%; */
        height: 100%;
        max-width: 100%;
    }
`;

const EqualSign = styled(Symbol)`
    width: 16px;
`;

const DivisionSign = styled(Symbol)`
    width: 20px;
`;

const MultiplicationSign = styled(Symbol)`
    width: 16px;
`;

const TextBox = styled(Heading.H7)`
    border: solid 1px ${colors.keytrudaGreen};
    flex: 1 10 100px;
    padding: 10px;
    text-align: center;
    border-radius: 4px;
`;

const Numerator = styled(TextBox)``;

const Denominator = styled(TextBox)``;

const SmallText = styled.span`
    font-size: 20px;
    font-weight: 300;
    text-align: center;
`;

const MultiplicationFactor = styled(SmallText)``;

const Result = styled(SmallText)``;

// @todo implement theme
const TestingEquation = props => {
    return (
        <Container>
            <Result>
                {props.result}
            </Result>
            <EqualSign><span><Equal/></span></EqualSign>
            <Numerator>
                {props.numerator}
            </Numerator>
            <DivisionSign><span><Divide/></span></DivisionSign>
            <Denominator>
                {props.denominator}
            </Denominator>
            <MultiplicationSign><span><Multiply/></span></MultiplicationSign>
            <MultiplicationFactor>100</MultiplicationFactor>
        </Container>
    );
};

export default TestingEquation;
