import React, { useContext } from 'react';
import styled from 'styled-components';

import { Text } from '../../util/typography';
import * as CTA from '../../util/CTA';
import * as colors from '../../util/colors';
import { useSelector, useDispatch } from 'react-redux';
import {
    openFilter,
    closeFilter,
    openOverlay,
    closeOverlay,
    preventScrolling,
    allowScrolling,
} from '../../store/actions';
import { checkAndTriggerCancerTypeChange } from '../../util/functions';
import { FilterContext } from '../FilterProvider';

export const StyledResetFilterPrompt = styled.button.attrs(props => ({
    'data-design-category': props.dataDesignCategory || props['data-design-category'] || 'button_vd'
}))`
    flex: 0 auto;
    white-space: nowrap;
    background: none;
    border: none;
    border-radius: 4px;
    color: ${colors.blueberry};
    padding: 4px 0;
    margin: 0;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;

    > span {
        display: block;
        color: currentColor;
        line-height: 1;
        padding-top: 0.2em;
    }

    &[disabled] {
        color: ${colors.slate};
        pointer-events: none;
    }

    &:hover {
        color: ${colors.blueberryDark};
    }
`;

export const ResetFilterPrompt = ({forwardedRef, ...props}) => {
    const { filteredCancerTypes } = useContext(FilterContext);

    return (
        <StyledResetFilterPrompt {...props} ref={forwardedRef} disabled={!filteredCancerTypes.length ? true : false}>
            <Text.Button>{props.label || 'Clear'}</Text.Button>
        </StyledResetFilterPrompt>
    );
};

export const ApplyButton = props => {
    const dispatch = useDispatch();

    const filterOpen = useSelector(state => state.filterOpen);
    const { filteredCancerTypes } = useContext(FilterContext);

    const handleToggleClick = () => {
        if (!filterOpen) {
            dispatch(openFilter());
            dispatch(openOverlay());
            dispatch(preventScrolling());
        } else {
            dispatch(closeFilter());
            dispatch(closeOverlay());
            dispatch(allowScrolling());
            checkAndTriggerCancerTypeChange();
        }
    };

    // const disabled = filteredCancerTypes.length === 0;
    const label = `Apply Filters ${
        filteredCancerTypes.length ? `( ${filteredCancerTypes.length} )` : `( All )`
    }`;

    return (
        <CTA.PrimaryButton
            as='button'
            onClick={() => {
                handleToggleClick();
            }}
            // disabled={disabled}
            label={label}
        />
    );
};
