import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Text } from '../../util/typography';
import * as colors from '../../util/colors';
import Card from './Card';
import { _EFFICACY, efficacyData } from './data';
import Link from 'gatsby-link';
import { createMarkup } from '../../util/functions';

const Container = styled.div`
    padding-top: 36px;
`;

const SectionHeading = styled(Text.Body).attrs({as: 'h3'})`
    display:block;
    margin: 0 0 16px;
    color: ${colors.ink};
`;

export const LinkText = styled(Text.Body)`
    text-decoration: none;
    color: ${colors.blueberry};
    display: block;
    &:hover {
        cursor: pointer;
        color: ${colors.blueberryDark}
    }
`;

const StyledLink = styled(Link).attrs(props => ({
    'data-design-category': props.dataDesignCategory || props['data-design-category'] || 'content_link_vd'
}))`
    display: block;
    text-decoration: none;
    margin-bottom: 14px;
`;

const CrossLinkSection = props => {
    const { heading, items, showAsCard } = props;
    return (
        <Container>
            <SectionHeading>{heading}</SectionHeading>
            {!showAsCard &&
                items.map((item, index) => (
                    <StyledLink 
                        key={index}
                        // key={`${dataType}-${index}`} 
                        to={item.url || '#'}>
                        <LinkText dangerouslySetInnerHTML={createMarkup(item.text)} />
                    </StyledLink>
                ))}
            {showAsCard &&
                items.map((item, index) => (
                    <Card
                        key={index}
                        // key={`${dataType}-${index}`}
                        url={!item.links ? (item.url || '#') : null}
                        eyebrow={item.eyebrow}
                        text={!item.links ? item.text : null}
                        target={!item.links ? item.target : null}
                        links={item.links || []}
                    />
                ))}
        </Container>
    );
};

CrossLinkSection.propTypes = {
    heading: PropTypes.string,
    // data: PropTypes.array(RailDataItem.propTypes || RailDataCardItem.propTypes),
    items: PropTypes.arrayOf(
        PropTypes.shape({
            text: PropTypes.string.isRequired,
            url: PropTypes.string.isRequired,
            // this is needed for the card
            subHeadline: PropTypes.string,
        })
    ),
    // dataType: PropTypes.oneOf(['efficacy', 'related', 'intereseted']),
    showAsCard: PropTypes.bool,
};

CrossLinkSection.defaultProps = {
    heading: _EFFICACY,
    items: efficacyData,
    // dataType: 'efficacy',
    showAsCard: false,
};
/*

RailDataItem.propTypes = {
    text: PropTypes.string.isRequired,
    url: PropTypes.string.isRequired,
};

RailDataItem.defaultProps = {
    text: 'Rail Item',
    url: '#',
};

RailDataCardItem.propTypes = {
    ...RailDataItem.propTypes,
    subHeadline: PropTypes.string.isRequired,
};

RailDataCardItem.defaultProps = {
    ...RailDataItem.defaultProps,
    subheadline: _BIOMARKER,
};

*/

export default CrossLinkSection;
