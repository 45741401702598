import React from 'react';
import styled from 'styled-components';
import * as colors from '../../util/colors';
import { Heading } from '../../util/typography';
import { between } from 'polished';
import PropTypes from 'prop-types';
import { createMarkup, stringToSlug } from '../../util/functions';

const StyledSectionWrapper = styled.section`
    padding: 40px 0;

    @media (min-width: 321px) and (max-width: 1032px) {
        padding: ${between('40px', '60px', '320px', '1032px')} 0;
    }

    @media (min-width: 1033px) {
        padding: 60px 0;
    }
`;

const ContentContainer = styled.div`
    max-width: ${({maxWidth}) => `${maxWidth + 40}px`};
    margin: 0 auto;
    padding: 0 20px;
    box-sizing: border-box;
`;

const ComponentContainer = styled.div`
    > *:first-child {
        margin-top: 0;
        padding-top: 0;
    }

    > *:last-child {
        margin-bottom: 0;
        padding-bottom: 0;
    }
`;

const AnchorTarget = styled.span`
    display: block;
    height: 0;
    overflow: hidden;
    line-height:none;
    transform: translateY(-155px); //manage offset for fully-expanded sticky nav
`;


//Styled-components '&' selector only references the actual Component - not dynamic props - so this sturcture is needed to allow us to collapse visual padding between two adjacent Sections with the same color background.
const WhiteSection = styled(StyledSectionWrapper)`
    background: ${colors.white};

    & + & {
        padding-top: 0;
    }
`;

const DarkSection = styled(StyledSectionWrapper)`
    background: ${colors.charcoal};

    & + & {
        padding-top: 0;
    }
`;

const MistSection = styled(StyledSectionWrapper)`
    background: ${colors.mist};

    & + & {
        padding-top: 0;
    }
`;

const CloudSection = styled(StyledSectionWrapper)`
    background: ${colors.cloud};

    & + & {
        padding-top: 0;
    }
`;

const GreenSection = styled(StyledSectionWrapper)`
    background: ${colors.keytrudaGreen};

    & + & {
        padding-top: 0;
    }
`;

const GradientSection = styled(StyledSectionWrapper)`
    background: radial-gradient(circle at 23% 0, ${colors.blueberry}, ${colors.keytrudaGreenWeb} 86%);

    & + & {
        padding-top: 0;
    }
`;

const SectionWrapper = props => {
    switch (props.bgColor) {
        case 'white':
            return <WhiteSection {...props} />  
        case 'mist':
            return <MistSection {...props} />
        case 'cloud':
            return <CloudSection {...props} />
        case 'keytrudaGreen':
            return <GreenSection {...props} />
        case 'gradient':
            return <GradientSection {...props} /> 
        case 'charcoal':
            return <DarkSection {...props} />    
        default:
            return <WhiteSection {...props} />  
    }
}

//the background color option should be mapped to a 'light' or 'dark' theme variable, which is passed to child components. We keep this descriptive: the SECTION is either dark or light theme, different child components will handle that context differently.
const mapBackgroundToTheme = bgColor => {
    if (!bgColor) return 'light';

    switch (bgColor) {
        case 'white':
            return 'light';
        case 'mist':
            return 'light';
        case 'cloud':
            return 'light';
        case 'keytrudaGreen':
            return 'light';
        case 'gradient':
            return 'dark';
        default:
            return 'light';
    }
};

//Like Typography, Page Section spacing needs to be consistent across ANY page type (regardless of whether the template is two columns, one full width column, etc) so this is a case where vw-based media queries are appropriate
const PageSectionTitle = styled(Heading.H2)`
    text-align: ${({ $centerTitle }) => ($centerTitle ? 'center' : 'left')};
    margin-bottom: 20px;
/* 
    @media (min-width: 321px) and (max-width: 1032px) {
        margin-bottom: ${between('20px', '30px', '320px', '1032px')};
    }

    @media (min-width: 1033px) {
        margin-bottom: 30px;
    } */
`;


const PageSection = (props) => {

    let sectionTheme = mapBackgroundToTheme(props.bgColor);
    // pass Section theme (dark or light, referring to the dominent background color), to child components so they can theme themselves as appropriate
    const children = React.Children.map(props.children, child => {
        return React.cloneElement(child, {
            sectionTheme: sectionTheme,
        });
    });

    return (
        <SectionWrapper bgColor={props.bgColor} >
            { props.title && <AnchorTarget id={stringToSlug(props.title)}/> }
            <ContentContainer maxWidth={props.maxWidth}>
                {props.title && (
                    <PageSectionTitle
                        as='H2'
                        $centerTitle={props.centerTitle}
                        theme={sectionTheme === 'dark' ? 'light' : 'dark'}
                        dangerouslySetInnerHTML={createMarkup(props.title)}
                    />
                )}
                <ComponentContainer>
                    {children}
                </ComponentContainer>
            </ContentContainer>
        </SectionWrapper>
    );
};

PageSection.propTypes = {
    title: PropTypes.string,
};

export default PageSection;
